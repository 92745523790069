// src/components/CandidateProfile/CandidateProfile.js
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { FaPencilAlt } from "react-icons/fa";
import ProfilePicture from "../../images/profile/ProfilePicture.jpg"; // Adjust the path as necessary
import Input from "../../component/input/Input"; // Adjust the path as necessary
import FileDropper from "../../component/fileDroper/FileDropper"; // Adjust the path as necessary
import ButtonLoader from "../../component/Loaders/ButtonLoader"; // Adjust the path as necessary
import {
  getCandidateProfile,
  updateProfileCandidate,
  uploadFiles,
} from "../../services/apiService"; // Adjust the path as necessary
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast notifications

const countryOptions = [
  "United Kingdom",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic (Czechia)",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini (fmr. Swaziland)",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Other",
];

const timezoneOptions = [
  "GMT",
  "CET",
  "EST",
  "PST",
  "IST",
  "AEST",
  "CST",
  "MST",
  "HST",
  "AKST",
  "Other",
];

const CandidateProfile = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [profileImage, setProfileImage] = useState(ProfilePicture);
  const [inputName, setInputName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [countryOfResidence, setCountryOfResidence] = useState(null);
  const [countryOfBirth, setCountryOfBirth] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [availableOn, setAvailableOn] = useState({
    whatsapp: null,
    telegram: null,
    skype: null,
    other: null,
  });
  const [coverLetter, setCoverLetter] = useState("");
  const [aboutVideo, setAboutVideo] = useState("");
  const [cv, setCv] = useState("");
  const [avatarFileName, setAvatarFileName] = useState(null); // Store the uploaded avatar file name here
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      const profileData = await dispatch(getCandidateProfile());
      if (profileData) {
        const data = profileData.data;

        setProfileImage(data.avatar || ProfilePicture);
        setInputName(data.first_name || "");
        setLastName(data.last_name || "");
        setEmail(data.email || "");
        setCountryOfResidence(data.countryOfRecidence || "");
        setCountryOfBirth(data.countryOfBirth || "");
        setTimezone(data.timezone || "");
        setContactNumber(data.contactNumber || "");
        setAvailableOn({
          whatsapp: data.whatsapp,
          telegram: data.telegram,
          skype: data.skype,
          other: data.other,
        });
        setCoverLetter(data.coverLetter || "");
        setAboutVideo(data.aboutVideo);
        setCv(data.cv);
        setAvatarFileName(data.avatar); // Set avatar file name if available
        setLoading(false);
      }
    };

    fetchProfile();
  }, [dispatch]);

  const handleInputName = (e) => setInputName(e.target.value);
  const handleLastName = (e) => setLastName(e.target.value);
  const handleCountryOfResidence = (e) => setCountryOfResidence(e.target.value);
  const handleCountryOfBirth = (e) => setCountryOfBirth(e.target.value);
  const handleTimezone = (e) => setTimezone(e.target.value);
  const handleContactNumber = (e) => setContactNumber(e.target.value);

  const handleAvailableOnChange = (e) => {
    const {
      name,
      value,
      type,
      // checked
    } = e.target;
    setAvailableOn((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? " " : value,
    }));
  };

  const handleCoverLetterChange = (e) => setCoverLetter(e.target.value);

  const toggleEditing = () => setIsEditing((prev) => !prev);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setUploading(true); // Start loader
      try {
        const response = await uploadFiles([file]);
        const uploadedFilePath = response.photos[0]; // Assuming the response contains the file path
        setAvatarFileName(uploadedFilePath);
        setProfileImage(URL.createObjectURL(file)); // Show the selected image
        toast.success("Profile picture uploaded successfully!");
      } catch (error) {
        toast.error("Failed to upload profile picture.");
      } finally {
        setUploading(false); // Stop loader
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg, image/jpg",
  });

  const handleSaveProfile = async () => {
    setSaving(true);
    try {
      const payload = {
        avatar: avatarFileName, // Use the uploaded file name here
        first_name: inputName,
        last_name: lastName,
        countryOfRecidence: countryOfResidence,
        countryOfBirth: countryOfBirth,
        timezone,
        contactNumber,
        whatsapp: availableOn.whatsapp ? availableOn.whatsapp : null,
        telegram: availableOn.telegram ? availableOn.telegram : null,
        skype: availableOn.skype ? availableOn.skype : null,
        other: availableOn.other ? availableOn.other : null,
        aboutVideo,
        cv,
        coverLetter,
      };
      await dispatch(updateProfileCandidate(payload));
      toast.success("Profile saved successfully!");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#f9fafb]">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full bg-[#f9fafb] flex flex-col items-center">
      <ToastContainer />
      <div className="bg-white shadow-md rounded-lg py-6 mb-6 w-full max-w-4xl">
        <h2 className="text-lg font-semibold mb-4 px-6">Profile Details</h2>
        <hr />
        <div className="flex flex-col items-center px-6 py-6">
          <div
            {...getRootProps()}
            className="relative w-32 h-32 border-dashed border-2 border-gray-300 rounded-lg p-2 cursor-pointer"
          >
            <input {...getInputProps()} />
            {uploading ? (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                <ButtonLoader /> {/* Show loader while uploading */}
              </div>
            ) : (
              <img
                src={profileImage}
                alt="Profile"
                className="w-full h-full object-cover rounded-lg"
              />
            )}
            <div className="absolute bottom-2 right-2 bg-white p-1 rounded-full">
              <FaPencilAlt className="text-gray-500 w-4 h-4" />
            </div>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Allowed file types: png, jpg, jpeg.
          </p>
        </div>
        <div className="sm:pl-2 sm:pr-32 px-2 w-full">
          {/* First Name */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <label className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              First Name
            </label>
            <Input
              type="text"
              className="w-full"
              placeholder="First Name"
              value={inputName}
              onChange={handleInputName}
            />
          </div>

          {/* Last Name */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <label className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Last Name
            </label>
            <Input
              type="text"
              className="w-full"
              placeholder="Last Name"
              value={lastName}
              onChange={handleLastName}
            />
          </div>

          {/* Email */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start mb-4">
            <label className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Email
            </label>
            <div>{email}</div>
          </div>

          {/* Country of Residence */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <label className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Country of Residence
            </label>
            <select
              className="w-full p-2 border rounded-md"
              value={countryOfResidence}
              onChange={handleCountryOfResidence}
            >
              {countryOptions.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>

          {/* Country of Birth */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <label className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Country of Birth
            </label>
            <select
              className="w-full p-2 border rounded-md"
              value={countryOfBirth}
              onChange={handleCountryOfBirth}
            >
              {countryOptions.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>

          {/* Timezone */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <label className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Timezone
            </label>
            <select
              className="w-full p-2 border rounded-md"
              value={timezone}
              onChange={handleTimezone}
            >
              {timezoneOptions.map((zone, index) => (
                <option key={index} value={zone}>
                  {zone}
                </option>
              ))}
            </select>
          </div>

          {/* Contact Number */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <label className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Contact Number
            </label>
            <Input
              type="text"
              className="w-full"
              placeholder="Contact Number"
              value={contactNumber}
              onChange={handleContactNumber}
            />
          </div>

          {/* Availability */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <label className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              I'm also available on
            </label>
            <div className="w-full sm:w-2/3">
              <div className="flex flex-wrap items-center mb-2">
                <input
                  type="checkbox"
                  name="whatsapp"
                  className="mr-2"
                  checked={availableOn.whatsapp}
                  onChange={handleAvailableOnChange}
                />
                <label className="mr-4">WhatsApp</label>

                <input
                  type="checkbox"
                  name="telegram"
                  className="mr-2"
                  checked={availableOn.telegram}
                  onChange={handleAvailableOnChange}
                />
                <label className="mr-4">Telegram</label>

                <input
                  type="checkbox"
                  name="skype"
                  className="mr-2"
                  checked={availableOn.skype}
                  onChange={handleAvailableOnChange}
                />
                <label className="mr-4">Skype</label>

                <input
                  type="checkbox"
                  name="other"
                  className="mr-2"
                  checked={availableOn.other}
                  onChange={handleAvailableOnChange}
                />
                <label className="mr-4">Other</label>
              </div>
              <div className="flex flex-col gap-2">
                {availableOn.whatsapp && (
                  <Input
                    type="text"
                    name="whatsapp"
                    className="w-full bg-gray-100 p-2 rounded-md"
                    placeholder="Type WhatsApp number here"
                    value={availableOn.whatsapp ? availableOn.whatsapp : ""}
                    onChange={handleAvailableOnChange}
                  />
                )}
                {availableOn.telegram && (
                  <Input
                    type="text"
                    name="telegram"
                    className="w-full bg-gray-100 p-2 rounded-md"
                    placeholder="Type Telegram here"
                    value={availableOn.telegram ? availableOn.telegram : ""}
                    onChange={handleAvailableOnChange}
                  />
                )}
                {availableOn.skype && (
                  <Input
                    type="text"
                    name="skype"
                    className="w-full bg-gray-100 p-2 rounded-md"
                    placeholder="Type Skype here"
                    value={availableOn.skype ? availableOn.skype : ""}
                    onChange={handleAvailableOnChange}
                  />
                )}
                {availableOn.other && (
                  <Input
                    type="text"
                    name="other"
                    className="w-full bg-gray-100 p-2 rounded-md"
                    placeholder="Type other availability here"
                    value={availableOn.other}
                    onChange={handleAvailableOnChange}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Upload Video */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <label className="font-semibold w-full sm:w-1/3">
              Upload Video About You
            </label>
            <FileDropper
              title="Upload video about you"
              instructions="Drop video here or click to upload."
              type="video"
              fileTypes={["mp4", "mov", "avi", "mkv"]}
              onFileDrop={(fileURL) => setAboutVideo(fileURL)}
              defaultFile={aboutVideo}
            />
          </div>

          {/* Upload CV */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <label className="font-semibold w-full sm:w-1/3">Upload CV</label>
            <FileDropper
              title="Upload CV"
              instructions="Drop CV here or click to upload."
              type="document"
              fileTypes={["pdf", "doc", "docx"]}
              onFileDrop={(fileURL) => setCv(fileURL)}
              defaultFile={cv}
            />
          </div>

          {/* Upload Cover Letter */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4 w-full">
            <label className="font-semibold w-full sm:w-1/3">
              Upload Cover Letter
            </label>
            <div className="flex flex-row w-full">
              <textarea
                className="mt-1 block w-full px-4 py-2 border rounded-md"
                placeholder="Write your cover letter here"
                value={coverLetter}
                onChange={handleCoverLetterChange}
                disabled={!isEditing}
              />
              <button
                type="button"
                className={`ml-2 my-2 w-24 text-sm sm:text-lg px-2 py-1 text-white rounded-md ${
                  isEditing ? "bg-green-500" : "bg-blue-500"
                }`}
                onClick={toggleEditing}
              >
                {isEditing ? "Confirm" : "Edit"}
              </button>
            </div>
          </div>

          {/* Save Button */}
          <div className="flex justify-center">
            <button
              className="bg-purpleButton text-white px-4 py-2 rounded-full"
              onClick={handleSaveProfile}
              disabled={saving}
            >
              {saving ? <ButtonLoader /> : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateProfile;
