import { useSelector } from "react-redux";
import testResult from "../../../images/test/online-test.png";
import testFailed from "../../../images/test/test failed.jpg";
import testNotFound from "../../../images/test/notfound test.jpg";

const SeeApplicationDetailTestResult = () => {
  // Access the fetched job application data from Redux store
  const jobApplication = useSelector(
    (state) => state.jobApplicationSingleReducer_candidate?.jobApplication[0]
  );
  return (
    <>
      {jobApplication?.status >= 4 && (
        <>
          <div className="flex flex-col items-center justify-center">
            <img src={testResult} alt="test result" className="w-96" />
            <p className="font-bold text-2xl">Test Passed</p>
          </div>
        </>
      )}
      {jobApplication.success === 2 && (
        <>
          <div className="flex flex-col items-center justify-center">
            <img src={testFailed} alt="test result" className="w-96" />
            <p className="font-bold text-2xl text-red-600">Failed</p>
          </div>
        </>
      )}
      {jobApplication?.status < 4 && jobApplication.success !== 2 && (
        <>
          <div className="flex flex-col items-center justify-center">
            <img src={testNotFound} alt="test result" className="w-96" />
            <p className="font-bold text-2xl">Not Found</p>
          </div>
        </>
      )}
    </>
  );
};

export default SeeApplicationDetailTestResult;
