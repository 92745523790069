import React from "react";
import { useSelector } from "react-redux";

const SeeApplicationDetailTimeLine = () => {
  // Access the fetched job application data from Redux store
  const jobApplication = useSelector(
    (state) => state.jobApplicationSingleReducer_candidate?.jobApplication[0]
  );

  const steps = [
    { text: "Applied for job", color: "greenButton", minStatus: 0 },
    { text: "Application Accepted", color: "greenButton", minStatus: 1 },
    { text: "Test Taken by Candidate", color: "greenButton", minStatus: 3 },
    { text: "Test passed by Candidate", color: "greenButton", minStatus: 4 },
    {
      text: "Candidate Signed the Contract",
      color: "greenButton",
      minStatus: 5,
    },
    { text: "Contract Approved", color: "redButton", minStatus: 6 },
  ];

  const isStepGreen = (step) => {
    if (jobApplication?.success === 1) {
      // If success is 1, all steps should be green
      return true;
    }
    return jobApplication?.status >= step.minStatus;
  };

  return (
    <div className="p-6">
      <ul className="relative list-none p-0 m-0">
        {steps.map((item, index, array) => {
          const isGreen = isStepGreen(item);
          return (
            <React.Fragment key={index}>
              <li className="flex flex-row justify-start items-start relative">
                <div className="flex items-center justify-center flex-col">
                  <div
                    className={`border-solid border-4 ${
                      isGreen ? "border-greenButton" : "border-redButton"
                    } w-4 h-4 rounded-full`}
                  ></div>
                  {index !== array.length - 1 && (
                    <>
                      {jobApplication?.success === 2 &&
                      steps[index].minStatus === jobApplication.status ? (
                        <div className="w-[2px] h-10 bg-slate-500"></div>
                      ) : (
                        <>
                          <div className="w-[2px] h-6 bg-slate-500"></div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="flex flex-col">
                  <span className="ml-8 leading-3">{item.text}</span>
                  {jobApplication?.success === 2 &&
                    steps[index].minStatus === jobApplication.status && (
                      <li className="mt-2 ml-8 text-redButton">
                        Application Rejected
                      </li>
                    )}
                </div>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default SeeApplicationDetailTimeLine;
