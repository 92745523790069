const initialState = {
  employerPackages: [],
};

const packages_employer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PACKAGE_HELP_AND_GUIDE":
      return {
        ...state,
        employerPackages: action.payload,
      };
    case "SET_CURRENT_SUBSCRIPTION":
      return {
        ...state,
        employerPackages: {
          ...state.employerPackages,
          currentSubscription: action.payload,
        },
      };
    default:
      return state;
  }
};

export default packages_employer;
