import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import FileDropper from "../../../component/fileDroper/FileDropper";
import {
  applyJobCandidate,
  getCandidateProfile,
} from "../../../services/apiService"; // Adjust the path according to your project structure
import ButtonLoader from "../../../component/Loaders/ButtonLoader";

// ThankYouModal Component for displaying a thank you message after form submission
const ThankYouModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
        <p className="mb-4">
          Thank you for applying. We will get back to you soon.
        </p>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          OK
        </button>
      </div>
    </div>
  );
};

// Options for country and timezone dropdowns
const countryOptions = [
  "United Kingdom",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic (Czechia)",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini (fmr. Swaziland)",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Other",
];

const timezoneOptions = [
  "GMT",
  "CET",
  "EST",
  "PST",
  "IST",
  "AEST",
  "CST",
  "MST",
  "HST",
  "AKST",
  "Other",
];

const SendApplicationFlow = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [coverLetter, setCoverLetter] = useState("");
  const [isEditing, setIsEditing] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.authReducer_candidate);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    availabilityDate: "",
    countryOfRecidence: "",
    countryOfBirth: "",
    timezone: "",
    contactNumber: "",
    aboutVideo: null, // Set after video upload
    cv: null, // Set after CV upload
  });

  // Fetch candidate profile when component mounts
  useEffect(() => {
    const fetchProfile = async () => {
      const response = await dispatch(getCandidateProfile());

      if (response) {
        setFormData({
          first_name: response.data.first_name || "",
          last_name: response.data.last_name || "",
          email: response.data.email || "",
          availabilityDate: "",
          countryOfRecidence: response.data.countryOfRecidence || "",
          countryOfBirth: response.data.countryOfBirth || "",
          timezone: response.data.timezone || "",
          contactNumber: response.data.contactNumber || "",
          aboutVideo: response.data.aboutVideo || null,
          cv: response.data.cv || null,
        });
        setCoverLetter(response.data.coverLetter || "");
      } else {
        // toast.error("Failed to fetch candidate profile.");
      }
    };

    fetchProfile();
  }, [dispatch]);

  const handleCoverLetterChange = (e) => {
    setCoverLetter(e.target.value);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleFileDrop = (filePath, type) => {
    setFormData((prevData) => ({
      ...prevData,
      [type]: filePath,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      jobId: id,
      ...formData,
      coverLetter,
    };

    try {
      setLoading(true);
      const response = await dispatch(applyJobCandidate(payload));
      toast.success(response.message || "Application submitted successfully!");
      setIsModalOpen(true);
    } catch (error) {
      toast.error(error.message || "Failed to submit application.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    // Check if the window can be closed programmatically
    if (window.opener) {
      window.close(); // This will work only if the window was opened by window.open()
    } else {
      // Redirect to a "Thank You" page if the window cannot be closed
      window.location.href = "/thank-you"; // Replace '/thank-you' with your actual "Thank You" page URL
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="w-full sm:max-w-[80%] mx-auto p-6 bg-white shadow-md rounded-lg text-center">
      <ToastContainer />
      <h2 className="text-2xl font-bold mb-4">Enter Your Details</h2>
      <form onSubmit={handleSubmit}>
        <table className="w-full text-left">
          <tbody>
            {[
              {
                label: "First Name",
                name: "first_name",
                type: "text",
                placeholder: "First name",
              },
              {
                label: "Last Name",
                name: "last_name",
                type: "text",
                placeholder: "Last name",
              },
              {
                label: "Email",
                name: "email",
                type: "email",
                placeholder: "Email here",
              },
              {
                label: "Contact Number",
                name: "contactNumber",
                type: "text",
                placeholder: "Contact number",
              },
              {
                label: "Availability Date",
                name: "availabilityDate",
                type: "date",
              },
            ].map((field, index) => (
              <tr key={index} className="flex flex-col sm:table-row">
                <td className="px-4 py-2">
                  <label className="block text-gray-700">{field.label}</label>
                </td>
                {field.label === "Email" && userInfo?.email ? (
                  <>
                    {
                      <td className="px-4 py-2">
                        <div>{formData[field.name]}</div>
                      </td>
                    }
                  </>
                ) : (
                  <>
                    <td className="px-4 py-2">
                      <input
                        type={field.type}
                        name={field.name}
                        className="mt-1 block w-full px-4 py-2 border rounded-md transition-colors"
                        placeholder={field.placeholder}
                        value={formData[field.name]} // Pre-fill form fields with candidate profile data
                        onChange={handleInputChange}
                      />
                    </td>
                  </>
                )}
              </tr>
            ))}
            <tr className="flex flex-col sm:table-row">
              <td className="px-4 py-2">
                <label className="block text-gray-700">
                  Country of Residence
                </label>
              </td>
              <td className="px-4 py-2">
                <select
                  name="countryOfRecidence"
                  value={formData.countryOfRecidence}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-4 py-2 border rounded-md"
                >
                  <option value="">Select your country of residence</option>
                  {countryOptions.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr className="flex flex-col sm:table-row">
              <td className="px-4 py-2">
                <label className="block text-gray-700">Country of Birth</label>
              </td>
              <td className="px-4 py-2">
                <select
                  name="countryOfBirth"
                  value={formData.countryOfBirth}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-4 py-2 border rounded-md"
                >
                  <option value="">Select your country of birth</option>
                  {countryOptions.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr className="flex flex-col sm:table-row">
              <td className="px-4 py-2">
                <label className="block text-gray-700">Timezone</label>
              </td>
              <td className="px-4 py-2">
                <select
                  name="timezone"
                  value={formData.timezone}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-4 py-2 border rounded-md"
                >
                  <option value="">Select your timezone</option>
                  {timezoneOptions.map((timezone) => (
                    <option key={timezone} value={timezone}>
                      {timezone}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr className="flex flex-col sm:table-row">
              <td className="px-4 py-2">
                <label className="block text-gray-700">
                  Upload video about you
                </label>
              </td>
              <td className="px-4 py-2">
                <FileDropper
                  title="Upload video about you"
                  instructions="Drag and drop your video file here or click to browse"
                  onFileDrop={handleFileDrop}
                  type="aboutVideo"
                  fileTypes={["mp4", "mov", "avi"]}
                  defaultFile={formData.aboutVideo}
                />
              </td>
            </tr>
            <tr className="flex flex-col sm:table-row">
              <td className="px-4 py-2">
                <label className="block text-gray-700">Upload CV</label>
              </td>
              <td className="px-4 py-2">
                <FileDropper
                  title="Upload CV"
                  instructions="Drag and drop your CV here or click to browse"
                  onFileDrop={handleFileDrop}
                  type="cv"
                  fileTypes={["pdf", "doc", "docx"]}
                  defaultFile={formData.cv}
                />
              </td>
            </tr>
            <tr className="flex flex-col sm:table-row">
              <td className="px-4 py-2">
                <label className="block text-gray-700">Cover Letter</label>
              </td>
              <td className="px-4 py-2">
                <div className="flex flex-row">
                  <textarea
                    className="mt-1 block w-full px-4 py-2 border rounded-md"
                    placeholder="Write your cover letter here"
                    value={coverLetter}
                    onChange={handleCoverLetterChange}
                    disabled={!isEditing}
                  />
                  {coverLetter && (
                    <button
                      type="button"
                      className={`ml-[5px] my-5 w-[30%] text-[10px] sm:text-lg sm:m-5 sm:w-full px-1 py-1 sm:px-4 sm:py-1 text-white rounded-md ${
                        isEditing ? "bg-purpleButton" : "bg-slate-600"
                      }`}
                      onClick={toggleEditing}
                    >
                      {isEditing ? "Confirm" : "Edit"}
                    </button>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          type="submit"
          className="w-[100px] mt-4 py-2 text-white bg-purpleButton hover:bg-purple-600 rounded-3xl"
        >
          {loading ? <ButtonLoader /> : <>Apply Job</>}
        </button>
      </form>
      <ThankYouModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default SendApplicationFlow;
