import React from "react";
import thankyou from "../../images/thankYou/images.png";

const ThankYouPage = () => {
  return (
    <div style={styles.container}>
      <p className="font-bold mb-2 text-2xl">Your process is complete.</p>
      <img src={thankyou} alt="thanks" />
      <p className="font-bold mt-2 text-2xl">
        You can now safely close this tab.
      </p>
    </div>
  );
};

// Inline styles for the component
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f4f4f4",
    textAlign: "center",
    padding: "20px",
  },
};

export default ThankYouPage;
