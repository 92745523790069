import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import profileImage from "../../../images/profile/ProfilePicture.jpg";
import greyProfile from "../../../images/employer candidate/greyProfile.png";
import SeeApplicationDetailTimeLine from "./SeeApplicationDetailTimeLine";
import { useSelector } from "react-redux";
import ButtonLoader from "../../../component/Loaders/ButtonLoader"; // Import ButtonLoader component for showing loading state

const SeeApplicationDetailHeader = () => {
  // Access the fetched job application data from Redux store
  const jobApplication = useSelector(
    (state) => state.jobApplicationSingleReducer_candidate?.jobApplication[0]
  );
  const navigate = useNavigate();
  const location = useLocation();

  const getPageStyle = (path) => {
    return location.pathname === path
      ? "text-purpleButton font-bold underline underline-offset-[20px] decoration-4"
      : "text-black font-bold";
  };

  if (!jobApplication) {
    return (
      <>
        <ButtonLoader />
      </>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-4 flex flex-col text-[10px] sm:text-lg">
      <div className="flex flex-col sm:flex-row justify-between w-full">
        <div className="flex flex-col items-start gap-4 w-[100%] sm:w-[60%]">
          <div className="flex">
            <img
              src={jobApplication?.candidate.avatar || profileImage}
              alt="profileImage"
              className="w-[90px] h-[90px] sm:w-[140px] sm:h-[140px] mr-4"
            />
            <div>
              <h1 className="text-lg font-bold">{`${
                jobApplication?.candidate.first_name || ""
              } ${jobApplication?.candidate.last_name || ""}`}</h1>
              <div className="flex items-center gap-1">
                <img src={greyProfile} alt="greyProfile" />
                <p className="text-[#a1a5b7]">Candidate</p>
              </div>
              <p>
                <span className="text-[#a1a5b7]">Applied for:</span>{" "}
                <span className="text-lg font-bold">
                  {new Date(jobApplication?.createdAt).toLocaleString()}
                </span>
              </p>
            </div>
          </div>
        </div>
        <SeeApplicationDetailTimeLine />
      </div>
      <div className="flex text-wrap items-start gap-2 sm:gap-10">
        <div
          className={`cursor-pointer ${getPageStyle(
            `/candidateDashboard/application/${jobApplication?._id}`
          )}`}
          onClick={() =>
            navigate(`/candidateDashboard/application/${jobApplication?._id}`)
          }
        >
          Personal Information
        </div>
        <div
          className={`cursor-pointer ${getPageStyle(
            `/candidateDashboard/application/${jobApplication?._id}/testResult`
          )}`}
          onClick={() =>
            navigate(
              `/candidateDashboard/application/${jobApplication?._id}/testResult`
            )
          }
        >
          Test Result
        </div>
        <div
          className={`cursor-pointer ${getPageStyle(
            `/candidateDashboard/application/${jobApplication?._id}/contract`
          )}`}
          onClick={() =>
            navigate(
              `/candidateDashboard/application/${jobApplication?._id}/contract`
            )
          }
        >
          Contracts
        </div>
      </div>
    </div>
  );
};

export default SeeApplicationDetailHeader;
