import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { FaPencilAlt, FaUserCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  updateProfile,
  uploadFiles,
  changePasswordManually,
} from "../../services/apiService"; // Update the path as needed
import ButtonLoader from "../../component/Loaders/ButtonLoader";

const EmployerProfile = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.authReducer_Employer);

  // Profile details state
  const [profileImage, setProfileImage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // Initial profile details state for cancel functionality
  const [initialProfileImage, setInitialProfileImage] = useState(null);
  const [initialFirstName, setInitialFirstName] = useState("");
  const [initialLastName, setInitialLastName] = useState("");

  // Security details state
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  // Email preferences state
  const [newApplication, setNewApplication] = useState(false);
  const [testTaken, setTestTaken] = useState(false);
  const [contractSigned, setContractSigned] = useState(false);

  // Initial email preferences state for cancel functionality
  const [initialNewApplication, setInitialNewApplication] = useState(false);
  const [initialTestTaken, setInitialTestTaken] = useState(false);
  const [initialContractSigned, setInitialContractSigned] = useState(false);

  // Loading states
  const [isUploading, setIsUploading] = useState(false);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [isSavingPreferences, setIsSavingPreferences] = useState(false);
  const [isSavingPassword, setIsSavingPassword] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setProfileImage(userInfo.avatar);
      setFirstName(userInfo.first_name);
      setLastName(userInfo.last_name);
      setNewApplication(userInfo.NewApplication);
      setTestTaken(userInfo.TestTaken);
      setContractSigned(userInfo.ContractSigned);

      // Set initial states for cancel functionality
      setInitialProfileImage(userInfo.avatar);
      setInitialFirstName(userInfo.first_name);
      setInitialLastName(userInfo.last_name);
      setInitialNewApplication(userInfo.NewApplication);
      setInitialTestTaken(userInfo.TestTaken);
      setInitialContractSigned(userInfo.ContractSigned);
    }
  }, [userInfo]);

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePasswordChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleCheckboxChange = (setter) => (e) => {
    setter(e.target.checked);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const validMimeTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!validMimeTypes.includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload an image (png, jpg, jpeg)."
        );
        return;
      }
      setIsUploading(true);
      try {
        const response = await uploadFiles([file]);
        setProfileImage(
          `https://grapesoftcouk.s3.amazonaws.com/${response.photos[0]}`
        ); // Assuming the response contains the URLs of the uploaded files
        toast.success("Profile image uploaded successfully!");
      } catch (error) {
        toast.error(error.message);
        console.error("Error uploading file:", error);
      } finally {
        setIsUploading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
  });

  const handleSaveProfile = async () => {
    setIsSavingProfile(true);
    const payload = {
      avatar: profileImage,
      first_name: firstName,
      last_name: lastName,
      company_name: userInfo.company_name, // Replace with actual data
    };

    try {
      await updateProfile(payload)(dispatch);
      toast.success("Profile updated successfully!");

      // Update initial states for cancel functionality
      setInitialProfileImage(profileImage);
      setInitialFirstName(firstName);
      setInitialLastName(lastName);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile.");
    } finally {
      setIsSavingProfile(false);
    }
  };

  const handleCancelProfile = () => {
    setProfileImage(initialProfileImage);
    setFirstName(initialFirstName);
    setLastName(initialLastName);
  };

  const handleSavePreferences = async () => {
    setIsSavingPreferences(true);
    const payload = {
      NewApplication: newApplication,
      TestTaken: testTaken,
      ContractSigned: contractSigned,
    };

    try {
      await updateProfile(payload)(dispatch);
      toast.success("Email preferences updated successfully!");

      // Update initial states for cancel functionality
      setInitialNewApplication(newApplication);
      setInitialTestTaken(testTaken);
      setInitialContractSigned(contractSigned);
    } catch (error) {
      console.error("Error updating email preferences:", error);
      toast.error("Failed to update email preferences.");
    } finally {
      setIsSavingPreferences(false);
    }
  };

  const handleCancelPreferences = () => {
    setNewApplication(initialNewApplication);
    setTestTaken(initialTestTaken);
    setContractSigned(initialContractSigned);
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSavePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }

    setIsSavingPassword(true);
    const payload = {
      oldPassword: currentPassword,
      newPassword,
    };

    try {
      await changePasswordManually(payload)(dispatch);
      toast.success("Password changed successfully!");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSavingPassword(false);
    }
  };

  const handleCancelPasswordChange = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div className="min-h-screen w-full bg-[#f9fafb] flex flex-col items-center">
      <ToastContainer position="top-right" />
      <div className="bg-white shadow-md rounded-lg py-6 mb-6 w-full max-w-2xl">
        <h2 className="text-lg font-semibold mb-4 px-6">Profile Details</h2>
        <hr />
        <div className="flex flex-col items-center px-6 py-6">
          <div
            {...getRootProps()}
            className="relative w-32 h-32 border-dashed border-1 border-gray-300 rounded-lg p-2 cursor-pointer flex items-center justify-center"
          >
            <input {...getInputProps()} />
            {isUploading ? (
              <ButtonLoader />
            ) : profileImage ? (
              <img
                src={profileImage}
                alt="Profile"
                className="w-full h-full object-cover rounded-lg"
              />
            ) : (
              <FaUserCircle className="text-black w-32 h-32" />
            )}
            <div className="absolute bottom-2 right-2 bg-white p-1 rounded-full">
              <FaPencilAlt className="text-black w-4 h-4" />
            </div>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Allowed file types: png, jpg, jpeg.
          </p>
        </div>
        <div className="px-6 w-full">
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <div className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              First Name
            </div>
            <input
              type="text"
              className={`w-full sm:w-2/3 px-3 py-2 border rounded-lg ${
                firstName ? "bg-inputBg" : ""
              }`}
              placeholder="First Name"
              value={firstName}
              onChange={handleFirstName}
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <div className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Last Name
            </div>
            <input
              type="text"
              className={`w-full sm:w-2/3 px-3 py-2 border rounded-lg ${
                lastName ? "bg-inputBg" : ""
              }`}
              placeholder="Last Name"
              value={lastName}
              onChange={handleLastName}
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <div className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Email
            </div>
            <div className="w-full sm:w-2/3 px-3 py-2 truncate">
              {userInfo?.email || "example@gmail.com"}
            </div>
          </div>
          <div className="flex justify-center gap-4">
            <button
              onClick={handleSaveProfile}
              className="bg-purpleButton text-white px-4 py-2 rounded-full flex items-center"
              disabled={isSavingProfile}
            >
              {isSavingProfile ? <ButtonLoader /> : "Save"}
            </button>
            <button
              onClick={handleCancelProfile}
              className="bg-gray-400 text-white px-4 py-2 rounded-full flex items-center"
              disabled={isSavingProfile}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg py-6 mb-6 w-full max-w-2xl">
        <h2 className="text-lg font-semibold mb-4 px-6">Security Details</h2>
        <hr />
        <div className="px-6 mt-4 w-full">
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <div className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Current Password
            </div>
            <div className="relative w-full sm:w-2/3">
              <input
                type={passwordVisible.current ? "text" : "password"}
                className={`w-full px-3 py-2 border rounded-lg ${
                  currentPassword ? "bg-inputBg" : ""
                }`}
                placeholder="Current Password"
                value={currentPassword}
                onChange={handlePasswordChange(setCurrentPassword)}
                autoComplete="new-password"
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("current")}
              >
                {passwordVisible.current ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <div className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              New Password
            </div>
            <div className="relative w-full sm:w-2/3">
              <input
                type={passwordVisible.new ? "text" : "password"}
                className={`w-full px-3 py-2 border rounded-lg ${
                  newPassword ? "bg-inputBg" : ""
                }`}
                placeholder="New Password"
                value={newPassword}
                onChange={handlePasswordChange(setNewPassword)}
                autoComplete="new-password"
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("new")}
              >
                {passwordVisible.new ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:justify-between mb-4">
            <div className="whitespace-nowrap w-full sm:w-1/3 font-semibold mb-2 sm:mb-0">
              Confirm Password
            </div>
            <div className="relative w-full sm:w-2/3">
              <input
                type={passwordVisible.confirm ? "text" : "password"}
                className={`w-full px-3 py-2 border rounded-lg ${
                  confirmPassword ? "bg-inputBg" : ""
                }`}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handlePasswordChange(setConfirmPassword)}
                autoComplete="new-password"
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("confirm")}
              >
                {passwordVisible.confirm ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </div>
          <div className="flex justify-center gap-4">
            <button
              onClick={handleSavePassword}
              className="bg-purpleButton text-white px-4 py-2 rounded-full flex items-center"
              disabled={isSavingPassword}
            >
              {isSavingPassword ? <ButtonLoader /> : "Save"}
            </button>
            <button
              onClick={handleCancelPasswordChange}
              className="bg-gray-400 text-white px-4 py-2 rounded-full flex items-center"
              disabled={isSavingPassword}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-6">Email Preferences</h2>
        <div className="space-y-4">
          <label className="flex items-start gap-2">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-purpleButton"
              checked={newApplication}
              onChange={handleCheckboxChange(setNewApplication)}
            />
            <div className="flex flex-col">
              <span className="text-lg sm:text-base">New Application</span>
              <span className="text-gray-500 sm:text-xs">
                Receive a notification for every new application.
              </span>
            </div>
          </label>
          <label className="flex items-start gap-2">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-purpleButton"
              checked={testTaken}
              onChange={handleCheckboxChange(setTestTaken)}
            />
            <div className="flex flex-col">
              <span className="text-lg sm:text-base">Test Taken</span>
              <span className="text-gray-500 sm:text-xs">
                Receive a notification each time candidate takes a test on your
                job.
              </span>
            </div>
          </label>
          <label className="flex items-start gap-2">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-purpleButton"
              checked={contractSigned}
              onChange={handleCheckboxChange(setContractSigned)}
            />
            <div className="flex flex-col">
              <span className="text-lg sm:text-base">Contract Signed</span>
              <span className="text-gray-500 sm:text-xs">
                Receive a notification each time candidate signs a contract.
              </span>
            </div>
          </label>
        </div>
        <div className="flex justify-center mt-6 gap-4">
          <button
            onClick={handleSavePreferences}
            className="bg-purpleButton text-white px-4 py-2 rounded-full flex items-center"
            disabled={isSavingPreferences}
          >
            {isSavingPreferences ? <ButtonLoader /> : "Save"}
          </button>
          <button
            onClick={handleCancelPreferences}
            className="bg-gray-400 text-white px-4 py-2 rounded-full flex items-center"
            disabled={isSavingPreferences}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployerProfile;
