import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import { getCandidateDashboardInfo } from "../../services/apiService"; // Adjust the import path as necessary
import { toast, ToastContainer } from "react-toastify";

// images
import Active from "../../images/dashboard/Active.png";
import Private from "../../images/dashboard/Private.png";
import Public from "../../images/dashboard/Public.png";
import Total from "../../images/dashboard/Total.png";
import { useDispatch } from "react-redux";

// SummaryItem Component
const SummaryItem = ({ image, count, label, status }) => (
  <Link
    to={`/candidateDashboard/applications?status=${status}`}
    className="flex flex-row items-center mb-4 gap-4 w-[50%]"
  >
    <div className="bg-white min-w-8 min-h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-lg">
      <img src={image} alt={label} />
    </div>
    <div className="flex flex-col">
      <span className="text-xl font-bold">{count}</span>
      <span className="text-[12px] text-[#00C9B7]">{label}</span>
    </div>
  </Link>
);

const CandidateMain = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true); // Start loading
        const response = await dispatch(getCandidateDashboardInfo());
        setDashboardData(response.data);
      } catch (error) {
        toast.error("Failed to fetch dashboard data.");
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchDashboardData();
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <div className="items-center justify-center">
      <ToastContainer />
      <div className="flex flex-col md:flex-row items-center py-4 gap-4">
        <div className="flex space-x-4 w-full flex-col md:flex-row">
          {/* Jobs Summary */}
          <Link
            to={`/candidateDashboard/applications`}
            className="bg-[#AEEBE7] rounded-lg shadow-md p-6 flex-1 w-full sm:max-w-[400px]"
          >
            <h2 className="text-xl font-semibold mb-4 text-[#00C9B7]">
              Summary
            </h2>
            <div className="flex">
              <SummaryItem
                image={Total}
                count={dashboardData?.totalApplications || 0}
                label="Job Applied"
                status={0}
              />
              <SummaryItem
                image={Active}
                count={dashboardData?.totalTestsTaken || 0}
                label="Test Taken"
                status={1}
              />
            </div>
            <div className="flex">
              <SummaryItem
                image={Public}
                count={dashboardData?.totalTestsPassed || 0}
                label="Test Passed"
                status={4}
              />
              <SummaryItem
                image={Private}
                count={dashboardData?.totalContractsJobOffers || 0}
                label="Contracted Jobs"
                status={3}
              />
            </div>
          </Link>
          <div className="hidden sm:flex w-[50%]"></div>
        </div>
      </div>
    </div>
  );
};

export default CandidateMain;
