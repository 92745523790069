import React, { useEffect, useRef, useState, useCallback } from "react";
import SignatureCanvas from "react-signature-canvas";
import FileDropper from "../../component/fileDroper/FileDropper";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDataForSignContract,
  signContract,
  loginCandidate,
  getCandidateProfile,
  uploadFiles,
} from "../../services/apiService";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";

function dataURLToFile(dataURL, fileName) {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeType = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeType });
  return new File([blob], fileName, { type: mimeType });
}

const CandidateSignContract = () => {
  const { jobApplicationId } = useParams();
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [contractData, setContractData] = useState(null);
  const [files, setFiles] = useState({
    governmentIdFront: null,
    governmentIdBack: null,
    proofOfAddress: null,
  });
  const [signature, setSignature] = useState(null);
  const [signatureURL, setSignatureURL] = useState("");
  const [isSigned, setIsSigned] = useState(false); // State to track if the contract is signed
  const sigCanvas = useRef({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchContractData = useCallback(async () => {
    try {
      const response = await dispatch(
        getDataForSignContract({
          jobApplyId: jobApplicationId,
        })
      );
      setContractData(response);
    } catch (error) {
      toast.error("Failed to fetch contract data.");
    }
  }, [dispatch, jobApplicationId]);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const profileData = await dispatch(getCandidateProfile());
        if (profileData) {
          setLoggedIn(true);
          await fetchContractData();
        }
      } catch (error) {
        console.log("User not logged in", error);
        setLoggedIn(false);
      } finally {
        setLoading(false);
      }
    };
    checkLoginStatus();
  }, [dispatch, fetchContractData]);

  const handleFileDrop = (file, type) => {
    if (file) {
      setFiles((prevFiles) => ({ ...prevFiles, [type]: file }));
    }
  };

  const handleLogin = async (loginPayload) => {
    setLoading(true);
    try {
      await dispatch(loginCandidate(loginPayload));
      toast.success("Logged in successfully!");
      setLoggedIn(true);
      await fetchContractData();
    } catch (error) {
      toast.error("Login failed!");
    } finally {
      setLoading(false);
    }
  };

  const handleSignatureSave = () => {
    const signatureData = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    const signatureFile = dataURLToFile(signatureData, "signature.png");
    setSignature(signatureFile);

    const reader = new FileReader();
    reader.onload = () => {
      setSignatureURL(reader.result);
    };
    reader.readAsDataURL(signatureFile);
  };

  const handleClearSignature = () => {
    sigCanvas.current.clear();
    setSignature(null);
    setSignatureURL("");
  };

  const handleSignContract = async (e) => {
    e.preventDefault();
    if (
      !files.governmentIdFront ||
      !files.governmentIdBack ||
      !files.proofOfAddress ||
      !signature
    ) {
      toast.error("Please complete all required fields.");
      return;
    }

    setLoading(true);

    try {
      const fileArray = [
        files.governmentIdFront,
        files.governmentIdBack,
        files.proofOfAddress,
      ];

      const signatureUploadResponse = await uploadFiles([signature]);
      const payload = {
        jobApplyId: jobApplicationId,
        governmentIdFront: fileArray[0],
        governmentIdBack: fileArray[1],
        proofOfAddress: fileArray[2],
        signature: signatureUploadResponse.photos[0],
      };
      await dispatch(signContract(payload));
      toast.success("Contract signed successfully!");
      setIsSigned(true); // Set the signed state to true on success
    } catch (error) {
      toast.error("Failed to sign contract.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen w-full bg-[#f9fafb] flex justify-center items-center">
        <ButtonLoader />
        <div className="font-bold my-4">
          Please wait while we sign the contract!
        </div>
        <ToastContainer />
      </div>
    );
  }

  if (isSigned) {
    // Display the Thank You page if the contract is signed
    return (
      <div className="min-h-screen w-full bg-[#f9fafb] flex justify-center items-center">
        <div className="text-center">
          <h2 className="text-3xl font-semibold mb-6">Thank You!</h2>
          <p className="text-xl text-slate-600">
            Your contract has been signed successfully.
          </p>
          <button
            onClick={() => navigate("/candidateDashboard/applications")}
            className="bg-purpleButton text-white px-4 py-2 rounded-full mt-6"
          >
            Go to Applications
          </button>
        </div>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full bg-[#f9fafb] flex flex-col items-center md:p-6 m-auto">
      <ToastContainer />
      {!loggedIn ? (
        <LoginForm onLogin={handleLogin} />
      ) : (
        <>
          <h2 className="text-2xl font-semibold mb-6 text-center">
            <div className="font-bold">Test</div>
            {new Date(contractData?.submittedTest?.createdAt).toLocaleString()}
          </h2>
          <p className="text-slate-400 mb-4">Contract test</p>
          <div className="bg-white shadow-md rounded-lg p-6 mb-6 w-full sm:max-w-[70%] flex flex-col gap-4">
            <h2 className="text-2xl font-semibold mb-6">
              Upload Verification Documents
            </h2>
            <FileDropper
              title="Upload Government ID (Front)"
              instructions="Drag 'n' drop some files here, or click to select files"
              onFileDrop={(file) => handleFileDrop(file, "governmentIdFront")}
              fileTypes={[
                "jpeg", // JPEG images (.jpg, .jpeg)
                "png", // PNG images (.png)
              ]}
              defaultFile={files.governmentIdFront}
            />
            <FileDropper
              title="Upload Government ID (Back)"
              instructions="Drag 'n' drop some files here, or click to select files"
              onFileDrop={(file) => handleFileDrop(file, "governmentIdBack")}
              fileTypes={[
                "jpeg", // JPEG images (.jpg, .jpeg)
                "png", // PNG images (.png)
              ]}
              defaultFile={files.governmentIdBack}
            />
            <FileDropper
              title="Proof of Address"
              instructions="Drag 'n' drop some files here, or click to select files"
              onFileDrop={(file) => handleFileDrop(file, "proofOfAddress")}
              fileTypes={[
                "jpeg", // JPEG images (.jpg, .jpeg)
                "png", // PNG images (.png)
              ]}
              defaultFile={files.proofOfAddress}
            />
          </div>
          <div className="bg-white shadow-md rounded-lg p-6 mb-6 w-full sm:max-w-[70%]">
            <h2 className="font-semibold mb-6 text-center">
              Take your time to study the contract before submitting
            </h2>
            {contractData?.jobContract?.docs && (
              <iframe
                src={contractData.jobContract.docs}
                width="100%"
                height="600px"
                title="Contract PDF"
                className="py-4"
              ></iframe>
            )}
            <div className="font-semibold mb-2">Signature</div>
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{
                width: 500,
                height: 200,
                className: "border-2 border-gray-400 rounded-lg",
              }}
            />
            <button
              onClick={handleClearSignature}
              className="mt-2 text-white bg-redButton p-2 rounded-full"
            >
              Clear Signature
            </button>
            <button
              onClick={handleSignatureSave}
              className="mt-2 text-white bg-purpleButton ml-4 p-2 rounded-full"
            >
              Save Signature
            </button>
            {signatureURL && (
              <img
                src={signatureURL}
                alt="Saved signature"
                className="border-2 border-gray-400 rounded-lg mt-4"
              />
            )}
          </div>
          <button
            onClick={handleSignContract}
            className="bg-purpleButton text-white px-4 py-2 rounded-full"
          >
            Sign Contract
          </button>
        </>
      )}
    </div>
  );
};

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin({ email, password });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded-lg p-6 mb-6 w-full sm:max-w-[70%]"
    >
      <h2 className="text-2xl font-semibold mb-6">Login</h2>
      <div className="mb-4">
        <label className="block text-gray-700">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 p-2 w-full border rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-1 p-2 w-full border rounded"
          required
        />
      </div>
      <button
        type="submit"
        className="bg-purpleButton text-white px-4 py-2 rounded-full"
      >
        Login
      </button>
    </form>
  );
};

export default CandidateSignContract;
