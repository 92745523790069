import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateHelpGuide } from "../../services/apiService";
import ButtonLoader from "../../component/Loaders/ButtonLoader";

const HelpGuideCandidate = () => {
  const dispatch = useDispatch();
  const helpAndGuide = useSelector(
    (state) => state.helpAndGuide_candidate.candidateHelpAndGuide
  );

  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      if (helpAndGuide.length === 0) {
        const fetchData = async () => {
          setLoading(true);
          await dispatch(getCandidateHelpGuide());
          setLoading(false);
        };
        fetchData();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [dispatch, helpAndGuide]);

  useEffect(() => {
    if (helpAndGuide.length) {
      const formattedData = helpAndGuide.map((item) => ({
        id: item._id,
        isOpen: false,
        content: {
          question: item.title,
          description: item.description,
          videoUrl: item.video,
        },
      }));
      setSections(formattedData);
    }
  }, [helpAndGuide]);

  const toggleSection = (sectionId) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? { ...section, isOpen: !section.isOpen }
          : section
      )
    );
  };

  return (
    <div>
      <div className="bg-white shadow-md rounded-md p-6">
        <h2 className="text-2xl font-semibold mb-4">Help & Guide</h2>
        {loading ? (
          <ButtonLoader />
        ) : sections.length === 0 ? (
          <div className="flex justify-center">not item yet</div>
        ) : (
          sections.map((section) => (
            <div key={section.id}>
              <div
                className="cursor-pointer py-2 border-b"
                onClick={() => toggleSection(section.id)}
              >
                <div className="flex gap-4 items-center">
                  <button className="bg-slate-300 flex justify-center items-center w-6 h-6 p-1">
                    <div className="text-black text-[1.4rem] text-center w-full h-full flex justify-center items-center">
                      {section.isOpen ? "-" : "+"}
                    </div>
                  </button>
                  <h3 className="text-xl">{section.content.question}</h3>
                </div>
              </div>
              {section.isOpen && (
                <div className="py-4">
                  <h4 className="font-semibold">Background:</h4>
                  <p className="mt-2">{section.content.description}</p>
                  {section.content.videoUrl && (
                    <div className="mt-4 bg-gray-200 h-56 flex items-center justify-center">
                      <video width="400" controls>
                        <source
                          src={section.content.videoUrl}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default HelpGuideCandidate;
