// src/components/Notifications.js
import React, { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getNotification, readNotification } from "../../services/apiService";
import ButtonLoader from "../../component/Loaders/ButtonLoader";

const Notifications = ({ userInfo }) => {
  const dispatch = useDispatch();
  const [showNotifications, setShowNotifications] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const data = await dispatch(getNotification());
        setNotifications(data.allNotifications);
        setUnreadCount(data.unreadNotifications);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [dispatch]);

  const toggleNotifications = async () => {
    setShowNotifications((prevState) => !prevState);
    if (!showNotifications && unreadCount > 0) {
      try {
        await dispatch(readNotification());
        setUnreadCount(0); // Remove blinking effect
        setNotifications((prev) =>
          prev.map((notification) => ({ ...notification, isRead: true }))
        );
      } catch (error) {
        console.error("Failed to mark notifications as read:", error);
      }
    }
  };

  const getTimeDifference = (date) => {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInSeconds = Math.floor((now - createdAt) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInSeconds < 60) return `${diffInSeconds} sec ago`;
    if (diffInMinutes < 60) return `${diffInMinutes} min ago`;
    if (diffInHours < 24) return `${diffInHours} hr ago`;
    if (diffInDays < 30) return `${diffInDays} d ago`;
    if (diffInMonths < 12) return `${diffInMonths} mo ago`;
    return `${diffInYears} yr ago`;
  };

  return (
    <div className="relative">
      <button
        className="bg-white border border-gray-300 rounded-lg p-2 focus:outline-none"
        onClick={toggleNotifications}
        disabled={loading}
      >
        <span className="sr-only">Notifications</span>
        <div className="relative">
          {loading ? (
            <ButtonLoader />
          ) : (
            <>
              <FaBell className="w-4 h-4 text-gray-500 relative" />
              {unreadCount > 0 && (
                <span className="animate-ping absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full w-[5px] h-[5px] flex items-center justify-center text-white"></span>
              )}
            </>
          )}
        </div>
      </button>
      {showNotifications && !loading && (
        <div className="z-50 absolute right-0 mt-2 w-80 bg-white rounded-lg overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="flex gap-2 p-4 items-center mb-2 bg-purpleButton text-white">
            <h2 className="text-lg font-semibold">Notifications</h2>
            <span className="text-sm">{unreadCount} unread</span>
          </div>
          <div className="flex flex-col gap-2 h-40 overflow-scroll">
            {notifications.map((notification) => (
              <div
                key={notification._id}
                className={`flex items-center gap-2 p-2 rounded-md ${
                  notification.isRead ? "bg-gray-100" : "bg-blue-100"
                } hover:bg-slate-300`}
              >
                <FaBell className="w-5 h-5 text-gray-500" />
                <div className="flex-grow">
                  <h3 className="font-medium text-sm">
                    {notification.message}
                  </h3>
                  <p className="text-xs text-gray-500">
                    {notification.description}
                  </p>
                </div>
                <span className="text-xs text-gray-400 bg-slate-200 p-1 rounded">
                  {getTimeDifference(notification.createdAt)}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
