const initialState = {
    jobApplication: [],
  };
  
  const jobApplicationSingleReducer_candidate = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_JOB_APPLICATION_SINGLE_CANDIDATE": {
        return {
          ...state,
          jobApplication: [action.payload],
        };
      }
      default:
        return state;
    }
  };
  
  export default jobApplicationSingleReducer_candidate;
  