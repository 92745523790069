import React, { useEffect, useState } from "react";
import ButtonLoader from "../../../component/Loaders/ButtonLoader";
import { getSignedContract } from "../../../services/apiService"; // Adjust the import according to your project structure
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const SeeApplicationDetailContract = () => {
  const { jobApplicationId } = useParams(); // Get jobApplyId from params
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const [contractDetails, setContractDetails] = useState([]);
  const [contractData, setContractData] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null); // State to store selected contract details
  const dispatch = useDispatch();

  function generateConsistentAlphanumeric(inputString) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let hash = 0;

    // Generate a hash code from the input string
    for (let i = 0; i < inputString.length; i++) {
      hash = (hash << 5) - hash + inputString.charCodeAt(i);
      hash |= 0; // Convert to 32-bit integer
    }

    // Convert hash to a positive number
    let positiveHash = Math.abs(hash);

    // Generate a 6-character alphanumeric string
    let alphanumericString = "";
    for (let i = 0; i < 6; i++) {
      alphanumericString += characters[positiveHash % characters.length];
      positiveHash = Math.floor(positiveHash / characters.length);
    }

    return alphanumericString;
  }

  useEffect(() => {
    const fetchSignedContract = async () => {
      try {
        const response = await dispatch(
          getSignedContract({ jobApplyId: jobApplicationId })
        );
        setContractData(response);
        setContractDetails([
          {
            srNo: 1,
            candidate: {
              email: response.email,
              first_name: response.first_name,
              last_name: response.last_name,
            },
            contractNo: generateConsistentAlphanumeric(response._id),
            action: "View",
          },
        ]);
      } catch (error) {
        console.log("failed to fetch signed contract", error);
        setContractData(null); // No contract found
      } finally {
        setLoading(false);
      }
    };

    fetchSignedContract();
  }, [jobApplicationId, dispatch]);

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleViewContract = (contract) => {
    if (!selectedContract) {
      setSelectedContract(contract); // Set the selected contract details
    } else {
      setSelectedContract(null); // Clear the selected contract details
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-4">
      {contractData ? (
        <div className="overflow-x-auto">
          <div className="min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead className="text-[#a1a5b7] font-semibold">
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-gray-200 text-left text-sm uppercase"
                  >
                    SR NO
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-gray-200 text-left text-sm uppercase"
                  >
                    Candidate
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell px-5 py-3 bg-white border-b border-gray-200 text-left text-sm uppercase"
                  >
                    Contract No
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell px-5 py-3 bg-white border-b border-gray-200 text-left text-sm uppercase"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {contractDetails.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr
                      className={!expandedRows[index] ? "border-gray-200" : ""}
                    >
                      <td className="px-5 py-5 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap flex gap-2">
                          <button
                            className={`sm:hidden w-6 h-6 rounded-full text-white ${
                              expandedRows[index]
                                ? "bg-green-500"
                                : "bg-red-500"
                            } rounded-full`}
                            onClick={() => toggleRow(index)}
                          >
                            {expandedRows[index] ? "-" : "+"}
                          </button>
                          {item.srNo}
                        </p>
                      </td>
                      <td className="px-5 py-5 bg-white text-sm flex flex-col">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.candidate.email}
                        </p>
                        <p>
                          {item.candidate.first_name + " "}
                          {item.candidate.last_name}
                        </p>
                      </td>
                      <td className="hidden sm:table-cell px-5 py-5 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.contractNo}
                        </p>
                      </td>
                      <td className="hidden sm:table-cell px-5 py-5 bg-white text-sm">
                        <button
                          onClick={() => handleViewContract(contractData)}
                          className="text-blue-500 hover:underline"
                        >
                          {item.action}
                        </button>
                      </td>
                    </tr>
                    {expandedRows[index] && (
                      <tr className="sm:hidden border-b">
                        <td colSpan="5" className="px-5 py-5 bg-white text-sm">
                          <div className="flex flex-col">
                            <p className="text-gray-900 whitespace-no-wrap">
                              <strong>Contract No:</strong> {item.contractNo}
                            </p>
                            <p className="text-gray-900 whitespace-no-wrap">
                              <strong>Action:</strong>{" "}
                              <button
                                onClick={() => handleViewContract(contractData)}
                                className="text-blue-500 hover:underline"
                              >
                                {item.action}
                              </button>
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          {selectedContract && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Contract Details</h3>
              <div className="flex flex-col gap-4">
                <div>
                  <strong>Government ID Front:</strong>
                  <img
                    src={selectedContract.governmentIdFront}
                    alt="Government ID Front"
                    className="mt-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <strong>Government ID Back:</strong>
                  <img
                    src={selectedContract.governmentIdBack}
                    alt="Government ID Back"
                    className="mt-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <strong>Proof of Address:</strong>
                  <img
                    src={selectedContract.proofOfAddress}
                    alt="Proof of Address"
                    className="mt-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <strong>Signature:</strong>
                  <img
                    src={selectedContract.signature}
                    alt="Signature"
                    className="mt-2 border border-gray-300 rounded"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center">
          <p className="text-xl font-semibold">Candidate not contracted</p>
        </div>
      )}
    </div>
  );
};

export default SeeApplicationDetailContract;
