const initialState = {
  employerHelpAndGuide: [],
};

const helpAndGuide_employer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_EMPLOYER_HELP_AND_GUIDE":
      return {
        ...state,
        employerHelpAndGuide: action.payload,
      };
    default:
      return state;
  }
};

export default helpAndGuide_employer;
