import axios from "axios";

// Set up your base URL for the API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your actual base URL

// Function to create an Axios instance with a bearer token
const createApiClient = () => {
  const token = localStorage.getItem("accessToken"); // Get the latest token
  return axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

// API call for signup
export const signupEmployer = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.post("/employer/signup/", payload);
    localStorage.setItem("accessToken", response.data.data.accessToken);
    dispatch({ type: "STORE_EMPLOYER_PROFILE", payload: response.data.data });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// loginEmployer action
export const loginEmployer = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.post("/employer/login/", payload);
    const { accessToken } = response.data.data;

    // Set the token in local storage
    localStorage.setItem("accessToken", accessToken);

    // Ensure the token is set before dispatching and returning the response
    if (localStorage.getItem("accessToken") === accessToken) {
      dispatch({ type: "STORE_EMPLOYER_PROFILE", payload: response.data.data });
      return response.data;
    } else {
      throw new Error("Failed to store access token.");
    }
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// API call for get profile
export const getProfileEmployer = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.get("/employer/", payload);
    dispatch({ type: "STORE_EMPLOYER_PROFILE", payload: response.data.data });
    await dispatch(getEmployerPackaged());
    await dispatch(getTestPermissions());
    return response.data;
  } catch (error) {
    // throw error.response.data;
    return false;
  }
};

// logout
export const logout = () => (dispatch) => {
  dispatch({ type: "LOGOUT" });
  // Additional signout logic if necessary
};

// send forget otp
export const sendForgetOTP = (payload) => async (dispatch) => {
  try {
    // payload in body
    // {email}
    const apiClient = createApiClient();
    const response = await apiClient.post("/employer/sendForgetOTP/", payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// send verify otp
export const verifyOTP = (payload) => async (dispatch) => {
  try {
    // payload in body
    // {otp,email}
    const apiClient = createApiClient();
    const response = await apiClient.post("/employer/verifyOTP/", payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// set password in forget
export const setPasswordReset = (payload) => async (dispatch) => {
  try {
    // payload in body
    // {otp,email,password}
    const apiClient = createApiClient();
    const response = await apiClient.post("/employer/resetPassword/", payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// employer get Dashboard info
export const getEmployerDashboardInfo = (payload) => async (dispatch) => {
  try {
    // sample response
    /*
{
  "status": "success",
  "data": {
    "jobs": {
      "total": 2,
      "active": 2,
      "public": 2,
      "private": 0
    },
    "jobApplications": {
      "total": 1,
      "testCompleted": 1,
      "contractSigned": 1,
      "rejected": 0
    }
  },
  "message": "Employer dashboard data fetched"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get("/employer/dashboard/", payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// candidate get Dashboard info
export const getCandidateDashboardInfo = (payload) => async (dispatch) => {
  try {
    // sample response
    /*
{
  "status": "success",
  "data": {
    "totalApplications": 1,
    "totalTestsTaken": 1,
    "totalTestsPassed": 1,
    "totalContractsJobOffers": 1
  },
  "message": "dashboard fetched"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get("/candidate/dashboard/", payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// set password in forget
export const updateProfile = (payload) => async (dispatch) => {
  try {
    // payload in body
    // {
    //   "avatar",
    //   "first_name",
    //   "last_name",
    //   "company_name",
    //   "NewApplication",
    //   "TestTaken",
    //   "ContractSigned"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.put("/employer/", payload);
    dispatch({ type: "STORE_EMPLOYER_PROFILE", payload: response.data.data });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// upload file
export const uploadFiles = async (files) => {
  // sample response
  //{
  //   "status": "success",
  //   "photos": [
  //     "file_1723482238791_SampleVideo_1280x720_5mb.mp4"
  //   ]
  // }
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("file", files[i], files[i].name); // Use "file" as the key
  }

  // Log the FormData contents
  for (let pair of formData.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }

  try {
    const apiClient = createApiClient();
    const response = await apiClient.post("/s3/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log("file response>>>>", response.data);
    return response.data;
  } catch (error) {
    // console.error("Upload error:", error);
    throw error.response.data;
  }
};

// change Password manually
export const changePasswordManually = (payload) => async (dispatch) => {
  try {
    // payload in body
    // {
    //   "oldPassword",
    //   "newPassword",
    // }
    const apiClient = createApiClient();
    const response = await apiClient.post(
      "/employer/changePasswordManually/",
      payload
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// create test builder
export const createTestBuilder = (payload) => async (dispatch) => {
  try {
    // payload example
    // {
    //   "testName": "JavaScript Basics",
    //   "testTime": 60,
    //   "questions": [
    //     {
    //       "type": 0, // open question
    //       "questionText": "What is JavaScript?",
    //       "options": null,
    //       "correctAnswer": null,
    //       "allowFile": false // true,false
    //     }
    //     {
    //       "type": 1, // file question
    //       "questionText": "What is JavaScript?",
    //       "options": null,
    //       "correctAnswer": null,
    //       "allowFile": null
    //     }
    //     {
    //       "type": 1, // multi choise question
    //       "questionText": "What is JavaScript?",
    //       "options": [
    //         "Option 1",
    //         "Option 2",
    //         "Option 3",
    //         "Option 4"
    //       ],
    //       "correctAnswer": 3,
    //       "allowFile": null
    //     }
    //   ]
    // }
    console.log(payload);
    const transformedPayload = {
      testName: payload.testName,
      testTime: payload.testTime,
      questions: payload.questions.map((q) => ({
        type: q.type, // 0 for open question, 1 for multiple choice, 2 for file question
        questionText: q.questionText, // The main question text
        options: q.options ? q.options : null, // Null if not a multiple choice question
        correctAnswer: q.correctAnswer !== null ? q.correctAnswer : null, // Null if not multiple choice
        allowFile: q.allowFile !== undefined ? q.allowFile : null, // True or false for open questions, null for others
      })),
    };

    const apiClient = createApiClient();
    const response = await apiClient.post("/testBuilder/", transformedPayload);
    // console.log("testBuilder created >>>", response.data.data);
    dispatch({ type: "ADD_TEST_BUILDER", payload: response.data.data });
    return response.data;
  } catch (error) {
    console.log("error in testBuilder create >>>", error);
    throw error;
  }
};

// get test builders
export const getTestBuilders = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();

    const response = await apiClient.get("/testBuilder/", {
      params: {
        page: payload.page,
        limit: payload.limit,
        infinity: payload.infinity ? payload.infinity : false,
      },
    });
    response.data.data.pagination = {
      ...response.data.data.pagination,
      limit: payload.limit,
    };
    // console.log("testBuilders fetched >>>", response.data.data);
    if (!payload.infinity) {
      dispatch({ type: "SET_TEST_BUILDERS", payload: response.data.data });
    }
    // console.log(response.data.data.pagination);
    return response.data;
  } catch (error) {
    console.log("error in testBuilder fetch >>>", error.response.data);
    throw error.response.data;
  }
};

// edit test Builder
export const updateTestBuilder = (payload) => async (dispatch, id) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.put(`/testBuilder/${payload._id}/`, {
      testName: payload.testName,
      testTime: payload.testTime,
    });
    dispatch({
      type: "EDIT_TEST_BUILDER",
      payload: {
        itemId: payload._id,
        updatedTestBuilder: response.data.data,
      },
    });
    console.log("testBuilder edited >>>", response.data);
    return response.data.data;
  } catch (error) {
    console.log("error in testBuilder edit >>>", error.response.data);
    throw error.response.data;
  }
};

// delete test question
export const addQuestions = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.post(
      `/testBuilder/question/${payload._id}/`,
      {
        questions: payload.questions,
      }
    );
    dispatch({
      type: "ADD_TEST_QUESTION_IN_BUILDER",
      payload: {
        itemId: payload._id,
        questions: response.data.data,
      },
    });
    console.log("add testBuilder question >>>", response.data);
    return response.data;
  } catch (error) {
    console.log("error add testBuilder question >>>", error);
    throw error;
  }
};

// delete test builder
export const deleteTestBuilder = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(`/testBuilder/${payload._id}/`);
    dispatch({ type: "DELETE_TEST_BUILDER", payload: payload._id });
    return response.data;
  } catch (error) {
    console.log("error in testBuilder delete >>>", error.response.data);
    throw error.response.data;
  }
};

// delete test question
export const deleteQuestion = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(
      `/testBuilder/question/${payload._id}/`
    );
    dispatch({
      type: "EDIT_TEST_BUILDER",
      payload: {
        _id: payload._id,
        itemId: payload.itemId,
        updatedTestBuilder: payload.updatedTestBuilder,
      },
    });
    console.log("delete testBuilder question >>>", response.data);
    return response.data;
  } catch (error) {
    console.log("error delete testBuilder question >>>", error);
    throw error;
  }
};

// update question
export const updateQuestion = (payload) => async (dispatch) => {
  try {
    console.log(payload.question);
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/testBuilder/question/${payload._id}/`,
      { ...payload.question }
    );
    dispatch({
      type: "EDIT_TEST_BUILDER",
      payload: {
        _id: payload._id,
        itemId: payload.itemId,
        updatedTestBuilder: payload.updatedTestBuilder,
      },
    });
    console.log("edit testBuilder question >>>", response.data);
    return response.data;
  } catch (error) {
    console.log("error edit testBuilder question >>>", error);
    throw error;
  }
};

// get all jobs
export const getAllJobs = (payload) => async (dispatch) => {
  try {
    // example response
    // {
    //   "status": "success",
    //   "data": {
    //     "jobs": [
    //       {
    //         "specification": {
    //           "title": "Senior Software Engineer",
    //           "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723281197497_SampleVideo_1280x720_5mb.mp4",
    //           "docs": "https://grapesoftcouk.s3.amazonaws.com/file_1723278181465_HR interview application.pdf"
    //         },
    //         "training": {
    //           "title": "Senior Software Engineer",
    //           "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723278099358_SampleVideo_1280x720_5mb.mp4",
    //           "docs": "https://grapesoftcouk.s3.amazonaws.com/file_1723278208053_HR interview application.pdf"
    //         },
    //         "contract": {
    //           "title": "Senior Software Engineer",
    //           "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723278134810_SampleVideo_1280x720_5mb.mp4"
    //         },
    //         "_id": "66b72488721c1d7fe76a3ac6",
    //         "employerId": "66b202ea912827180a73fa01",
    //         "title": "Senior Software Engineer",
    //         "testBuilderId": null,
    //         "status": true,
    //         "privateOrPublic": true,
    //         "coverLetter": true,
    //         "cv": true,
    //         "aboutVideo": true,
    //         "createdAt": "2024-08-10T08:27:52.513Z",
    //         "updatedAt": "2024-08-11T10:07:07.321Z",
    //         "__v": 0
    //       }
    //     ],
    //     "pagination": {
    //       "currentPage": 1,
    //       "totalPages": 1,
    //       "totalJobs": 1
    //     }
    //   },
    //   "message": "Jobs fetched successfully"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.get(`/job/`, {
      params: { page: payload.page, limit: payload.limit },
    });
    dispatch({ type: "ADD_LIST_JOBs", payload: response.data.data });
    return response.data;
  } catch (error) {
    console.log("error in testBuilder delete >>>", error);
    throw error;
  }
};

// get all jobs for candidates
export const getAllJobsCandidates = (payload) => async (dispatch) => {
  try {
    // example response
    // {
    //   "status": "success",
    //   "data": {
    //     "jobs": [
    //       {
    //         "specification": {
    //           "title": "Senior Software Engineer",
    //           "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723281197497_SampleVideo_1280x720_5mb.mp4",
    //           "docs": "https://grapesoftcouk.s3.amazonaws.com/file_1723278181465_HR interview application.pdf"
    //         },
    //         "training": {
    //           "title": "Senior Software Engineer",
    //           "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723278099358_SampleVideo_1280x720_5mb.mp4",
    //           "docs": "https://grapesoftcouk.s3.amazonaws.com/file_1723278208053_HR interview application.pdf"
    //         },
    //         "contract": {
    //           "title": "Senior Software Engineer",
    //           "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723278134810_SampleVideo_1280x720_5mb.mp4"
    //         },
    //         "_id": "66b72488721c1d7fe76a3ac6",
    //         "employerId": "66b202ea912827180a73fa01",
    //         "title": "Senior Software Engineer",
    //         "testBuilderId": null,
    //         "status": true,
    //         "privateOrPublic": true,
    //         "coverLetter": true,
    //         "cv": true,
    //         "aboutVideo": true,
    //         "createdAt": "2024-08-10T08:27:52.513Z",
    //         "updatedAt": "2024-08-11T10:07:07.321Z",
    //         "__v": 0
    //       }
    //     ],
    //     "pagination": {
    //       "currentPage": 1,
    //       "totalPages": 1,
    //       "totalJobs": 1
    //     }
    //   },
    //   "message": "Jobs fetched successfully"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.get(`/job/forCandidate/all`, {
      params: { page: payload.page, limit: payload.limit },
    });
    return response.data;
  } catch (error) {
    console.log("error in jobs get >>>", error);
    throw error;
  }
};

// create a job
export const createJob = (payload) => async (dispatch) => {
  try {
    // example body
    // {
    //   "title": "Frontend Developer",
    //   "specification": {
    //     "title": "Job Specification",
    //     "video": "specification_video.mp4",
    //     "docs": "specification_docs.pdf"
    //   },
    //   "training": {
    //     "title": "Training Material",
    //     "video": "training_video.mp4",
    //     "docs": "training_docs.pdf"
    //   },
    //   "contract": {
    //     "title": "Contract Material",
    //     "docs": "contract_video.mp4"
    //   },
    //   "testBuilderId": "60d9f8f5d5f9c6001c8e4f2a",
    //   "coverLetter": true,
    //   "cv": true,
    //   "aboutVideo": true
    // }
    const apiClient = createApiClient();
    const response = await apiClient.post(`/job/`, payload);
    dispatch({
      type: "ADD_JOB",
      payload: response.data.data,
    });
    return response.data;
  } catch (error) {
    console.log("error add job  >>>", error);
    throw error;
  }
};

// delete a job
export const deleteJob = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(`/job/${payload.id}/`);
    dispatch({
      type: "DELETE_JOB",
      payload: payload.id,
    });
    console.log("job deleted >>>", response.data);
  } catch (error) {
    console.log("error delete job >>>", error);
    throw error;
  }
};

// get specific job
export const getSpecificJob = (payload) => async (dispatch) => {
  try {
    // example response
    /*
    {
  "status": "success",
  "data": {
    "specification": {
      "title": "ok",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723977150568_SampleVideo_1280x720_5mb.mp4",
      "docs": "https://grapesoftcouk.s3.amazonaws.com/file_1723977155624_HR interview application.pdf"
    },
    "training": {
      "title": "ok",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723977165346_SampleVideo_1280x720_5mb.mp4",
      "docs": "https://grapesoftcouk.s3.amazonaws.com/file_1723977173206_HR interview application.pdf"
    },
    "contract": {
      "title": "ok",
      "docs": "file_1723977182585_HR interview application.pdf"
    },
    "_id": "66c1cdf2367157b8a23f0d59",
    "employerId": "66b202ea912827180a73fa01",
    "title": "new job",
    "testBuilderId": "66bfae494fe4fe57d381b1e0",
    "status": false,
    "privateOrPublic": true,
    "coverLetter": true,
    "cv": false,
    "aboutVideo": true,
    "createdAt": "2024-08-18T10:33:22.984Z",
    "updatedAt": "2024-08-20T20:04:40.951Z",
    "__v": 0
  },
  "message": "job fetched successfully"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get(`/job/${payload.id}/`);
    console.log("job get >>>", response.data);
    return response.data.data;
  } catch (error) {
    console.log("error get job >>>", error);
    throw error;
  }
};

// create a job
export const editJob = (payload) => async (dispatch) => {
  try {
    // example body
    // {
    //   "title": "Frontend Developer",
    //   "specification": {
    //     "title": "Job Specification",
    //     "video": "specification_video.mp4",
    //     "docs": "specification_docs.pdf"
    //   },
    //   "training": {
    //     "title": "Training Material",
    //     "video": "training_video.mp4",
    //     "docs": "training_docs.pdf"
    //   },
    //   "contract": {
    //     "title": "Contract Material",
    //     "docs": "contract_video.mp4"
    //   },
    //   "testBuilderId": "60d9f8f5d5f9c6001c8e4f2a",
    //   "coverLetter": true,
    //   "cv": true,
    //   "aboutVideo": true
    // }
    const apiClient = createApiClient();
    const _id = payload._id;
    delete payload._id;
    delete payload.contractSigned;
    delete payload.applications;
    delete payload.testCompleted;
    delete payload.createdAt;
    delete payload.updatedAt;
    delete payload.employerId;
    delete payload.__v;
    delete payload.isDeleted;
    // delete payload.
    const response = await apiClient.put(`/job/${_id}/`, payload);
    dispatch({
      type: "EDIT_JOB",
      payload: response.data.data,
    });
    return response.data;
  } catch (error) {
    console.log("error add job  >>>", error);
    throw error;
  }
};

// create job draft
export const getJobDraft = (payload) => async (dispatch) => {
  try {
    // example response
    // {
    //   "title": "Frontend Developer",
    //   "specification": {
    //     "title": "Job Specification",
    //     "video": "specification_video.mp4",
    //     "docs": "specification_docs.pdf"
    //   },
    //   "training": {
    //     "title": "Training Material",
    //     "video": "training_video.mp4",
    //     "docs": "training_docs.pdf"
    //   },
    //   "contract": {
    //     "title": "Contract Material",
    //     "docs": "contract_video.mp4"
    //   },
    //   "testBuilderId": "60d9f8f5d5f9c6001c8e4f2a",
    //   "coverLetter": true,
    //   "cv": true,
    //   "aboutVideo": true
    // }
    const apiClient = createApiClient();
    const response = await apiClient.post(`/job/getDraft/`);
    console.log("job draft fetched  >>>", response.data);
    return response.data;
  } catch (error) {
    console.log("error get job draft  >>>", error);
    throw error;
  }
};

// create job draft
export const createJobDraft = (payload) => async (dispatch) => {
  try {
    // example body
    // {
    //   "title": "Frontend Developer",
    //   "specification": {
    //     "title": "Job Specification",
    //     "video": "specification_video.mp4",
    //     "docs": "specification_docs.pdf"
    //   },
    //   "training": {
    //     "title": "Training Material",
    //     "video": "training_video.mp4",
    //     "docs": "training_docs.pdf"
    //   },
    //   "contract": {
    //     "title": "Contract Material",
    //     "docs": "contract_video.mp4"
    //   },
    //   "testBuilderId": "60d9f8f5d5f9c6001c8e4f2a",
    //   "coverLetter": true,
    //   "cv": true,
    //   "aboutVideo": true
    // }
    const apiClient = createApiClient();
    const response = await apiClient.post(`/job/draft/`, payload);
    console.log("job draft added  >>>", response.data);
    return response.data;
  } catch (error) {
    console.log("error add job draft  >>>", error);
    throw error;
  }
};

// get employer help and guide
export const getEmployerHelpGuide = (payload) => async (dispatch) => {
  try {
    // example response
    /*{
  "status": "success",
  "data": [
    {
      "_id": "66c2656b2dca2c6476bdc468",
      "title": "gvhj",
      "description": "jcfhvj",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1724087005173_SampleVideo_1280x720_5mb.mp4",
      "sort": 1,
      "createdAt": "2024-08-18T21:19:39.270Z",
      "updatedAt": "2024-08-19T17:28:01.681Z",
      "__v": 0
    },
    {
      "_id": "66c37d3f5722eca462241489",
      "title": "yhj",
      "description": "ghijkl",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1724087601781_employer area screen record.mp4",
      "sort": 2,
      "createdAt": "2024-08-19T17:13:35.194Z",
      "updatedAt": "2024-08-19T17:39:58.829Z",
      "__v": 0
    },
    {
      "_id": "66c384915722eca4622414b5",
      "title": "rytgui",
      "description": "cghj",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1724089471886_employer area screen record.mp4",
      "sort": 3,
      "createdAt": "2024-08-19T17:44:49.253Z",
      "updatedAt": "2024-08-19T17:44:49.253Z",
      "__v": 0
    },
    {
      "_id": "66c386365722eca4622414ce",
      "title": "tfuyi",
      "description": "tuyiui",
      "video": null,
      "sort": 4,
      "createdAt": "2024-08-19T17:51:50.898Z",
      "updatedAt": "2024-08-19T17:51:50.898Z",
      "__v": 0
    }
  ],
  "message": "All help and guide questionnaires"
} */
    const apiClient = createApiClient();
    const response = await apiClient.get(`/helpguideemployer/`);
    dispatch({
      type: "ADD_EMPLOYER_HELP_AND_GUIDE",
      payload: response.data.data,
    });
    return response.data.data;
  } catch (error) {
    console.log("error get employer help guide >>>", error);
    throw error;
  }
};
// get employer help and guide
export const getCandidateHelpGuide = (payload) => async (dispatch) => {
  try {
    // example response
    /*{
  "status": "success",
  "data": [
    {
      "_id": "66c2656b2dca2c6476bdc468",
      "title": "gvhj",
      "description": "jcfhvj",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1724087005173_SampleVideo_1280x720_5mb.mp4",
      "sort": 1,
      "createdAt": "2024-08-18T21:19:39.270Z",
      "updatedAt": "2024-08-19T17:28:01.681Z",
      "__v": 0
    },
    {
      "_id": "66c37d3f5722eca462241489",
      "title": "yhj",
      "description": "ghijkl",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1724087601781_employer area screen record.mp4",
      "sort": 2,
      "createdAt": "2024-08-19T17:13:35.194Z",
      "updatedAt": "2024-08-19T17:39:58.829Z",
      "__v": 0
    },
    {
      "_id": "66c384915722eca4622414b5",
      "title": "rytgui",
      "description": "cghj",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1724089471886_employer area screen record.mp4",
      "sort": 3,
      "createdAt": "2024-08-19T17:44:49.253Z",
      "updatedAt": "2024-08-19T17:44:49.253Z",
      "__v": 0
    },
    {
      "_id": "66c386365722eca4622414ce",
      "title": "tfuyi",
      "description": "tuyiui",
      "video": null,
      "sort": 4,
      "createdAt": "2024-08-19T17:51:50.898Z",
      "updatedAt": "2024-08-19T17:51:50.898Z",
      "__v": 0
    }
  ],
  "message": "All help and guide questionnaires"
} */
    const apiClient = createApiClient();
    const response = await apiClient.get(`/helpguidecandidate/`);
    dispatch({
      type: "ADD_candidate_HELP_AND_GUIDE",
      payload: response.data.data,
    });
    return response.data.data;
  } catch (error) {
    console.log("error get employer help guide >>>", error);
    throw error;
  }
};

// get employer Packages
export const getEmployerPackaged = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.get(`/package/get/employer/`);
    dispatch({
      type: "ADD_PACKAGE_HELP_AND_GUIDE",
      payload: response.data.data,
    });
    // console.log("success get employer packages >>>", response.data.data);
    return response.data.data;
  } catch (error) {
    // console.log("error get employer packages >>>", error);
    throw error;
  }
};

// get employer Packages
export const newSubscription = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.post(`/subscription/`, {
      packageId: payload._id,
    });
    dispatch({
      type: "SET_CURRENT_SUBSCRIPTION",
      payload: response.data.data,
    });
    console.log("subscription set successfully >>>", response.data.data);
    return response.data.data;
  } catch (error) {
    console.log("error set subscription >>>", error);
    throw error;
  }
};

// apply job candidate
export const applyJobCandidate = (payload) => async (dispatch) => {
  try {
    // sample payload
    /*
    {
  "jobId": "string",
  "first_name": "string",
  "last_name": "string",
  "email": "string",
  "countryOfRecidence": "string",
  "countryOfBirth": "string",
  "timezone": "string",
  "contactNumber": "string",
  "aboutVideo": "string",
  "cv": "string",
  "coverLetter": "string"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.post(`/candidateJob/apply/`, payload);
    return response.data;
  } catch (error) {
    // console.log("error apply job candidate >>>", error);
    throw error.response.data;
  }
};

// API call for signup
export const signupCandidate = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.post("/candidate/signup/", payload);
    localStorage.setItem("accessToken", response.data.data.accessToken);
    dispatch({ type: "STORE_CANDIDATE_PROFILE", payload: response.data.data });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// login candidate
export const loginCandidate = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.post("/candidate/login/", payload);
    const { accessToken } = response.data.data;

    // Set the token in local storage
    localStorage.setItem("accessToken", accessToken);

    // Ensure the token is set before dispatching and returning the response
    if (localStorage.getItem("accessToken") === accessToken) {
      dispatch({
        type: "STORE_CANDIDATE_PROFILE",
        payload: response.data.data,
      });
      return response.data;
    } else {
      throw new Error("Failed to store access token.");
    }
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// get Candidate profile
export const getCandidateProfile = (payload) => async (dispatch) => {
  try {
    // sample response
    /*
    {
  "status": "success",
  "data": {
    "_id": "66c77ac585b7d3341d8818da",
    "avatar": null,
    "first_name": "string",
    "last_name": "string",
    "email": "abdulwadoodowner@gmail.com",
    "password": "U2FsdGVkX19LC2anGUcl9DdhAQDg9wezP1d8rxjJnQTt8fedmsge8uthx7poL0nI",
    "encryptOTP": null,
    "countryOfRecidence": "string",
    "countryOfBirth": "string",
    "timezone": null,
    "contactNumber": "string",
    "whatsapp": null,
    "telegram": null,
    "skype": null,
    "other": null,
    "aboutVideo": "https://grapesoftcouk.s3.amazonaws.com/file_1724344476936_SampleVideo_1280x720_5mb.mp4",
    "cv": "https://grapesoftcouk.s3.amazonaws.com/file_1724344393770_HR interview application.pdf",
    "coverLetter": "string",
    "refreshToken": [],
    "createdAt": "2024-08-22T17:52:05.138Z",
    "updatedAt": "2024-08-22T20:10:40.852Z",
    "__v": 0
  },
  "message": "Candidate Profile fetched"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get("/candidate/", payload);
    dispatch({ type: "STORE_CANDIDATE_PROFILE", payload: response.data.data });
    await dispatch(getTestPermissions());
    return response.data;
  } catch (error) {
    // throw error.response.data;
    return false;
  }
};

// send forget otp
export const sendForgetOTPCandidate = (payload) => async (dispatch) => {
  try {
    // payload in body
    // {email}
    const apiClient = createApiClient();
    const response = await apiClient.post("/candidate/sendForgetOTP/", payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// send verify otp
export const verifyOTPCandidate = (payload) => async (dispatch) => {
  try {
    // payload in body
    // {otp,email}
    const apiClient = createApiClient();
    const response = await apiClient.post("/candidate/verifyOTP/", payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// set password in forget
export const setPasswordResetCandidate = (payload) => async (dispatch) => {
  try {
    // payload in body
    // {otp,email,password}
    const apiClient = createApiClient();
    const response = await apiClient.post("/candidate/resetPassword/", payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// update profile candidate
export const updateProfileCandidate = (payload) => async (dispatch) => {
  try {
    // payload in body
    /*
{
  "avatar": "string",
  "first_name": "John",
  "last_name": "Doe",
  "countryOfRecidence": "United Kingdom",
  "countryOfBirth": "United Kingdom",
  "timezone": "GMT",
  "contactNumber": "+441234567890",
  "whatsapp": "+441234567890",
  "telegram": "@johndoe",
  "skype": "live:johndoe",
  "other": "Other contact details",
  "aboutVideo": "string",
  "cv": "string",
  "coverLetter": "string"
}
*/
    const apiClient = createApiClient();
    const response = await apiClient.put("/candidate/", payload);
    dispatch({ type: "STORE_CANDIDATE_PROFILE", payload: response.data.data });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

//  job application section
export const EmployerGetjobApplications = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // sample response
    /*
    
    {
  "status": "success",
  "data": {
    "jobApplications": [
      {
        "_id": "66c78d488b752c02f64ad69e",
        "candidateId": "66c78d488b752c02f64ad69c",
        "jobId": "66c1cdf2367157b8a23f0d59",
        "first_name": "Abdul",
        "last_name": "Waheed",
        "email": "abdulwaheedowner@gmail.com",
        "countryOfRecidence": "Azerbaijan",
        "countryOfBirth": "Austria",
        "timezone": null,
        "contactNumber": "2345678975643",
        "availabilityDate": null,
        "aboutVideo": "https://grapesoftcouk.s3.amazonaws.com/file_1724353852557_SampleVideo_1280x720_5mb.mp4",
        "cv": null,
        "coverLetter": "very nice job",
        "status": 0,
        "createdAt": "2024-08-22T19:11:04.416Z",
        "updatedAt": "2024-08-24T08:59:47.579Z",
        "__v": 0,
        "note": "this is note",
        "candidate": {
          "_id": "66c78d488b752c02f64ad69c",
          "avatar": null,
          "first_name": "Abdul",
          "last_name": "Waheed",
          "email": "abdulwaheedowner@gmail.com",
          "password": null,
          "encryptOTP": null,
          "countryOfRecidence": "Azerbaijan",
          "countryOfBirth": "Austria",
          "timezone": null,
          "contactNumber": "2345678975643",
          "whatsapp": null,
          "telegram": null,
          "skype": null,
          "other": null,
          "aboutVideo": "https://grapesoftcouk.s3.amazonaws.com/file_1724353852557_SampleVideo_1280x720_5mb.mp4",
          "cv": null,
          "coverLetter": "very nice job",
          "refreshToken": [],
          "createdAt": "2024-08-22T19:11:04.179Z",
          "updatedAt": "2024-08-22T19:11:04.179Z",
          "__v": 0
        }
      }
    ],
    "pagination": {
      "totalPages": 1,
      "currentPage": 1,
      "totalDocuments": 1,
      "pageSize": 10
    }
  },
  "message": "Help and guide created"
}
    
    */
    const response = await apiClient.get(
      `/jobApplication?page=${payload.page}&limit=${payload.limit}`
    );
    dispatch({
      type: "ADD_LIST_JOBS_APPLICATIONS",
      payload: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

//  job application section
export const EmployerGetSpecificJobApplication =
  (payload) => async (dispatch) => {
    try {
      const apiClient = createApiClient();
      // sample response
      /*
    
{
  "status": "success",
  "data": {
    "_id": "66c78d488b752c02f64ad69e",
    "candidateId": "66c78d488b752c02f64ad69c",
    "jobId": "66c1cdf2367157b8a23f0d59",
    "first_name": "Abdul",
    "last_name": "Waheed",
    "email": "abdulwaheedowner@gmail.com",
    "countryOfRecidence": "Azerbaijan",
    "countryOfBirth": "Austria",
    "timezone": null,
    "contactNumber": "2345678975643",
    "availabilityDate": null,
    "aboutVideo": "https://grapesoftcouk.s3.amazonaws.com/file_1724353852557_SampleVideo_1280x720_5mb.mp4",
    "cv": null,
    "coverLetter": "very nice job",
    "status": 0,
    "createdAt": "2024-08-22T19:11:04.416Z",
    "updatedAt": "2024-08-24T08:59:47.579Z",
    "__v": 0,
    "note": "this is note",
    "candidate": {
      "_id": "66c78d488b752c02f64ad69c",
      "avatar": null,
      "first_name": "Abdul",
      "last_name": "Waheed",
      "email": "abdulwaheedowner@gmail.com",
      "encryptOTP": null,
      "countryOfRecidence": "Azerbaijan",
      "countryOfBirth": "Austria",
      "timezone": null,
      "contactNumber": "2345678975643",
      "whatsapp": null,
      "telegram": null,
      "skype": null,
      "other": null,
      "aboutVideo": "https://grapesoftcouk.s3.amazonaws.com/file_1724353852557_SampleVideo_1280x720_5mb.mp4",
      "cv": null,
      "coverLetter": "very nice job",
      "createdAt": "2024-08-22T19:11:04.179Z",
      "updatedAt": "2024-08-22T19:11:04.179Z"
    }
  },
  "message": "Job application fetched successfully"
}

    */
      const response = await apiClient.get(
        `/jobApplication/singleProduct?jobApplicationId=${payload.jobApplicationId}`
      );
      dispatch({
        type: "ADD_JOB_APPLICATION_SINGLE",
        payload: response.data.data,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };
//  job application section
export const CandidateGetSpecificJobApplication =
  (payload) => async (dispatch) => {
    try {
      const apiClient = createApiClient();
      // sample response
      /*
    
{
  "status": "success",
  "data": {
    "_id": "66c78d488b752c02f64ad69e",
    "candidateId": "66c78d488b752c02f64ad69c",
    "jobId": "66c1cdf2367157b8a23f0d59",
    "first_name": "Abdul",
    "last_name": "Waheed",
    "email": "abdulwaheedowner@gmail.com",
    "countryOfRecidence": "Azerbaijan",
    "countryOfBirth": "Austria",
    "timezone": null,
    "contactNumber": "2345678975643",
    "availabilityDate": null,
    "aboutVideo": "https://grapesoftcouk.s3.amazonaws.com/file_1724353852557_SampleVideo_1280x720_5mb.mp4",
    "cv": null,
    "coverLetter": "very nice job",
    "status": 0,
    "createdAt": "2024-08-22T19:11:04.416Z",
    "updatedAt": "2024-08-24T08:59:47.579Z",
    "__v": 0,
    "note": "this is note",
    "candidate": {
      "_id": "66c78d488b752c02f64ad69c",
      "avatar": null,
      "first_name": "Abdul",
      "last_name": "Waheed",
      "email": "abdulwaheedowner@gmail.com",
      "encryptOTP": null,
      "countryOfRecidence": "Azerbaijan",
      "countryOfBirth": "Austria",
      "timezone": null,
      "contactNumber": "2345678975643",
      "whatsapp": null,
      "telegram": null,
      "skype": null,
      "other": null,
      "aboutVideo": "https://grapesoftcouk.s3.amazonaws.com/file_1724353852557_SampleVideo_1280x720_5mb.mp4",
      "cv": null,
      "coverLetter": "very nice job",
      "createdAt": "2024-08-22T19:11:04.179Z",
      "updatedAt": "2024-08-22T19:11:04.179Z"
    }
  },
  "message": "Job application fetched successfully"
}

    */
      const response = await apiClient.get(
        `/jobApplication/singleProduct?jobApplicationId=${payload.jobApplicationId}`
      );
      dispatch({
        type: "ADD_JOB_APPLICATION_SINGLE_CANDIDATE",
        payload: response.data.data,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

// job note update
export const UpdateJobNote = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/jobApplication/editNote?jobId=${payload.jobId}&candidateId=${payload.candidateId}`,
      { note: payload.note }
    );
    dispatch({
      type: "EDIT_JOB_APPLICATION",
      payload: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// delete job note
export const DeleteJobNote = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(
      `/jobApplication/deleteApplication?jobId=${payload.jobId}&candidateId=${payload.candidateId}`
    );
    dispatch({
      type: "DELETE_JOB_APPLICATION",
      payload: payload._id,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// accept job note
export const acceptJobApplication = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/jobApplication/acceptApplication?jobId=${payload.jobId}&candidateId=${payload.candidateId}&jobApplicationId=${payload.jobApplicationId}`
    );

    dispatch({
      type: "ADD_JOB_APPLICATION_SINGLE",
      payload: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// passed from test
export const passApplication = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/jobApplication/passApplication?jobId=${payload.jobId}&candidateId=${payload.candidateId}&jobApplicationId=${payload.jobApplicationId}`
    );

    dispatch({
      type: "ADD_JOB_APPLICATION_SINGLE",
      payload: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// delete job note
export const rejectedApplication = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/jobApplication/rejectedApplication?jobId=${payload.jobId}&candidateId=${payload.candidateId}&jobApplicationId=${payload.jobApplicationId}`
    );

    dispatch({
      type: "ADD_JOB_APPLICATION_SINGLE",
      payload: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
// delete approve contract
export const approveContract = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/jobApplication/ContractApproved?jobId=${payload.jobId}&candidateId=${payload.candidateId}&jobApplicationId=${payload.jobApplicationId}`
    );

    dispatch({
      type: "ADD_JOB_APPLICATION_SINGLE",
      payload: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const sendVerifyEmailOTP = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    await apiClient.post(`/candidate/sendVerifyEmailOTP/`, {
      email: payload.email,
    });
    return true;
  } catch (error) {
    throw error;
  }
};
export const verifyAccountByOTP = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    await apiClient.post(`/candidate/verifyAccountByOTP/`, {
      otp: payload.otp,
      email: payload.email,
    });
    return true;
  } catch (error) {
    throw error;
  }
};
export const completeProfileWithPasswordCandidate =
  (payload) => async (dispatch) => {
    try {
      const apiClient = createApiClient();
      const response = await apiClient.post(`/candidate/password/`, {
        email: payload.email,
        password: payload.password,
      });

      const { accessToken } = response.data.data;

      // Set the token in local storage
      localStorage.setItem("accessToken", accessToken);

      // Ensure the token is set before dispatching and returning the response
      if (localStorage.getItem("accessToken") === accessToken) {
        dispatch({
          type: "STORE_CANDIDATE_PROFILE",
          payload: response.data.data,
        });
        return response.data;
      } else {
        throw new Error("Failed to store access token.");
      }
    } catch (error) {
      throw error.response.data;
    }
  };

// get test Permissions
export const getTestPermissions = (payload) => async (dispatch) => {
  try {
    // example response
    /*
{
  "status": "success",
  "data": {
    "_id": "66cb0ca1ad1a5491332e846c",
    "__v": 0,
    "createdAt": "2024-08-25T10:51:09.926Z",
    "fileDataMax": 10,
    "multiChoiceQuestions": 4,
    "openQuestionWords": 200,
    "updatedAt": "2024-08-25T11:18:31.493Z"
  },
  "message": "Test permissions get successfully"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get("/testPermissions/");
    console.log("testPermissions response>>>>", response.data);

    dispatch({
      type: "STORE_TEST_PERMISSIONS",
      payload: response.data.data,
    });
    return response.data.data;
  } catch (error) {
    console.log("failed testPermissions ==> ", error);
    throw error;
  }
};
// get test getCandidateJobApplications
export const getCandidateJobApplications = (payload) => async (dispatch) => {
  try {
    // example response
    /*
{
  "status": "success",
  "data": {
    "jobApplications": [
      {
        "_id": "66caf338bc63d0f47f5ea9b1",
        "candidateId": "66ca471911923998bad9ffb2",
        "jobId": "66c1cdf2367157b8a23f0d59",
        "first_name": "mantiq",
        "last_name": "mantiq soft",
        "email": "mantiqsoft@gmail.com",
        "countryOfRecidence": "Algeria",
        "countryOfBirth": "Barbados",
        "timezone": null,
        "contactNumber": "2345678975643",
        "availabilityDate": "2024-08-08T00:00:00.000Z",
        "aboutVideo": "file_1724576557380_SampleVideo_1280x720_5mb.mp4",
        "cv": null,
        "coverLetter": "setrfgh dtrg",
        "status": 1,
        "success": 0,
        "note": "dtfgyuh tfui",
        "createdAt": "2024-08-25T09:02:48.245Z",
        "updatedAt": "2024-08-25T10:10:17.084Z",
        "__v": 0,
        "jobTitle": "new job"
      }
    ],
    "paginationInfo": {
      "totalDocuments": 1,
      "currentPage": 1,
      "totalPages": 1,
      "pageSize": 10
    }
  },
  "message": "Job applications fetched successfully"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get("/candidateJob/applications/");
    // console.log("getCandidateJobApplications response>>>>", response.data);

    dispatch({
      type: "ADD_LIST_JOBS_APPLICATIONS_CANDIDATE",
      payload: response.data.data,
    });
    return response.data.data;
  } catch (error) {
    console.log("failed getCandidateJobApplications ==> ", error);
    throw error;
  }
};

// get the test
export const getCandidateJobApplicationTest = (payload) => async (dispatch) => {
  try {
    // example response
    /*
{
  "status": "success",
  "data": {
    "job": {
      "specification": {
        "title": "ok",
        "video": "file_1723977150568_SampleVideo_1280x720_5mb.mp4",
        "docs": "file_1723977155624_HR interview application.pdf"
      },
      "training": {
        "title": "ok",
        "video": "file_1723977165346_SampleVideo_1280x720_5mb.mp4",
        "docs": "file_1723977173206_HR interview application.pdf"
      },
      "contract": {
        "title": "ok",
        "docs": "file_1723977182585_HR interview application.pdf"
      },
      "_id": "66c1cdf2367157b8a23f0d59",
      "employerId": "66b202ea912827180a73fa01",
      "title": "new job",
      "testBuilderId": "66cb7e4d9d6c20ba181b325b",
      "status": true,
      "privateOrPublic": true,
      "coverLetter": true,
      "cv": false,
      "aboutVideo": true,
      "createdAt": "2024-08-18T10:33:22.984Z",
      "updatedAt": "2024-08-25T19:05:47.546Z",
      "__v": 0
    },
    "testBuilder": {
      "_id": "66cb7e4d9d6c20ba181b325b",
      "employerId": "66b202ea912827180a73fa01",
      "testName": "sample test Builder",
      "testTime": 2,
      "createdAt": "2024-08-25T18:56:13.965Z",
      "updatedAt": "2024-08-25T18:56:13.965Z",
      "__v": 0
    },
    "testQuestions": [
      {
        "_id": "66cb7e4e9d6c20ba181b325d",
        "employerId": "66b202ea912827180a73fa01",
        "testBuilderId": "66cb7e4d9d6c20ba181b325b",
        "type": 0,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": true,
        "__v": 0,
        "createdAt": "2024-08-25T18:56:14.198Z",
        "updatedAt": "2024-08-25T18:56:14.198Z"
      },
      {
        "_id": "66cb7e4e9d6c20ba181b325e",
        "employerId": "66b202ea912827180a73fa01",
        "testBuilderId": "66cb7e4d9d6c20ba181b325b",
        "type": 0,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": false,
        "__v": 0,
        "createdAt": "2024-08-25T18:56:14.199Z",
        "updatedAt": "2024-08-25T18:56:14.199Z"
      },
      {
        "_id": "66cb7e4e9d6c20ba181b325f",
        "employerId": "66b202ea912827180a73fa01",
        "testBuilderId": "66cb7e4d9d6c20ba181b325b",
        "type": 2,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": null,
        "__v": 0,
        "createdAt": "2024-08-25T18:56:14.199Z",
        "updatedAt": "2024-08-25T18:56:14.199Z"
      },
      {
        "_id": "66cb7e4e9d6c20ba181b3260",
        "employerId": "66b202ea912827180a73fa01",
        "testBuilderId": "66cb7e4d9d6c20ba181b325b",
        "type": 1,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": [
          "fine",
          "not fine"
        ],
        "correctAnswer": 0,
        "allowFile": null,
        "__v": 0,
        "createdAt": "2024-08-25T18:56:14.199Z",
        "updatedAt": "2024-08-25T18:56:14.199Z"
      },
      {
        "_id": "66cb7e4e9d6c20ba181b3261",
        "employerId": "66b202ea912827180a73fa01",
        "testBuilderId": "66cb7e4d9d6c20ba181b325b",
        "type": 2,
        "questionText": "how are you hi?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": null,
        "__v": 0,
        "createdAt": "2024-08-25T18:56:14.199Z",
        "updatedAt": "2024-08-25T18:56:14.199Z"
      },
      {
        "_id": "66cb7e4e9d6c20ba181b3262",
        "employerId": "66b202ea912827180a73fa01",
        "testBuilderId": "66cb7e4d9d6c20ba181b325b",
        "type": 0,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": false,
        "__v": 0,
        "createdAt": "2024-08-25T18:56:14.199Z",
        "updatedAt": "2024-08-25T18:56:14.199Z"
      },
      {
        "_id": "66cb7e4e9d6c20ba181b3263",
        "employerId": "66b202ea912827180a73fa01",
        "testBuilderId": "66cb7e4d9d6c20ba181b325b",
        "type": 0,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": false,
        "__v": 0,
        "createdAt": "2024-08-25T18:56:14.200Z",
        "updatedAt": "2024-08-25T18:56:14.200Z"
      }
    ]
  },
  "message": "test fetched success"
}
    */

    const apiClient = createApiClient();
    const response = await apiClient.get(
      `/test?jobApplyId=${payload.jobApplyId}`
    );
    console.log("getCandidateJobApplicationTest response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed getCandidateJobApplicationTest ==> ", error);
    throw error;
  }
};

// post the test
export const postCandidateJobApplicationTest =
  (payload) => async (dispatch) => {
    try {
      // example payload needed
      /*
{
  "jobApplyId": "60d5ec49f5f63b1b0c8d2e3f",
  "recordedVideo": "ok.mp4",
  "questions": [
    {
      "type": 1,
      "questionText": "What is the capital of France?",
      "wordLimit": 100,
      "options": [
        "Paris",
        "Berlin",
        "Rome"
      ],
      "correctAnswer": 0,
      "allowFile": true,
      "isCorrect": true,
      "answer": "string"
    }
  ]
}
*/

      const apiClient = createApiClient();
      const response = await apiClient.post(`/test`, payload);
      console.log(
        "postCandidateJobApplicationTest response>>>>",
        response.data
      );

      return response.data.data;
    } catch (error) {
      console.log("failed postCandidateJobApplicationTest ==> ", error);
      throw error;
    }
  };

// get submitted test
export const getSubmittedTest = (payload) => async (dispatch) => {
  try {
    // payload required
    /*
{
  jobApplyId,
  candidateId
}
    */

    // example response

    /*

{
  "status": "success",
  "data": {
    "_id": "66d0bd625e8a5d7b58acfac4",
    "jobApplyId": "66d0bb605e8a5d7b58acfa84",
    "jobId": "66d0bb605e8a5d7b58acfa84",
    "candidateId": "66d0aa4d5e8a5d7b58acf848",
    "employerId": "66b202ea912827180a73fa01",
    "questions": [
      {
        "type": 0,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": true,
        "fileAnswer": null,
        "answer": null,
        "isCorrect": null,
        "_id": "66d0bd625e8a5d7b58acfac5"
      },
      {
        "type": 0,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": false,
        "fileAnswer": null,
        "answer": "tfghcguvjhiu",
        "isCorrect": null,
        "_id": "66d0bd625e8a5d7b58acfac6"
      },
      {
        "type": 2,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": null,
        "fileAnswer": "file_1724955964564_SampleVideo_1280x720_5mb.mp4",
        "answer": null,
        "isCorrect": null,
        "_id": "66d0bd625e8a5d7b58acfac7"
      },
      {
        "type": 1,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": [
          "fine",
          "not fine"
        ],
        "correctAnswer": 0,
        "allowFile": null,
        "fileAnswer": null,
        "answer": "fine",
        "isCorrect": null,
        "_id": "66d0bd625e8a5d7b58acfac8"
      },
      {
        "type": 2,
        "questionText": "how are you hi?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": null,
        "fileAnswer": null,
        "answer": null,
        "isCorrect": null,
        "_id": "66d0bd625e8a5d7b58acfac9"
      },
      {
        "type": 0,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": false,
        "fileAnswer": null,
        "answer": "vyuhiujyubjhk",
        "isCorrect": null,
        "_id": "66d0bd625e8a5d7b58acfaca"
      },
      {
        "type": 0,
        "questionText": "how are you?",
        "wordLimit": 1,
        "options": null,
        "correctAnswer": null,
        "allowFile": false,
        "fileAnswer": null,
        "answer": null,
        "isCorrect": null,
        "_id": "66d0bd625e8a5d7b58acfacb"
      }
    ],
    "recordedVideo": "https://grapesoftcouk.s3.amazonaws.com/file_1724955990244_blob.mp4",
    "createdAt": "2024-08-29T18:26:43.001Z",
    "updatedAt": "2024-08-29T18:26:43.001Z",
    "__v": 0,
    "totalQuestion": 7,
    "answeredQuestion": 4,
    "status": "Not Completed",
    "comment": "test complete but candidate not answer all questions"
  },
  "message": "submitted test fetched"
}

*/

    const apiClient = createApiClient();
    const response = await apiClient.get(
      `/test/submitted?jobApplyId=${payload.jobApplyId}&candidateId=${payload.candidateId}`
    );
    console.log("submitted test response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get submitted test ==> ", error);
    throw error;
  }
};

// mark question correct in correct
export const markQuestionCorrectUnCorrect = (payload) => async (dispatch) => {
  try {
    // payload required
    /*
{
  "testId": "66d0bd625e8a5d7b58acfac4",
  "questionId": "66d0bd625e8a5d7b58acfac5",
  "isCorrect": true
}
    */

    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/test/markQuestionCorrectUnCorrect`,
      payload
    );
    console.log(
      "markQuestionCorrectUnCorrect test response>>>>",
      response.data
    );

    return response.data.data;
  } catch (error) {
    console.log("failed get markQuestionCorrectUnCorrect test ==> ", error);
    throw error;
  }
};
// get Data For Sign Contract
export const getDataForSignContract = (payload) => async (dispatch) => {
  try {
    // example response
    /*
{
  "status": "success",
  "data": {
    "submittedTest": {
      "_id": "66d0bd625e8a5d7b58acfac4",
      "createdAt": "2024-08-29T18:26:43.001Z"
    },
    "jobContract": {
      "title": "this is for contract",
      "docs": "https://grapesoftcouk.s3.amazonaws.com/file_1724867356935_HR interview application.pdf"
    }
  },
  "message": "contract required data fetched"
}
    */

    const apiClient = createApiClient();
    const response = await apiClient.get(
      `/jobApplication/signContractRequirements?jobApplyId=${payload.jobApplyId}`
    );
    console.log("signContractRequirements test response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get signContractRequirements test ==> ", error);
    throw error;
  }
};
//  Sign Contract
export const signContract = (payload) => async (dispatch) => {
  try {
    // example payload
    /*
{
  "jobApplyId": "64e7bfbf57f9f0f7d882e1bc",
  "governmentIdFront": "https://bucket-name.s3.amazonaws.com/path/to/governmentIdFront.jpg",
  "governmentIdBack": "https://bucket-name.s3.amazonaws.com/path/to/governmentIdBack.jpg",
  "proofOfAddress": "https://bucket-name.s3.amazonaws.com/path/to/proofOfAddress.jpg",
  "signature": "https://bucket-name.s3.amazonaws.com/path/to/signature.jpg",
  "agreedToTerms": true
}
    */

    const apiClient = createApiClient();
    const response = await apiClient.post(
      `/jobApplication/signContract`,
      payload
    );
    console.log("signContract test response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get signContract test ==> ", error);
    throw error;
  }
};
// get signed contract
export const getSignedContract = (payload) => async (dispatch) => {
  try {
    // example response
    /*
{
  "status": "success",
  "data": {
    "_id": "66d2f732dccef3287bcbeaf5",
    "jobApplyId": "66d0bb605e8a5d7b58acfa84",
    "governmentIdFront": "https://grapesoftcouk.s3.amazonaws.com/file_1725101844933_man2.jfif",
    "governmentIdBack": "https://grapesoftcouk.s3.amazonaws.com/file_1725101849481_avatar.jpg",
    "proofOfAddress": "https://grapesoftcouk.s3.amazonaws.com/file_1725101849481_avatar.jpg",
    "signature": "https://grapesoftcouk.s3.amazonaws.com/file_1725101871199_signature.png",
    "email":"",
    "first_name":"",
    "last_name":"",
    "agreedToTerms": null,
    "createdAt": "2024-08-31T10:57:54.285Z",
    "updatedAt": "2024-08-31T10:57:54.285Z",
    "__v": 0
  },
  "message": "contract required data fetched"
}
    */

    const apiClient = createApiClient();
    const response = await apiClient.get(
      `/jobApplication/signContract?jobApplyId=${payload.jobApplyId}`
    );
    console.log("signContract response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get signContract ==> ", error);
    throw error;
  }
};
// get notification for employer and candidate both
export const getNotification = (payload) => async (dispatch) => {
  try {
    // example response
    /*
{
  "status": "success",
  "data": {
    "unreadNotifications": 0,
    "allNotifications": [
      {
        "_id": "66d47be966ffac549925c11c",
        "senderId": "66b202ea912827180a73fa01",
        "receiverId": "66d0aa4d5e8a5d7b58acf848",
        "message": "contract approved",
        "description": "contract approved for job dtj xcfh contractApproved",
        "isRead": true,
        "createdAt": "2024-09-01T14:36:25.841Z",
        "updatedAt": "2024-09-01T14:41:44.650Z",
        "__v": 0
      },
      {
        "_id": "66d47abf66ffac549925c0d6",
        "senderId": "66b202ea912827180a73fa01",
        "receiverId": "66d0aa4d5e8a5d7b58acf848",
        "message": "Test Passed",
        "description": "Dear mantiq mantiq soft,Congratulations on passing the test for the position of dtj xcfh",
        "isRead": true,
        "createdAt": "2024-09-01T14:31:27.482Z",
        "updatedAt": "2024-09-01T14:41:44.650Z",
        "__v": 0
      },
      {
        "_id": "66d479477364ac50c2dbe72a",
        "senderId": "66b202ea912827180a73fa01",
        "receiverId": "66d0aa4d5e8a5d7b58acf848",
        "message": "application accepted",
        "description": "your application for dtj xcfh has been accepted",
        "isRead": true,
        "createdAt": "2024-09-01T14:25:11.158Z",
        "updatedAt": "2024-09-01T14:41:44.650Z",
        "__v": 0
      },
      {
        "_id": "66d4783e9e0417f22be91583",
        "senderId": "66b202ea912827180a73fa01",
        "receiverId": "66d0aa4d5e8a5d7b58acf848",
        "message": "Application Rejected",
        "description": "Your application for dtj xcfh at Your Company has been rejected",
        "isRead": true,
        "createdAt": "2024-09-01T14:20:46.346Z",
        "updatedAt": "2024-09-01T14:41:44.650Z",
        "__v": 0
      }
    ]
  },
  "message": "fetched notifications"
}
    */

    const apiClient = createApiClient();
    const response = await apiClient.get(`/notification`);
    console.log("notification response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get notification ==> ", error);
    throw error;
  }
};
// read Notifications all for employer and candidate both
export const readNotification = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.put(`/notification/mark-as-read`);
    console.log("notifications read >>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed read notifications ==> ", error);
    throw error;
  }
};

// Fetch Stripe keys
export const getStripeKeys = async () => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.get("/stripe-key");
    return response.data.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// services/apiService.js
export const verifyPayment = (sessionId) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.post(`/subscription/verify-payment`, { sessionId });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// get subscription history
export const getSubscriptionHistory = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // sample response
    /*
{
  "status": "success",
  "data": {
    "currentPackage": {
      "packageStatus": {
        "numberOfCreditsAdminCustomAdded": 0,
        "numberOfCreditsAdminCustomRemove": 0,
        "title": "576t8",
        "features": [
          "rse"
        ],
        "pricePerCredit": 23,
        "numberOfCredits": 345,
        "type": 1,
        "active": true
      },
      "transactionId": null,
      "title": "576t8",
      "features": [
        "rse"
      ],
      "pricePerCredit": 23,
      "numberOfCredits": 345,
      "type": 1,
      "active": true
    },
    "_id": "66ce46face8e3bdbfae47912",
    "employerId": "66b202ea912827180a73fa01",
    "subscriptionHistory": [
      {
        "packageStatus": {
          "title": "new is",
          "features": [
            "great package"
          ],
          "pricePerCredit": 3,
          "numberOfCredits": 80,
          "numberOfCreditsAdminCustomAdded": 0,
          "numberOfCreditsAdminCustomRemove": 4,
          "type": 1,
          "active": true,
          "transactionId": null
        },
        "transactionId": null,
        "title": "new is",
        "features": [
          "great package"
        ],
        "pricePerCredit": 3,
        "numberOfCredits": 89,
        "type": 1,
        "active": true,
        "_id": "66db34c4f7c41642a5290e3e"
      },
      {
        "packageStatus": {
          "title": "tyu",
          "features": [
            "tfgh"
          ],
          "pricePerCredit": 334,
          "numberOfCredits": 56,
          "numberOfCreditsAdminCustomAdded": 0,
          "numberOfCreditsAdminCustomRemove": 0,
          "type": 1,
          "active": true,
          "transactionId": null
        },
        "transactionId": null,
        "title": "tyu",
        "features": [
          "tfgh"
        ],
        "pricePerCredit": 334,
        "numberOfCredits": 56,
        "type": 1,
        "active": true,
        "_id": "66db34f7965cd8541b4e724b"
      }
    ],
    "createdAt": "2024-08-27T21:36:58.145Z",
    "updatedAt": "2024-09-06T16:59:35.319Z",
    "__v": 2
  },
  "message": "Subscription fetched successfully"
}
    */
    const response = await apiClient.get(
      `/subscription/employer/`,
      payload
    );
    console.log(" getSubscription response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get getSubscription ==> ", error);
    throw error;
  }
};