import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  acceptJobApplication,
  approveContract,
  passApplication,
  rejectedApplication,
} from "../../../services/apiService";
import ButtonLoader from "../../../component/Loaders/ButtonLoader";

const SeeApplicationDetailTimeLine = () => {
  const jobApplication = useSelector(
    (state) => state.jobApplicationSingleReducer_Employer?.jobApplication[0]
  );
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const dispatch = useDispatch();

  const approvedFunction = async () => {
    try {
      setApproveLoading(true);
      if (jobApplication.status === 0) {
        await dispatch(
          acceptJobApplication({
            jobId: jobApplication?.jobId,
            candidateId: jobApplication?.candidateId,
            jobApplicationId: jobApplication?._id,
          })
        );
      }
      if (jobApplication.status === 3) {
        await dispatch(
          passApplication({
            jobId: jobApplication?.jobId,
            candidateId: jobApplication?.candidateId,
            jobApplicationId: jobApplication?._id,
          })
        );
      }
      if (jobApplication.status === 5) {
        await dispatch(
          approveContract({
            jobId: jobApplication?.jobId,
            candidateId: jobApplication?.candidateId,
            jobApplicationId: jobApplication?._id,
          })
        );
      }
      toast.success("Application accepted");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setApproveLoading(false);
    }
  };

  const rejectFunction = async () => {
    try {
      setRejectLoading(true);
      await dispatch(
        rejectedApplication({
          jobId: jobApplication?.jobId,
          candidateId: jobApplication?.candidateId,
          jobApplicationId: jobApplication?._id,
        })
      );
      toast.success("Application rejected");
    } catch (error) {
      toast.error("Failed to reject.");
    } finally {
      setRejectLoading(false);
    }
  };

  if (!jobApplication) {
    return <div>Application not found</div>;
  }

  const timelineItems = [
    { text: "Applied for job", status: 0 },
    { text: "Application Accepted", status: 1 },
    { text: "Test Taken by Candidate", status: 3 },
    { text: "Test Passed by Candidate", status: 4 },
    { text: "Candidate Signed the Contract", status: 5 },
    { text: "Contract Approved", status: 6 },
  ];

  let showRejectionMessage = false;

  return (
    <div className="p-6">
      <ToastContainer />
      <ul className="relative list-none p-0 m-0">
        {timelineItems.map((item, index, array) => {
          const isActive = jobApplication.status === item.status;
          const isCompleted = jobApplication.status > item.status;
          const isPending = jobApplication.status < item.status;
          const isRejectedStep =
            jobApplication.success === 2 && !showRejectionMessage && isPending;
          const showButtons =
            !isRejectedStep &&
            jobApplication.success !== 1 && // Hide buttons if success is 1
            ((jobApplication.status === 0 && isActive) ||
              (jobApplication.status === 3 && item.status === 4) ||
              (jobApplication.status === 5 && item.status === 6)); // Show buttons below "Contract Approved" when status is 5
          const height = showButtons || isRejectedStep ? "h-24" : "h-7"; // Adjust height for buttons or rejection message

          if (isRejectedStep) {
            showRejectionMessage = true; // Ensure the rejection message is shown only once
          }

          return (
            <li
              key={index}
              className="flex flex-row justify-start items-start relative"
            >
              <div className="flex items-center justify-center flex-col">
                {jobApplication.success === 1 || // All steps green when success is 1
                (item.status === 0 && jobApplication.status >= 0) || // Green for "Applied for job" when status is 0 or greater
                (item.status === 1 && jobApplication.status === 1) || // Green only when the status is exactly 1
                (item.status === 3 && jobApplication.status === 3) || // Green for "Test Taken by Candidate" when status is 3
                (item.status === 4 && jobApplication.status === 4) || // Green for "Test Passed by Candidate" when status is 4
                (item.status === 5 && jobApplication.status === 5) ? ( // Green for "Candidate Signed the Contract" when status is 5
                  <>
                    <div className="border-solid border-4 border-greenButton w-4 h-4 rounded-full"></div>
                    {index !== array.length - 1 && (
                      <div className={`w-[2px] ${height} bg-slate-500`}></div>
                    )}
                  </>
                ) : isCompleted ? (
                  <>
                    <div className="border-solid border-4 border-greenButton w-4 h-4 rounded-full"></div>
                    {index !== array.length - 1 && (
                      <div className={`w-[2px] ${height} bg-slate-500`}></div>
                    )}
                  </>
                ) : isActive ? (
                  <>
                    <div
                      className={`border-solid border-4 border-red-500 w-4 h-4 rounded-full`}
                    ></div>
                    {index !== array.length - 1 && (
                      <div className={`w-[2px] ${height} bg-slate-500`}></div>
                    )}
                  </>
                ) : isPending ? (
                  <>
                    <div className="border-solid border-4 border-red-500 w-4 h-4 rounded-full"></div>
                    {index !== array.length - 1 && (
                      <div className={`w-[2px] ${height} bg-slate-500`}></div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="border-solid border-4 border-gray-400 w-4 h-4 rounded-full"></div>
                    {index !== array.length - 1 && (
                      <div className={`w-[2px] ${height} bg-slate-500`}></div>
                    )}
                  </>
                )}
              </div>

              <div className="flex flex-col ml-8">
                <span className="leading-3">{item.text}</span>

                {isRejectedStep && (
                  <div className="text-red-600 mt-2">
                    Application rejected at this step
                  </div>
                )}

                {showButtons && (
                  <div className="flex mt-4">
                    <button
                      className={`bg-redButton text-white py-2 px-4 rounded-md mx-2 flex items-center justify-center ${
                        rejectLoading ? "opacity-70 cursor-not-allowed" : ""
                      }`}
                      onClick={rejectFunction}
                      disabled={approveLoading || rejectLoading}
                    >
                      {rejectLoading ? <ButtonLoader /> : "Reject"}
                    </button>

                    <button
                      className={`bg-greenButton text-white py-2 px-4 rounded-md mx-2 flex items-center justify-center ${
                        approveLoading ? "opacity-70 cursor-not-allowed" : ""
                      }`}
                      onClick={approvedFunction}
                      disabled={approveLoading || rejectLoading}
                    >
                      {approveLoading ? <ButtonLoader /> : "Approve"}
                    </button>
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SeeApplicationDetailTimeLine;
