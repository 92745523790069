import React, { useRef, useState, useEffect } from "react";
import SetPermissionsDesign from "../../../images/SetPermissionDesigns/SetPermissionsDesign.png";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaStream } from "./MediaStreamContext"; // Adjust the import path

const CandidateSetTestPermissions = () => {
  const navigate = useNavigate();
  const { jobApplyId } = useParams(); // Get jobApplyId from URL params

  const [isVideoReady, setIsVideoReady] = useState(false);
  const [permissionsGranted, setPermissionsGranted] = useState(false);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false); // State to detect mobile device
  const cameraStreamRef = useRef(null);

  const {
    setCameraStream,
    setScreenStream,
    setMediaRecorder,
    setRecordedChunks,
    cameraStream,
    screenStream,
  } = useMediaStream();

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate(1); // Prevents navigating back
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  localStorage.setItem("renderCount", 0);

  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    if (cameraStream && screenStream) {
      setIsVideoReady(true);
      setPermissionsGranted(true);
    }
  }, [cameraStream, screenStream]);

  const checkPermissions = async () => {
    try {
      const cameraPermissions = await navigator.permissions.query({
        name: "camera",
      });
      const microphonePermissions = await navigator.permissions.query({
        name: "microphone",
      });

      if (
        cameraPermissions.state === "denied" ||
        microphonePermissions.state === "denied"
      ) {
        setError(
          "Camera and/or microphone access is denied. Please enable permissions in your browser settings."
        );
        return false;
      }

      return true;
    } catch (err) {
      console.error("Error checking permissions: ", err.message);
      return false;
    }
  };

  const startCamera = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError(
        "Media devices are not supported in this browser or the site is not served over HTTPS."
      );
      return;
    }

    const permissionsGranted = await checkPermissions();
    if (!permissionsGranted) return;

    try {
      const cameraStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      let screenStream = null;

      if (navigator.mediaDevices.getDisplayMedia) {
        screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            mediaSource: "screen",
            displaySurface: "monitor",
          },
          audio: true,
        });
        const videoTrack = screenStream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();
        if (settings.displaySurface !== "monitor") {
          screenStream.getTracks().forEach((track) => track.stop());
          alert("Please share your entire screen.");
          return startCamera();
        }
      }

      const mixedStream = new MediaStream([
        ...cameraStream.getVideoTracks(),
        ...(screenStream ? screenStream.getVideoTracks() : []),
        ...cameraStream.getAudioTracks(),
      ]);

      cameraStreamRef.current = mixedStream;
      setCameraStream(cameraStream);
      setScreenStream(screenStream);
      setIsVideoReady(true);
      setPermissionsGranted(true);

      const mediaRecorder = new MediaRecorder(mixedStream, {
        mimeType: "video/webm;codecs=vp9,opus",
      });
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]);
        }
      };
      mediaRecorder.start();
      setMediaRecorder(mediaRecorder);
    } catch (err) {
      console.error("Error accessing camera or screen: ", err.message);
      alert(`Error accessing camera or screen: ${err.message}`);
    }
  };

  const proceedToTest = () => {
    navigate("/attemptTest", {
      replace: true,
      state: {
        jobApplyId,
      },
    });

    window.history.pushState(null, null, window.location.href);
    window.history.replaceState(null, null, window.location.href);

    window.onpopstate = function () {
      window.history.go(1);
    };
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full text-center">
        {isMobile ? (
          <div className="bg-red-500 text-white p-4 rounded mb-6">
            <h1 className="text-xl font-bold">
              Screen sharing is blocked on mobile devices!
            </h1>
            <p className="text-md mt-2">
              Please use a desktop or laptop with screen sharing capabilities to
              proceed with this test.
            </p>
          </div>
        ) : (
          <>
            <h1 className="text-red-500 text-sm font-bold mb-4">
              Test 20 July 24
            </h1>
            <h2 className="text-xl font-bold mb-6">
              You are now starting a timed test
            </h2>
            <p className="text-gray-600 mb-6 text-[10px] sm:text-lg">
              Ensure your camera is on and you accept permissions from the
              browser to use camera/mic/screen record, etc.
            </p>
            <div className="flex justify-center mb-6">
              {isVideoReady ? (
                <video
                  ref={(el) => {
                    if (el && cameraStreamRef.current) {
                      el.srcObject = cameraStreamRef.current;
                      el.play();
                    }
                  }}
                  style={{ width: "400px", height: "300px" }}
                  autoPlay
                />
              ) : (
                <img src={SetPermissionsDesign} alt="Timed Test" />
              )}
            </div>
            {error ? (
              <div className="text-red-500 mb-4">{error}</div>
            ) : permissionsGranted ? (
              <button
                onClick={proceedToTest}
                className="bg-purpleButton text-white font-bold py-2 px-4 rounded hover:bg-blue-700 mb-4"
              >
                Proceed
              </button>
            ) : (
              <button
                onClick={startCamera}
                className="bg-purpleButton text-white font-bold py-2 px-4 rounded hover:bg-blue-700 mb-4"
              >
                Start Camera
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CandidateSetTestPermissions;
