import React from "react";

const SimpleTextGreeting = () => {
  const getGreetingMessage = () => {
    const currentHour = new Date().getHours(); // Get the current hour (0-23)
    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return <p className="md:ml-2 text-slate-500">{getGreetingMessage()}</p>;
};

export default SimpleTextGreeting;
