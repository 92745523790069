import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  sendVerifyEmailOTP,
  verifyAccountByOTP,
  completeProfileWithPasswordCandidate,
} from "../../services/apiService";
import { toast, ToastContainer } from "react-toastify";
import OTPInput from "../../component/auth/OTPbox"; // Adjust the import path based on your file structure
import ButtonLoader from "../../component/Loaders/ButtonLoader"; // Adjust the import path based on your file structure

const CompleteProfileByPassword = () => {
  const [step, setStep] = useState(1); // Step 1: Send OTP, Step 2: Verify OTP, Step 3: Set Password
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for button
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email: encodedEmail } = useParams(); // Get the encoded email from URL if needed
  const payload = encodedEmail
    ? JSON.parse(decodeURIComponent(encodedEmail))
    : null;
  const candidateEmail = payload?.candidateEmail || email;

  const handleSendOTP = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
    try {
      await dispatch(sendVerifyEmailOTP({ email: candidateEmail }));
      toast.success("OTP sent to your email");
      setStep(2);
    } catch (error) {
      toast.error("Failed to send OTP");
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
    try {
      await dispatch(verifyAccountByOTP({ otp, email: candidateEmail }));
      toast.success("OTP verified successfully");
      setStep(3);
    } catch (error) {
      toast.error("Invalid OTP");
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleSetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    setLoading(true); // Set loading to true
    try {
      await dispatch(
        completeProfileWithPasswordCandidate({
          email: candidateEmail,
          password: password,
        })
      );
      toast.success(
        "Profile completed successfully. Redirecting to dashboard..."
      );
      navigate(`/candidateDashboard/setTestPermissions/${payload.jobApplyId}`);
    } catch (error) {
      toast.error("Failed to set password");
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <ToastContainer />
      {step === 1 && (
        <form
          onSubmit={handleSendOTP}
          className="bg-white p-6 rounded-lg shadow-md w-full max-w-sm"
        >
          <h2 className="text-2xl font-bold mb-5 text-center">Verify Email</h2>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={candidateEmail}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={!!encodedEmail} // Disable input if email is provided in the URL
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-center items-center"
            disabled={loading} // Disable button during loading
          >
            {loading ? <ButtonLoader /> : "Send OTP"}
          </button>
        </form>
      )}

      {step === 2 && (
        <form
          onSubmit={handleVerifyOTP}
          className="bg-white p-6 rounded-lg shadow-md w-full max-w-sm"
        >
          <h2 className="text-2xl font-bold mb-5 text-center">Enter OTP</h2>
          <OTPInput length={5} onChangeOTP={setOtp} />
          <button
            type="submit"
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-center items-center"
            disabled={loading} // Disable button during loading
          >
            {loading ? <ButtonLoader /> : "Verify OTP"}
          </button>
        </form>
      )}

      {step === 3 && (
        <form
          onSubmit={handleSetPassword}
          className="bg-white p-6 rounded-lg shadow-md w-full max-w-sm"
        >
          <h2 className="text-2xl font-bold mb-5 text-center">Set Password</h2>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              id="password"
              type="password"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="confirmPassword"
            >
              Confirm Password
            </label>
            <input
              id="confirmPassword"
              type="password"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-center items-center"
            disabled={loading} // Disable button during loading
          >
            {loading ? <ButtonLoader /> : "Submit"}
          </button>
        </form>
      )}
    </div>
  );
};

export default CompleteProfileByPassword;
