import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import profile from "../../images/employer candidate/profile Button.png";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import { getCandidateJobApplications } from "../../services/apiService";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CandidateApplications = () => {
  const query = useQuery();
  const status = query.get("status") ? Number(query.get("status")) : undefined;
  const dispatch = useDispatch();
  const {
    jobApplications,

    // paginationInfo
  } = useSelector((state) => state.jobApplicationReducer_candidate);

  const [candidatesPerPage, setCandidatesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRow, setExpandedRow] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      setLoading(true);
      try {
        await dispatch(
          getCandidateJobApplications({
            status,
            page: currentPage,
            limit: candidatesPerPage,
          })
        );
        toast.success("Job applications fetched successfully");
      } catch (error) {
        toast.error("Failed to fetch job applications");
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [dispatch, status, currentPage, candidatesPerPage]);

  const filteredCandidates =
    status !== undefined
      ? jobApplications.filter((candidate) => candidate.status === status)
      : jobApplications;

  const indexOfLastCandidate = currentPage * candidatesPerPage;
  const indexOfFirstCandidate = indexOfLastCandidate - candidatesPerPage;
  const currentCandidates = filteredCandidates.slice(
    indexOfFirstCandidate,
    indexOfLastCandidate
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCandidatesPerPageChange = (event) => {
    setCandidatesPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page whenever candidates per page changes
  };

  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "bg-[#fcd9e2] text-[#f1416c]";
      case 1:
        return "bg-green-200 text-green-600";
      case 2:
        return "bg-[#ccf4f1] text-[#00c9b7]";
      case 3:
        return "bg-[#fff4d0] text-[#ffc715]";
      case 4:
        return "bg-[#ccf4f1] text-[#00c9b7]";
      case 5:
        return "bg-blue-200 text-blue-600";
      case 6:
        return "bg-green-200 text-green-600";
      default:
        return "bg-[#ccf4f1] text-[#00c9b7]";
    }
  };

  const renderRow = (candidate, index) => (
    <React.Fragment key={index}>
      <tr className="border-t">
        <td className="font-bold">
          <div className="flex items-center">
            {expandedRow === index ? (
              <div className="flex items-center">
                <div
                  className="cursor-pointer p-1 rounded-full bg-red-500 text-white"
                  onClick={() => handleRowExpand(index)}
                >
                  <FiMinus />
                </div>
              </div>
            ) : (
              <div className="flex items-center">
                <div
                  className="cursor-pointer p-1 rounded-full bg-purpleButton text-white"
                  onClick={() => handleRowExpand(index)}
                >
                  <GoPlus />
                </div>
              </div>
            )}
            <div className="ml-2">
              {new Date(candidate.updatedAt).toLocaleString()}
            </div>
          </div>
        </td>
        <td className="p-2 flex space-x-2">
          <Link
            to={`/candidateDashboard/application/${candidate._id}`}
            className="bg-gray-200 rounded"
          >
            <img src={profile} alt="profile" />
          </Link>
        </td>
      </tr>
      {expandedRow === index && (
        <tr>
          <td colSpan={1} className="p-2">
            <div className="flex flex-col space-y-2">
              <div>
                <div className="font-bold">JOB:</div>
                <div>{candidate.jobTitle}</div>
                <div className="text-gray-500">{candidate._id}</div>
              </div>
              <div className="flex flex-col text-left gap-1">
                <div className="font-bold">STATUS:</div>
                <div>
                  <span
                    className={`px-4 py-1 rounded-2xl items-center ${getStatusClass(
                      candidate.success === 1 ? 6 : candidate.status
                    )}`}
                  >
                    {candidate.success === 2 ? (
                      "rejected"
                    ) : candidate.success === 1 ? (
                      "Hired"
                    ) : (
                      <>
                        {candidate.status === 0 && "pending"}
                        {candidate.status === 1 && "accepted"}
                        {candidate.status === 3 && "test taken"}
                        {candidate.status === 4 && "passed"}
                        {candidate.status === 5 && "contract signed"}
                      </>
                    )}
                  </span>
                </div>
                <div className="my-2 w-full">
                  {candidate.status === 1 && (
                    <Link
                      to={`/candidateDashboard/training/${candidate.jobId}_${candidate._id}`}
                      className="bg-green-600 py-1 px-4 sm:px-4 text-white text-[7px]"
                    >
                      Attempt Test
                    </Link>
                  )}
                  {candidate.status === 2 && (
                    <Link
                      to={`/signContract/${candidate._id}`}
                      className="bg-[#ffc715] py-1 px-1 sm:px-4 text-white text-[7px]"
                    >
                      Sign Contract
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );

  return (
    <div className="rounded-lg p-4 bg-white shadow">
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <ButtonLoader />
      ) : (
        <>
          {/* Desktop and Tablet */}
          <div className="hidden sm:flex flex-col">
            <table className="min-w-full text-xs lg:text-sm">
              <thead>
                <tr className="text-left text-gray-400">
                  <th className="p-2">APPLIED DATE</th>
                  <th className="p-2">JOB</th>
                  <th className="p-2">STATUS</th>
                  <th className="p-2">ACTION</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {currentCandidates.map((candidate, index) => (
                  <tr key={index} className="border-t">
                    <td className="p-2 font-bold">
                      {new Date(candidate.updatedAt).toLocaleString()}
                    </td>
                    <td className="p-2">
                      <div className="font-bold">{candidate.jobTitle}</div>
                      <div className="text-gray-500">{candidate._id}</div>
                    </td>
                    <td className="p-2">
                      <span
                        className={`px-4 py-1 rounded-2xl ${getStatusClass(
                          candidate.success === 1 ? 6 : candidate.status
                        )}`}
                      >
                        {candidate.success === 2 ? (
                          "rejected"
                        ) : candidate.success === 1 ? (
                          "Hired"
                        ) : (
                          <>
                            {candidate.status === 0 && "pending"}
                            {candidate.status === 1 && "accepted"}
                            {candidate.status === 3 && "test taken"}
                            {candidate.status === 4 && "passed"}
                            {candidate.status === 5 && "contract signed"}
                          </>
                        )}
                      </span>
                    </td>
                    <td className="p-2 flex space-x-2">
                      {candidate.status === 1 && (
                        <Link
                          to={`/candidateDashboard/training/${candidate.jobId}_${candidate._id}`}
                          className="bg-green-600 py-1 px-1 sm:px-4 text-white "
                        >
                          Attempt Test
                        </Link>
                      )}
                      {candidate.status === 4 && (
                        <Link
                          to={`/signContract/${candidate._id}`}
                          className="bg-[#ffc715] py-1 px-1 sm:px-[14px] text-white "
                        >
                          Sign Contract
                        </Link>
                      )}
                      <Link
                        to={`/candidateDashboard/application/${candidate._id}`}
                        className="bg-gray-200 rounded"
                      >
                        <img src={profile} alt="profile" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Mobile */}
          <div className="flex sm:hidden flex-col">
            <table className="min-w-full text-xs lg:text-sm">
              <thead>
                <tr className="text-left text-gray-400">
                  <th className="p-2">APPLIED DATE</th>
                  <th className="p-2">ACTION</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {currentCandidates.map((candidate, index) =>
                  renderRow(candidate, index)
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-center sm:justify-between items-center mt-4 text-sm">
            <div className=" hidden sm:flex items-center space-x-2">
              <select
                className="p-2 border rounded bg-gray-100"
                onChange={handleCandidatesPerPageChange}
                value={candidatesPerPage}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>
                Showing {indexOfFirstCandidate + 1} to {indexOfLastCandidate} of{" "}
                {filteredCandidates.length} records
              </span>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                className={`px-2 py-1 rounded ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
              </button>
              <button
                key={currentPage}
                onClick={() => paginate(currentPage)}
                className="px-3 py-1 rounded bg-purpleButton text-white"
              >
                {currentPage}
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                className={`px-2 py-1 rounded ${
                  currentPage ===
                  Math.ceil(filteredCandidates.length / candidatesPerPage)
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={
                  currentPage ===
                  Math.ceil(filteredCandidates.length / candidatesPerPage)
                }
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CandidateApplications;
