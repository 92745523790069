import { createBrowserRouter, useNavigate } from "react-router-dom";
// pages
// auth
import Login from "../Pages/auth/Login";
import { Link } from "react-router-dom";
import Signup from "../Pages/auth/SignUp";
// employer dashboard
import MainPage from "../Pages/employerDashboard/EmployerMain";
import EmployerIndex from "../Pages/employerDashboard/EmployerIndex";
import EmployerProfile from "../Pages/employerDashboard/EmployerProfile";
import EmployerJobs from "../Pages/employerDashboard/EmployerJobs";
import EmployerJob from "../Pages/employerDashboard/EmployerJob";
import EmployerCandidates from "../Pages/employerDashboard/EmployerCandidates";
import JobApply from "../Pages/JobApply/JobApply";
import SendApplicationFlow from "../Pages/JobApply/JobApplyComponents/SendApplicationFlow";
import BillingHistoryTable from "../Pages/employerDashboard/BillHistoryTable";
import PurchaseCredits from "../Pages/employerDashboard/EmployerPurchaseCredits";
import HelpGuideEmployer from "../Pages/employerDashboard/EmployerHelpAndGuide";
// candidate dashboard
import CandidateIndex from "../Pages/candidateDashboard/CandidateIndex";
import CandidateMain from "../Pages/candidateDashboard/CandidateMain";
import CandidateApplications from "../Pages/candidateDashboard/CandidateApplications";
import CandidateProfile from "../Pages/candidateDashboard/CandidateProfile";
import CandidateJobs from "../Pages/candidateDashboard/CandidateJobs";
import CandidateAttemptTest from "../Pages/candidateDashboard/TestProcess/CandidateAttemptTest";
import CandidateSignContract from "../Pages/candidateDashboard/CandidateSignContract";
import CandidateSetTestPermissions from "../Pages/candidateDashboard/TestProcess/CandidateSetPermissionsPage";
import CandidateHelpGuide from "../Pages/candidateDashboard/CandidateHelpAndGuide";
// application detail employer
import SeeApplicationDetail from "../Pages/SeeApplicationDetails/SeeApplicationDetail";
import SeeApplicationDetailPersonalInfo from "../Pages/SeeApplicationDetails/components/SeeApplicationDetailPersonalInfo";
import SeeApplicationDetailTestResult from "../Pages/SeeApplicationDetails/components/SeeApplicationDetailTestResult";
import SeeApplicationDetailContract from "../Pages/SeeApplicationDetails/components/SeeApplicationDetailContract";
import SeeApplicationDetailRecordedVideo from "../Pages/SeeApplicationDetails/components/seeApplicationDetailRecordedVideo";
import TestBuilder from "../Pages/employerDashboard/testBuilderSection/TestBuilder";
// application detail candidate
import SeeApplicationDetailCandidate from "../Pages/SeeApplicationDetailsCandidate/SeeApplicationDetail";
import SeeApplicationDetailTestResultCandidate from "../Pages/SeeApplicationDetailsCandidate/components/SeeApplicationDetailTestResult";
import SeeApplicationDetailContractCandidate from "../Pages/SeeApplicationDetailsCandidate/components/SeeApplicationDetailContract";
import SeeApplicationDetailPersonalInfoCandidate from "../Pages/SeeApplicationDetailsCandidate/components/SeeApplicationDetailPersonalInfo";
// media stream
import { MediaStreamProvider } from "../Pages/candidateDashboard/TestProcess/MediaStreamContext";
import TestBuilderCreator from "../Pages/employerDashboard/testBuilderSection/TestBuilderCreator";
import { useEffect } from "react";
import CompleteProfileByPassword from "../Pages/candidateDashboard/CompleteProfileByPassword";
import LoginWithPasswordOnly from "../Pages/candidateDashboard/LoginWithPasswordOnly";
import Training from "../Pages/candidateDashboard/TestProcess/Training";
import ThankYouPage from "../Pages/thankyou/ThankYouPage";
import SubscriptionSuccess from "../Pages/employerDashboard/SubscriptionSuccess";
import SubscriptionCancel from "../Pages/employerDashboard/SubscriptionCancel";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, [navigate]);

  return null; // Optionally, you can return a loading spinner or another element while redirecting
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <>error</>,
    children: [],
  },
  {
    path: "/landingpage",
    element: (
      <>
        <Link to="/login" className="bg-blue-500 text-white p-4 rounded m-4">
          Sign In
        </Link>
      </>
    ),
    errorElement: <>error</>,
    children: [],
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <>error</>,
    children: [],
  },
  {
    path: "/signup",
    element: <Signup />,
    errorElement: <>error</>,
    children: [],
  },
  {
    path: "/employerDashboard",
    element: <EmployerIndex />,
    errorElement: <>error</>,
    children: [
      {
        path: "/employerDashboard/main",
        element: <MainPage />,
        errorElement: <>error</>,
      },
      {
        path: "/employerDashboard/jobs",
        element: <EmployerJobs />,
        errorElement: <>error</>,
      },
      {
        path: "/employerDashboard/job",
        element: <EmployerJob />,
        errorElement: <>error</>,
      },
      {
        path: "/employerDashboard/candidates",
        element: <EmployerCandidates />,
        errorElement: <>error</>,
      },
      {
        path: "/employerDashboard/helpAndGuide",
        element: <HelpGuideEmployer />,
        errorElement: <>error</>,
      },
      {
        path: "/employerDashboard/profile",
        element: <EmployerProfile />,
        errorElement: <>error</>,
      },
      {
        path: "/employerDashboard/BillingHistoryTable",
        element: <BillingHistoryTable />,
        errorElement: <>error</>,
      },
      {
        path: "/employerDashboard/TestBuilder",
        element: <TestBuilder />,
        errorElement: <>error</>,
      },
      {
        path: "/employerDashboard/TestBuilderCreator",
        element: <TestBuilderCreator />,
        errorElement: <>error</>,
      },
      {
        path: "/employerDashboard/application/:jobApplicationId",
        element: <SeeApplicationDetail />,
        errorElement: <>error</>,
        children: [
          {
            path: "/employerDashboard/application/:jobApplicationId",
            element: <SeeApplicationDetailPersonalInfo />,
            errorElement: <>error</>,
          },
          {
            path: "/employerDashboard/application/:jobApplicationId/testResult",
            element: <SeeApplicationDetailTestResult />,
            errorElement: <>error</>,
          },
          {
            path: "/employerDashboard/application/:jobApplicationId/contract",
            element: <SeeApplicationDetailContract />,
            errorElement: <>error</>,
          },
          {
            path: "/employerDashboard/application/:jobApplicationId/recordedVideo",
            element: <SeeApplicationDetailRecordedVideo />,
            errorElement: <>error</>,
          },
        ],
      },
    ],
  },
  {
    path: "/PurchaseCredits",
    element: <PurchaseCredits />,
    errorElement: <>error</>,
  },
  {
    path: "/candidateDashboard",
    element: (
      <MediaStreamProvider>
        <CandidateIndex />
      </MediaStreamProvider>
    ),
    errorElement: <>error</>,
    children: [
      {
        path: "/candidateDashboard/main",
        element: <CandidateMain />,
        errorElement: <>error</>,
      },
      {
        path: "/candidateDashboard/applications",
        element: <CandidateApplications />,
        errorElement: <>error</>,
      },
      {
        path: "/candidateDashboard/profile",
        element: <CandidateProfile />,
        errorElement: <>error</>,
      },
      {
        path: "/candidateDashboard/jobs",
        element: <CandidateJobs />,
        errorElement: <>error</>,
      },
      {
        path: "/candidateDashboard/helpAndGuide",
        element: <CandidateHelpGuide />,
        errorElement: <>error</>,
      },
      {
        path: "/candidateDashboard/training/:id",
        element: <Training />,
        errorElement: <>error</>,
      },
      {
        path: "/candidateDashboard/setTestPermissions/:jobApplyId",
        element: <CandidateSetTestPermissions />,
        errorElement: <>error</>,
      },
      {
        path: "/candidateDashboard/application/:jobApplicationId",
        element: <SeeApplicationDetailCandidate />,
        errorElement: <>error</>,
        children: [
          {
            path: "/candidateDashboard/application/:jobApplicationId",
            element: <SeeApplicationDetailPersonalInfoCandidate />,
            errorElement: <>error</>,
          },
          {
            path: "/candidateDashboard/application/:jobApplicationId/testResult",
            element: <SeeApplicationDetailTestResultCandidate />,
            errorElement: <>error</>,
          },
          {
            path: "/candidateDashboard/application/:jobApplicationId/contract",
            element: <SeeApplicationDetailContractCandidate />,
            errorElement: <>error</>,
          },
        ],
      },
    ],
  },
  {
    path: "/signContract/:jobApplicationId",
    element: <CandidateSignContract />,
    errorElement: <>error</>,
  },
  {
    path: "/attemptTest",
    element: (
      <MediaStreamProvider>
        <CandidateAttemptTest />
      </MediaStreamProvider>
    ),
    errorElement: <>error</>,
  },
  {
    path: "/jobApply/:id",
    element: <JobApply />,
    errorElement: <>error</>,
    children: [],
  },
  {
    path: "/sendApplicationFlow/:id",
    element: <SendApplicationFlow />,
    errorElement: <>error</>,
    children: [],
  },
  {
    path: "/completeProfileCandidate/:email",
    element: <CompleteProfileByPassword />,
    errorElement: <>error</>,
  },
  {
    path: "/loginWithPasswordCandidate/:email",
    element: <CompleteProfileByPassword />,
    errorElement: <>error</>,
  },
  {
    path: "/LoginWithPasswordOnly/:email",
    element: <LoginWithPasswordOnly />,
    errorElement: <>error</>,
  },
  {
    path: "/thank-you",
    element: <ThankYouPage />,
    errorElement: <>error</>,
  },
  {
    path: "/subscriptionSuccess",
    element: <SubscriptionSuccess />,
    errorElement: <>error</>,
  },
  {
    path: "/subscriptionCancel",
    element: <SubscriptionCancel />,
    errorElement: <>error</>,
  },
  {
    path: "*",
    element: <>error</>,
  },
]);

export default router;
