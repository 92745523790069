import React from "react";
import { useSelector } from "react-redux";
import ButtonLoader from "../../../component/Loaders/ButtonLoader";

function SeeApplicationDetailPersonalInfo() {
  // Access the fetched job application data from Redux store
  const jobApplication = useSelector(
    (state) => state.jobApplicationSingleReducer_Employer?.jobApplication[0]
  );
  if (!jobApplication) {
    return (
      <>
        <ButtonLoader />
      </>
    );
  }
  return (
    <div className="mt-4 flex flex-col gap-2  text-[10px] sm:text-lg">
      <p className="flex justify-between">
        <p>First Name:</p>
        <p>{jobApplication?.first_name}</p>
      </p>
      <p className="flex justify-between">
        <p>Last Name:</p>
        <p>{jobApplication?.last_name}</p>
      </p>
      <p className="flex justify-between">
        <p>Email:</p>
        <p>{jobApplication?.email}</p>
      </p>
      <p className="flex justify-between">
        <p>Residence Country:</p>
        <p>{jobApplication?.countryOfRecidence}</p>
      </p>
      <p className="flex justify-between">
        <p>Birth Country:</p>
        <p>{jobApplication?.countryOfBirth}</p>
      </p>
      <p className="flex justify-between">
        <p>Contact Number:</p>
        <p>{jobApplication?.contactNumber}</p>
      </p>
      <p className="flex justify-between">
        <p>Availability Date:</p>
        <p>
          {jobApplication?.availabilityDate && (
            <>
              {
                new Date(jobApplication?.availabilityDate)
                  .toLocaleString()
                  .split(",")[0]
              }
            </>
          )}
        </p>
      </p>
      {jobApplication.cv && (
        <>
          <p className="flex justify-between">
            CV:
            <a
              href={jobApplication.cv}
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Here
            </a>
          </p>
        </>
      )}
      {jobApplication.aboutVideo && (
        <>
          <p className="flex justify-between">
            ABOUT VIDEO:
            <a
              href={jobApplication.aboutVideo}
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Here
            </a>
          </p>
        </>
      )}
      {jobApplication.coverLetter && (
        <>
          <p className="flex justify-between">
            COVER LETTER:
            <div>{jobApplication.coverLetter}</div>
          </p>
        </>
      )}
    </div>
  );
}

export default SeeApplicationDetailPersonalInfo;
