const initialState = {
  jobApplications: [],
  pagination: {
    totalPages: 0,
    currentPage: 1,
    totalDocuments: 0,
    pageSize: 10, // Ensure this is set here
  },
};

const jobApplicationReducer_candidate = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_JOB_APPLICATIONs_CANDIDATE": {
      if (state.jobApplications.length > 0) {
        return {
          ...state,
          jobApplications: [action.payload, ...state.jobApplications],
          pagination: {
            ...state.pagination,
            totalDocuments: state.pagination.totalDocuments + 1,
            totalPages: Math.ceil(
              (state.pagination.totalDocuments + 1) / state.pagination.pageSize
            ),
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "ADD_LIST_JOBS_APPLICATIONS_CANDIDATE":
      return {
        ...state,
        jobApplications: action.payload.jobApplications,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    case "EDIT_JOB_APPLICATION_CANDIDATE":
      return {
        ...state,
        jobApplications: state.jobApplications.map((jobApplication) =>
          jobApplication._id === action.payload._id
            ? { ...jobApplication, ...action.payload }
            : jobApplication
        ),
      };
    case "DELETE_JOB_APPLICATION_CANDIDATE":
      return {
        ...state,
        jobApplications: state.jobApplications.filter(
          (jobApplication) => jobApplication._id !== action.payload
        ),
        pagination: {
          ...state.pagination,
          totalDocuments: state.pagination.totalDocuments - 1,
          totalPages: Math.ceil(
            (state.pagination.totalDocuments - 1) / state.pagination.pageSize
          ),
          currentPage:
            Math.min(
              state.pagination.currentPage,
              Math.ceil(
                (state.pagination.totalDocuments - 1) /
                  state.pagination.pageSize
              )
            ) === 0
              ? 1
              : Math.min(
                  state.pagination.currentPage,
                  Math.ceil(
                    (state.pagination.totalDocuments - 1) /
                      state.pagination.pageSize
                  )
                ),
        },
      };
    case "GET_JOB_APPLICATIONS_CANDIDATE":
      return {
        ...state,
        jobApplications: action.payload.jobApplications,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    case "EDIT_PAGINATION_JOB_APPLICATIONS_CANDIDATE":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    default:
      return state;
  }
};

export default jobApplicationReducer_candidate;
