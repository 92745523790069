import React from "react";

const SeeApplicationDetailRecordedVideo = () => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr className="w-full bg-gray-100">
            <th className="py-2 px-4 text-left text-gray-700">RECORDED TIME</th>
            <th className="py-2 px-4 text-left text-gray-700">ACTION</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-t">
            <td className="py-2 px-4 text-gray-700">
              2022-08-09T19:23:11.000000Z
            </td>
            <td className="py-2 px-4">
              <a href="#2ii" className="text-blue-500 hover:underline">
                Watch Video
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SeeApplicationDetailRecordedVideo;
