import React, { useState } from "react";

const OTPInput = ({ length, onChangeOTP }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus on the next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }

    onChangeOTP(newOtp.join(""));
  };

  const handleKeyUp = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  return (
    <div>
      {otp.map((data, index) => {
        return (
          <input
            className="otp-field bg-slate-300 text-white w-[2rem] h-[2rem] md:w-[2.8rem] md:h-[2.8rem]"
            type="text"
            name="otp"
            maxLength="1"
            key={index}
            value={data}
            onChange={(e) => handleChange(e.target, index)}
            onKeyUp={(e) => handleKeyUp(e, index)}
            onFocus={(e) => e.target.select()}
            style={{
              margin: "0.5rem",
              textAlign: "center",
              fontSize: "1.5rem",
              border: "1px solid #ccc",
              borderRadius: "0.25rem",
            }}
          />
        );
      })}
    </div>
  );
};

export default OTPInput;
