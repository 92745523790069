import React from "react";
import rightArrow from "../../images/auth/rightArrow.png";
import loginBackground from "../../images/auth/loginBackground.jpg";
import { Link } from "react-router-dom";
function BackgroundAuth() {
  return (
    <>
      <div className="relative w-full sm:w-[45%] sm:flex flex-col justify-center items-center">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${loginBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: -1,
          }}
        ></div>
        <div className="bg-[#6244fe] bg-opacity-75 w-full h-full flex flex-col justify-center items-center">
          <div>
            <span className="text-white text-[60px]">Welcome to</span>
            <br />
            <span className="text-white text-[60px] font-bold">RedDot</span>
            <div className="text-white">Login to your Account</div>
            <Link
              to="/"
              className="bg-[#00c9b7] w-[200px] p-2 rounded-full text-white text-center my-10 flex justify-around"
            >
              <img src={rightArrow} alt="right arrow" className="inline w-4" />
              <div to="/">Back to Home Page</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default BackgroundAuth;
