import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SeeApplicationDetailHeader from "./components/SeeApplicationDetailHeader";
import { EmployerGetSpecificJobApplication } from "../../services/apiService";
import { Outlet } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ButtonLoader from "../../component/Loaders/ButtonLoader";

function SeeApplicationDetail() {
  const dispatch = useDispatch();
  const { jobApplicationId } = useParams(); // Use useParams to get jobApplicationId from URL

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobApplication = async () => {
      try {
        setLoading(true);
        await dispatch(EmployerGetSpecificJobApplication({ jobApplicationId }));
        setLoading(false);
        // handle the response if needed
        toast.success("Job application details fetched successfully.");
      } catch (error) {
        toast.error("Failed to fetch job application details.");
        setLoading(false);
      }
    };

    fetchJobApplication();
  }, [dispatch, jobApplicationId]);

  if (loading) {
    return (
      <div className="mt-[25%]">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <div className="min-h-screen sm:p-4">
      <ToastContainer />
      <SeeApplicationDetailHeader />
      <div className="bg-white shadow rounded-lg p-2 sm:p-6 mt-6">
        <Outlet />
      </div>
    </div>
  );
}

export default SeeApplicationDetail;
