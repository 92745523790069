import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTestBuilders,
  deleteTestBuilder,
} from "../../../services/apiService";
import { toast, ToastContainer } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import editIcon from "../../../images/jobsEmployer/edit.png";
import deleteIcon from "../../../images/jobsEmployer/delete.png";
import ButtonLoader from "../../../component/Loaders/ButtonLoader";
import ConfirmationModal from "../../../component/confirm/ConfirmationModel";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const paginateArray = (array, pageSize, currentPage) => {
  const startIndex = (currentPage - 1) * pageSize;
  return array.slice(startIndex, startIndex + pageSize);
};

const TestBuilder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { testBuilders, pagination } = useSelector(
    (state) => state.testBuilder_Employer
  );

  const [perPage, setPerPage] = useState(pagination?.limit || 10);
  const [currentPage, setCurrentPage] = useState(pagination?.currentPage || 1);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState({});
  const [displayedBuilders, setDisplayedBuilders] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [testBuilderToDelete, setTestBuilderToDelete] = useState(null); // State for the test builder to delete
  const hasFetchedData = useRef(false);
  const fetchCount = useRef(0);

  const fetchData = useCallback(async () => {
    try {
      const totalJobs = pagination?.totalJobs || 0;

      if (runOnce.current) {
        const isCurrentPageDataLoaded =
          testBuilders.length > 0 &&
          testBuilders.length >=
            Math.min(pagination.limit * currentPage, totalJobs);

        if (!isCurrentPageDataLoaded) {
          setLoading(true);
          await dispatch(
            getTestBuilders({ page: currentPage, limit: perPage })
          );
          setLoading(false);
          toast.success(`Test builder fetched successfully`);
        }
      }
      if (testBuilders.length === 0) {
        runOnce.current = false;
      } else {
        runOnce.current = true;
      }
    } catch (error) {
      // toast.error(`Error fetching test builder`);
    }
  }, [pagination, testBuilders, currentPage, perPage, dispatch]);

  const runOnce = useRef(true);

  useEffect(() => {
    setDisplayedBuilders(paginateArray(testBuilders, perPage, currentPage));
  }, [testBuilders, currentPage, perPage]);

  useEffect(() => {
    if (hasFetchedData.current) return;
    hasFetchedData.current = true;
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchCount.current += 1;
    if (fetchCount.current > 2) {
      fetchData();
    }
  }, [fetchData]);

  useEffect(() => {
    if (location.state?.showToast) {
      toast.success(location.state.showToast);
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location.state, location.pathname, navigate]);

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handlePerPageChange = (e) => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1);
    dispatch({
      type: "EDIT_PAGINATION_TEST_BUILDER",
      payload: {
        pagination: {
          limit: Number(e.target.value),
          totalPages: Math.ceil(pagination.totalJobs / Number(e.target.value)),
        },
      },
    });
  };

  const paginate = async (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch({
      type: "EDIT_PAGINATION_TEST_BUILDER",
      payload: {
        pagination: {
          currentPage: pageNumber,
          limit: Number(perPage),
          totalPages: Math.ceil(pagination.totalJobs / Number(perPage)),
          totalJobs: pagination.totalJobs,
        },
      },
    });
  };

  const handleDelete = async () => {
    closeModal();
    const id = testBuilderToDelete;
    try {
      if (displayedBuilders.length !== 1) {
        setDeleting((prev) => ({ ...prev, [id]: true }));
        await dispatch(deleteTestBuilder({ _id: id }));
        toast.success("Test Builder deleted successfully!");
      } else {
        setDeleting((prev) => ({ ...prev, [id]: true }));
        await dispatch(deleteTestBuilder({ _id: id }));
        toast.success("Test Builder deleted successfully!");

        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          dispatch({
            type: "EDIT_PAGINATION_TEST_BUILDER",
            payload: {
              pagination: {
                currentPage: currentPage - 1,
              },
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.message || "Failed to delete Test Builder.";
      toast.error(errorMessage);
    } finally {
      setDeleting((prev) => ({ ...prev, [id]: false }));
    }
  };

  const openModal = (id) => {
    setTestBuilderToDelete(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTestBuilderToDelete(null);
  };

  return (
    <div className="mx-auto px-4 sm:px-8 w-full">
      <ToastContainer />
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDelete}
        question="Are you sure you want to delete this test builder?"
        loading={deleting[testBuilderToDelete] || false}
      />
      <div className="py-8 w-full">
        <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
          <h2 className="text-2xl leading-tight">Tests</h2>
          <Link
            to="/employerDashboard/TestBuilderCreator"
            className="bg-purpleButton text-white px-6 p-2 w-36 rounded-full"
          >
            Create Test
          </Link>
        </div>
        <div className="py-4 overflow-x-auto">
          <div className="w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="hidden sm:table-cell px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal">
                    DATE
                  </th>
                  <th className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal">
                    TITLE
                  </th>
                  <th className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="3" className="text-center p-5">
                      <ButtonLoader />
                    </td>
                  </tr>
                ) : (
                  displayedBuilders.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={
                          !expandedRows[index] ? "border-b border-gray-200" : ""
                        }
                      >
                        <td className="hidden sm:table-cell px-5 py-5 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {new Date(item.createdAt).toLocaleDateString()}
                          </p>
                        </td>
                        <td className="px-5 py-5 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap flex gap-2">
                            <button
                              className={`sm:hidden w-6 h-6 rounded-full text-white ${
                                expandedRows[index]
                                  ? "bg-green-500"
                                  : "bg-red-500"
                              }`}
                              onClick={() => toggleRow(index)}
                            >
                              {expandedRows[index] ? "-" : "+"}
                            </button>
                            {item.testName}
                          </p>
                        </td>
                        <td className="px-5 py-5 bg-white text-sm">
                          <div className="p-1 md:p-2 flex space-x-1 md:space-x-2">
                            <Link
                              to={{
                                pathname:
                                  "/employerDashboard/TestBuilderCreator",
                              }}
                              state={{ item }} // Pass the item data using the state prop
                              className="p-1 bg-slate-200"
                            >
                              <img src={editIcon} alt="edit" />
                            </Link>
                            <button
                              className="p-1 bg-slate-200"
                              onClick={() => openModal(item._id)}
                              disabled={deleting[item._id]}
                            >
                              {deleting[item._id] ? (
                                <ButtonLoader />
                              ) : (
                                <img src={deleteIcon} alt="delete" />
                              )}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {expandedRows[index] && (
                        <tr className="sm:hidden border-b">
                          <td
                            colSpan="3"
                            className="px-5 py-5 bg-white text-sm"
                          >
                            <div className="flex flex-col">
                              <p className="text-gray-900 whitespace-no-wrap">
                                <strong>Date:</strong>{" "}
                                {new Date(item.createdAt).toLocaleDateString()}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-center sm:justify-between items-center mt-4 text-sm">
          <div className="hidden sm:flex items-center space-x-2">
            <select
              className="p-2 border rounded bg-gray-100"
              onChange={handlePerPageChange}
              value={perPage}
            >
              <option value="2">2</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <span>
              Showing{" "}
              {Math.min(
                (currentPage - 1) * perPage + 1,
                pagination?.totalJobs || 0
              )}{" "}
              to {Math.min(currentPage * perPage, pagination?.totalJobs || 0)}{" "}
              of {pagination?.totalJobs || 0} records
            </span>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => paginate(currentPage - 1)}
              className={`px-2 py-1 rounded ${
                currentPage === 1
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-gray-200 text-gray-600"
              }`}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <button
              key={currentPage}
              onClick={() => {
                paginate(currentPage);
              }}
              className="px-3 py-1 rounded bg-purpleButton text-white"
              disabled={pagination?.totalPages === 0}
            >
              {currentPage}
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              className={`px-2 py-1 rounded ${
                currentPage === pagination?.totalPages ||
                pagination?.totalPages === 0
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-gray-200 text-gray-600"
              }`}
              disabled={
                currentPage === pagination?.totalPages ||
                pagination.totalPages === 0
              }
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestBuilder;
