import React, { useState } from "react";

const Input = ({
  label,
  type = "text",
  placeholder,
  value,
  onChange,
  name,
  required = false,
  disabled = false,
  className = "",
}) => {
  const [isFilled, setIsFilled] = useState(false);

  const handleChange = (e) => {
    setIsFilled(e.target.value !== "");
    onChange(e);
  };

  return (
    <div className={`${className}`}>
      {label && (
        <label className="block text-gray-700 font-bold mb-2">{label}</label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        name={name}
        required={required}
        disabled={disabled}
        className={`appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          isFilled ? "bg-[#e8f0fe]" : ""
        }`}
      />
    </div>
  );
};

export default Input;
