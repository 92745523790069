import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSpecificJob } from "../../../services/apiService"; // Adjust the import path accordingly
import ButtonLoader from "../../../component/Loaders/ButtonLoader";
import { Link } from "react-router-dom";

const Training = () => {
  let { id } = useParams();
  id = id.split("_");
  const jobId = id[0];
  const JobApplyId = id[1];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [jobDetails, setJobDetails] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await dispatch(getSpecificJob({ id: jobId }));
        setJobDetails(response);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch job details:", error);
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [dispatch, jobId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ButtonLoader />
      </div>
    );
  }

  if (!jobDetails) {
    return (
      <div className="text-center mt-10">
        Job not found or error loading job details.
      </div>
    );
  }

  const { title, training } = jobDetails;

  return (
    <div className="m-4 p-4 bg-white shadow-lg rounded-lg overflow-hidden flex flex-col items-center">
      <div className="px-6 py-4 text-center">
        <h2 className="font-bold text-xl mb-2">{jobDetails.title}</h2>
        <p className="text-gray-700 mb-4">
          <strong>Title:</strong> {title}
        </p>
        <p className="text-gray-700 mb-4">
          <strong>training:</strong> {training.title}
        </p>
      </div>
      <div className="px-6 py-4 w-full">
        <h3 className="font-bold text-lg mb-2 text-center">training Video</h3>
        <div className="bg-gray-300 text-center py-4 mb-4">
          <video className="w-full" controls>
            <source src={training.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="px-6 py-4 w-full">
        <h3 className="font-bold text-lg mb-2 text-center">Pdf/Doc</h3>
        <div className="bg-gray-300 text-center py-4 mb-4">
          <iframe
            title="job-training-docs"
            src={training.docs}
            width="100%"
            height="500px"
          ></iframe>
        </div>
      </div>
      <div className="px-6 py-4 text-center w-full">
        <Link
          className="bg-purpleButton hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          to={`/candidateDashboard/setTestPermissions/${JobApplyId}`}
        >
          Apply
        </Link>
      </div>
    </div>
  );
};

export default Training;
