export const generateConsistentAlphanumericfunction =
  function generateConsistentAlphanumeric(inputString) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let hash = 0;

    // Generate a hash code from the input string
    for (let i = 0; i < inputString.length; i++) {
      hash = (hash << 5) - hash + inputString.charCodeAt(i);
      hash |= 0; // Convert to 32-bit integer
    }

    // Convert hash to a positive number
    let positiveHash = Math.abs(hash);

    // Generate a 6-character alphanumeric string
    let alphanumericString = "";
    for (let i = 0; i < 6; i++) {
      alphanumericString += characters[positiveHash % characters.length];
      positiveHash = Math.floor(positiveHash / characters.length);
    }

    return alphanumericString;
  };
