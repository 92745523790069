import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RxCross2, RxPencil2 } from "react-icons/rx";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { FaPlus, FaTrash } from "react-icons/fa6"; // Import FaTrash for delete icon
import { toast, ToastContainer } from "react-toastify";
import ButtonLoader from "../../../component/Loaders/ButtonLoader";
import {
  createTestBuilder,
  deleteQuestion,
  updateQuestion,
  updateTestBuilder,
  addQuestions,
} from "../../../services/apiService";

// Input Component
const Input = ({ label, value, onChange, placeholder, onKeyDown }) => {
  return (
    <div className="mb-4">
      {label && (
        <label className="block text-gray-700 text-sm font-bold mb-2">
          {label}
        </label>
      )}
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
    </div>
  );
};

// MultipleChoiceQuestion Component
const MultipleChoiceQuestion = ({
  options,
  onChange,
  correctAnswerIndex,
  setCorrectAnswerIndex,
  onDeleteOption,
}) => {
  const [newOption, setNewOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleAddOption = (e) => {
    e.preventDefault();
    if (newOption) {
      onChange(newOption);
      setNewOption("");
    }
  };

  return (
    <div className="mb-4">
      <div className="flex flex-wrap gap-2 items-center">
        <input
          type="text"
          placeholder="Add Multiple Choices*"
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleAddOption(e);
            }
          }}
          className="border p-2 rounded w-full md:w-auto"
        />
        <div
          onClick={handleAddOption}
          className="flex justify-center items-center bg-gray-200 rounded-sm cursor-pointer p-2"
        >
          <FaPlus />
        </div>
        <div className="flex flex-wrap gap-2">
          {options.map((option, index) => (
            <div key={index} className="mb-2 flex items-center">
              <span>
                {String.fromCharCode(65 + index)}) {option}
              </span>
              <button
                type="button"
                onClick={() => onDeleteOption(index)}
                className="ml-2 text-red-500 hover:text-red-700"
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
      </div>

      <label className="block text-gray-700 text-sm font-bold mb-2">
        Choose Correct Answer*
      </label>
      <div className="relative">
        <select
          value={correctAnswerIndex !== null ? correctAnswerIndex : ""}
          onChange={(e) => setCorrectAnswerIndex(Number(e.target.value))}
          className="shadow appearance-none border border-1 hover:border-2 border-gray-700 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          onBlur={() => setIsOpen(false)}
          onClick={() => setIsOpen(!isOpen)}
        >
          <option value="">Select correct answer</option>
          {options.map((option, index) => (
            <option key={index} value={index}>
              {String.fromCharCode(65 + index)}) {option}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 cursor-pointer">
          {isOpen ? (
            <AiOutlineUp className="w-4 h-4" />
          ) : (
            <AiOutlineDown className="w-4 h-4" />
          )}
        </div>
      </div>
    </div>
  );
};

// TestBuilderCreator Component
const TestBuilderCreator = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state || {}; // Get the item from the location state

  const testPermissions = useSelector(
    (state) => state.testPermissionsReducer.testPermissions[0]
  );

  const dispatch = useDispatch();

  const [testName, setTestName] = useState("");
  const [testTime, setTestTime] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentWordCount, setCurrentWordCount] = useState(0);
  const [currentType, setCurrentType] = useState("text");
  const [isOpen, setIsOpen] = useState(false);
  const [allowFile, setAllowFile] = useState(false);
  const [wordLimit, setWordLimit] = useState(
    testPermissions?.openQuestionWords || ""
  ); // New state for word limit

  const [currentOptions, setCurrentOptions] = useState([]);
  const [currentCorrectAnswerIndex, setCurrentCorrectAnswerIndex] =
    useState(null);
  const [editingIndex, setEditingIndex] = useState(null);

  const [isLoading, setIsLoading] = useState(false); // New state for loading

  useEffect(() => {
    if (item) {
      setTestName(item.testName || "");
      setTestTime(item.testTime || "");
      setQuestions(item.questions || []);
    }
  }, [item]);

  // Function to update the location state whenever the form state changes
  const updateLocationState = useCallback(
    (newState) => {
      // Only update location state if there's a meaningful change
      const currentState = location.state || {};
      if (JSON.stringify(currentState.item) !== JSON.stringify(newState.item)) {
        navigate(location.pathname, {
          replace: true,
          state: { ...currentState, ...newState },
        });
      }
    },
    [navigate, location]
  );

  const handleSelectChange = (e) => {
    setCurrentType(e.target.value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleAddQuestion = (e) => {
    e.preventDefault();
    if (currentQuestion.trim() === "") return;

    let newQuestion = {
      type: 0, // Default to open question
      questionText: currentQuestion,
      options: null,
      correctAnswer: null,
      allowFile: null,
      wordLimit: null, // Initialize word limit
    };

    switch (currentType) {
      case "multipleChoice":
        newQuestion.type = 1;
        newQuestion.options = currentOptions.length > 0 ? currentOptions : [];
        newQuestion.correctAnswer =
          currentCorrectAnswerIndex !== null ? currentCorrectAnswerIndex : null;
        newQuestion.allowFile = null;
        break;
      case "file":
        newQuestion.type = 2;
        newQuestion.allowFile = null;
        break;
      default:
        newQuestion.type = 0;
        newQuestion.allowFile = allowFile;
        newQuestion.wordLimit =
          wordLimit > (testPermissions?.openQuestionWords || "")
            ? testPermissions?.openQuestionWords || ""
            : wordLimit; // Assign word limit for text questions
        break;
    }
    if (newQuestion.type === 1) {
      if (newQuestion.options.length < 2) {
        toast.error("Please add at least two options");
        return;
      }
      if (newQuestion.correctAnswer === null) {
        toast.error("Please select correct answer");
        return;
      }
    }

    if (editingIndex !== null) {
      const updatedQuestions = [...questions];
      updatedQuestions[editingIndex] = {
        _id: questions[editingIndex]._id,
        ...newQuestion,
      };

      if (item && questions[editingIndex]._id && item._id) {
        const payload = {
          _id: questions[editingIndex]._id,
          question: newQuestion,
          itemId: item._id,
          updatedTestBuilder: {
            ...item,
            questions: updatedQuestions,
          },
          updatedQuestion: newQuestion,
        };

        dispatch(updateQuestion(payload))
          .then(() => {
            setQuestions(updatedQuestions);
            toast.success("Question updated successfully!");
            updateLocationState({
              item: { ...item, questions: updatedQuestions },
            });
          })
          .catch((error) => {
            console.error("Failed to update question:", error);
            toast.error("Failed to update question. Please try again.");
          });
      } else {
        setQuestions(updatedQuestions);
        toast.success("Question edit locally.");
      }
      setEditingIndex(null);
    } else {
      if (item?._id) {
        dispatch(addQuestions({ _id: item._id, questions: [newQuestion] }))
          .then((response) => {
            setQuestions([...response.data, ...questions]);
            toast.success("Question added successfully!");
            updateLocationState({
              item: { ...item, questions: questions },
            });
          })
          .catch((error) => {
            console.error("Failed to add question:", error);
            toast.error("Failed to add question. Please try again.");
          });
      } else {
        const updatedQuestions = [...questions, newQuestion];
        setQuestions(updatedQuestions);
      }
    }

    // Clear the form after adding or editing
    setCurrentQuestion("");
    setCurrentOptions([]);
    setCurrentCorrectAnswerIndex(null);
    setCurrentWordCount(0);
    setAllowFile(false);
    setWordLimit(testPermissions?.openQuestionWords || ""); // Reset word limit
  };

  const handleRemoveQuestion = async (index) => {
    if (questions.length === 1) {
      toast.error(`
        You cannot remove the last question in the test builder.
        `);
      return;
    }
    if (item && questions[index]._id) {
      const updatedQuestions = questions.filter((_, i) => i !== index);
      try {
        const payload = {
          _id: questions[index]._id,
          itemId: item._id,
          updatedTestBuilder: {
            ...item,
            questions: updatedQuestions,
          },
        };
        await dispatch(deleteQuestion(payload));
        setQuestions(updatedQuestions);
        toast.success("Question deleted successfully!");
        updateLocationState({ item: { ...item, questions: updatedQuestions } });
      } catch (error) {
        console.error("Failed to delete question:", error);
        toast.error(error.response.data.message);
      }
    } else {
      const updatedQuestions = questions.filter((_, i) => i !== index);
      setQuestions(updatedQuestions);
      updateLocationState({ item: { ...item, questions: updatedQuestions } });
    }
  };

  const handleEditQuestion = (index) => {
    const questionToEdit = questions[index];
    setCurrentQuestion(questionToEdit.questionText);
    setCurrentType(
      questionToEdit.type === 1
        ? "multipleChoice"
        : questionToEdit.type === 2
        ? "file"
        : "text"
    );
    setCurrentOptions(questionToEdit.options || []);
    setCurrentCorrectAnswerIndex(
      questionToEdit.type === 1 ? questionToEdit.correctAnswer : null
    );
    setAllowFile(questionToEdit.allowFile || false);
    setWordLimit(
      questionToEdit.wordLimit > (testPermissions?.openQuestionWords || "")
        ? testPermissions?.openQuestionWords || ""
        : questionToEdit.wordLimit // Assign word limit for text questions
    ); // Set word limit from the question
    setEditingIndex(index);
  };

  // Handle cancelling the edit
  const handleCancelEdit = () => {
    // Reset the form fields
    setCurrentQuestion("");
    setCurrentOptions([]);
    setCurrentCorrectAnswerIndex(null);
    setAllowFile(false);
    setCurrentType("text");
    setWordLimit(testPermissions?.openQuestionWords || ""); // Reset word limit

    // Clear the editing index
    setEditingIndex(null);
  };

  // Handle updating the entire test builder
  const handleSubmitTestBuilder = async () => {
    if (
      testName.trim() === "" ||
      String(testTime).trim() === "" ||
      questions.length === 0
    ) {
      toast.error(
        "Please fill all the required fields and add at least one question."
      );
      return;
    }

    if (typeof Number(testTime) !== "number") {
      toast.error("Please enter test time in number.");
      return;
    }

    setIsLoading(true); // Show loader

    try {
      if (item?._id) {
        const payload = {
          _id: item?._id,
          testName,
          testTime,
        };
        // Update existing test builder
        const updatedTestBuilder = await dispatch(updateTestBuilder(payload));

        // Replace all states with the response
        setTestName(updatedTestBuilder.testName);
        setTestTime(updatedTestBuilder.testTime);
        setQuestions(updatedTestBuilder.questions);

        updateLocationState({
          item: updatedTestBuilder,
        });
        navigate("/employerDashboard/TestBuilder", {
          state: { showToast: "Test Builder updated successfully!" },
        });
      } else {
        const payload = {
          _id: item?._id,
          testName,
          testTime,
          questions,
        };
        // Create new test builder
        await dispatch(createTestBuilder(payload));

        // Reset form after creation
        setTestName("");
        setTestTime("");
        setQuestions([]);
        navigate("/employerDashboard/TestBuilder", {
          state: { showToast: "Test Builder created successfully!" },
        });
      }
    } catch (error) {
      console.error("Failed to update/create test builder:", error);
      toast.error("Failed to save test builder. Please try again.");
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  // Update location state whenever testName, testTime or questions change
  useEffect(() => {
    if (item?._id) {
      if (testName || testTime || questions.length) {
        updateLocationState({
          item: { ...item, testName, testTime, questions },
        });
      }
    }
  }, [testName, testTime, questions, item, updateLocationState]);

  // Function to delete a specific option
  const handleDeleteOption = (index) => {
    const updatedOptions = currentOptions.filter((_, i) => i !== index);
    setCurrentOptions(updatedOptions);

    // Adjust the correct answer index if necessary
    if (index === currentCorrectAnswerIndex) {
      setCurrentCorrectAnswerIndex(null); // Reset if deleted option was the correct answer
    } else if (index < currentCorrectAnswerIndex) {
      setCurrentCorrectAnswerIndex((prev) => prev - 1); // Adjust the index
    }
  };

  return (
    <form
      className="w-full mx-auto mt-8 px-4 sm:px-8"
      onSubmit={(e) => e.preventDefault()}
    >
      <ToastContainer />

      <div className="flex justify-between py-4 items-center">
        <div className="font-bold">Build your test below</div>
        <div
          className="bg-purpleButton px-6 py-2 rounded-full text-white cursor-pointer"
          onClick={handleSubmitTestBuilder}
        >
          {isLoading ? <ButtonLoader /> : item ? "Edit" : "Post"}
        </div>
      </div>
      <Input
        label="Enter Test Name"
        value={testName}
        onChange={(e) => setTestName(e.target.value)}
        placeholder="Test Name"
      />
      <Input
        label="Test Time (in minutes)"
        value={testTime}
        onChange={(e) => setTestTime(e.target.value)}
        placeholder="Test Time"
      />

      <div className="mb-4 relative">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Question Type
        </label>
        <div className="relative">
          <select
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            value={currentType}
            onChange={handleSelectChange}
            onClick={toggleDropdown}
            onBlur={() => setIsOpen(false)}
          >
            <option value="text">Open Question</option>
            <option value="multipleChoice">Multiple Choice Question</option>
            <option value="file">File Question</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            {isOpen ? (
              <AiOutlineUp className="w-4 h-4" />
            ) : (
              <AiOutlineDown className="w-4 h-4" />
            )}
          </div>
        </div>
      </div>

      {currentType === "text" && (
        <div className="mb-4">
          <Input
            label="Add Question*"
            value={currentQuestion}
            onChange={(e) => {
              setCurrentQuestion(e.target.value);
              setCurrentWordCount(e.target.value.split(" ").length);
            }}
            placeholder="Type your question here..."
          />
          <div className="flex flex-wrap justify-between my-4 mx-7 gap-4">
            <div className="flex flex-wrap gap-4 items-center">
              <div className="sm:w-36">
                Enter number of words maximum allowed (max 520)
              </div>
              <p className="p-3 bg-slate-200 font-semibold">
                {currentWordCount} words
              </p>
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Select Word Limit
              </label>
              <select
                value={wordLimit}
                onChange={(e) => setWordLimit(Number(e.target.value))}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                {Array.from(
                  { length: testPermissions?.openQuestionWords || "" },
                  (_, i) => i + 1
                ).map((num) => (
                  <option key={num} value={num}>
                    {num} words
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                id="allowFile"
                checked={allowFile}
                onChange={() => setAllowFile(!allowFile)}
              />
              <label htmlFor="allowFile" className="sm:w-60 mt-4 sm:mt-0">
                Tick to allow candidates to upload a file
              </label>
            </div>
          </div>
        </div>
      )}

      {currentType === "multipleChoice" && (
        <div>
          <Input
            label="Add Question*"
            value={currentQuestion}
            onChange={(e) => setCurrentQuestion(e.target.value)}
            placeholder="Type your question here..."
          />
          <MultipleChoiceQuestion
            options={currentOptions}
            onChange={(option) => {
              if (
                currentOptions.length < testPermissions?.multiChoiceQuestions
              ) {
                // Restrict to a maximum of 4 options
                setCurrentOptions([...currentOptions, option]);
              } else {
                toast.error(
                  `You can only add up to ${testPermissions?.multiChoiceQuestions} options.`
                );
              }
            }}
            correctAnswerIndex={currentCorrectAnswerIndex}
            setCorrectAnswerIndex={setCurrentCorrectAnswerIndex}
            onDeleteOption={handleDeleteOption} // Handle option deletion
          />
        </div>
      )}

      {currentType === "file" && (
        <div className="mb-4">
          <Input
            label="Add Files*"
            value={currentQuestion}
            onChange={(e) => setCurrentQuestion(e.target.value)}
            placeholder="Type your question here..."
          />
        </div>
      )}

      <div className="flex justify-center gap-4">
        <button
          type="submit"
          className="bg-purpleButton px-6 py-2 text-white rounded-full"
          onClick={handleAddQuestion}
        >
          {editingIndex !== null ? "Update" : "Add"} Question
        </button>

        {editingIndex !== null && (
          <button
            type="button"
            className="bg-gray-500 px-6 py-2 text-white rounded-full"
            onClick={handleCancelEdit}
          >
            Cancel
          </button>
        )}
      </div>

      {questions.length > 0 && (
        <div className="mt-8">
          <h2 className="text-2xl mb-4">Added Questions</h2>
          {questions.map((q, index) => (
            <div key={index} className="mb-4 px-4 py-2 rounded bg-lightGreen">
              <div className="flex justify-between items-center">
                <h3 className="flex flex-wrap gap-4 items-center">
                  <div className="text-xl">
                    {q.type === 1
                      ? "Multiple Choice"
                      : q.type === 2
                      ? "File"
                      : "Question"}
                    : {index + 1}
                  </div>
                  <p className="text-sm">{q.questionText}</p>
                  {q.type === 2 && (
                    <div className="flex flex-col gap-2 font-semibold">
                      Type File
                    </div>
                  )}
                </h3>
                <div className="flex gap-2">
                  <button
                    type="button"
                    onClick={() => handleEditQuestion(index)}
                  >
                    <RxPencil2 className="text-2xl font-semibold" />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRemoveQuestion(index)}
                  >
                    <RxCross2 className="text-2xl font-semibold" />
                  </button>
                </div>
              </div>
              {q.type === 1 && q.options && (
                <ul className="flex flex-wrap gap-2">
                  {q.options.map((option, i) => (
                    <li key={i}>
                      <input
                        type="radio"
                        name={`question-${index}`}
                        value={option}
                        checked={Number(q.correctAnswer) === i}
                        readOnly
                      />
                      {String.fromCharCode(65 + i)}) {option}
                    </li>
                  ))}
                </ul>
              )}

              {q.type === 0 && q.allowFile && (
                <p className="text-sm mt-2">
                  <strong>Note:</strong> Candidates can upload a file for this
                  question.
                </p>
              )}
              {q.type === 0 && q.wordLimit && (
                <p className="text-sm mt-2">
                  <strong>Word Limit:</strong> {q.wordLimit} words
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </form>
  );
};

export default TestBuilderCreator;
