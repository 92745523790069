const initialState = {
  testPermissions: [], // List of help and guidance items
};

const testPermissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_TEST_PERMISSIONS":
      return {
        testPermissions: [action.payload],
      };
    default:
      return state;
  }
};

export default testPermissionsReducer;
