import React from "react";
import Congratulation from "../../../../images/../images/congratulations/congratulationApplication.png";
import { Link } from "react-router-dom";
const Modal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border max-w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <img
              src={Congratulation}
              alt="congratulation"
              className="max-w-24"
            />
          </div>
          <h3 className="text-lg font-medium text-gray-900">Congratulations</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">
              You have successfully completed the application.
            </p>
          </div>
          <div className="items-center px-4 py-3">
            <Link
              to="/CandidateDashboard/applications"
              onClick={onClose}
              className="px-4 py-2 bg-purpleButton text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Close
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
