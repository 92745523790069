const initialState = {
  candidateHelpAndGuide: [],
};

const helpAndGuide_candidate = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_candidate_HELP_AND_GUIDE":
      return {
        ...state,
        candidateHelpAndGuide: action.payload,
      };
    default:
      return state;
  }
};

export default helpAndGuide_candidate;
