import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getSubscriptionHistory } from "../../services/apiService";
import ButtonLoader from "../../component/Loaders/ButtonLoader";

const BillingHistoryTable = () => {
  const [expandedRows, setExpandedRows] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [billingHistory, setBillingHistory] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSubscriptionHistory = async () => {
      try {
        setLoading(true);
        const response = await dispatch(getSubscriptionHistory());
        const currentPackage = response.currentPackage
          ? [response.currentPackage]
          : [];
        setBillingHistory([...currentPackage, ...response.subscriptionHistory]);
        toast.success("Subscription history fetched successfully!");
      } catch (error) {
        toast.error("Failed to fetch subscription history.");
        console.error("Error fetching subscription history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionHistory();
  }, [dispatch]);

  const indexOfLastRecord = currentPage * perPage;
  const indexOfFirstRecord = indexOfLastRecord - perPage;
  const currentRecords = billingHistory.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handlePerPageChange = (e) => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page whenever perPage changes
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <ToastContainer />
      <div className="py-8">
        <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
          <h2 className="text-2xl leading-tight">Billing History</h2>
        </div>

        {loading ? (
          <ButtonLoader />
        ) : (
          <>
            <div className="py-4 overflow-x-auto">
              <div className="min-w-full shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="hidden sm:table-cell px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="hidden sm:table-cell px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={
                            !expandedRows[index]
                              ? "border-b border-gray-200"
                              : ""
                          }
                        >
                          <td className="px-5 py-5 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap flex gap-2">
                              <button
                                className={`sm:hidden w-6 h-6 rounded-full text-white ${
                                  expandedRows[index]
                                    ? "bg-green-500"
                                    : "bg-red-500"
                                }`}
                                onClick={() => toggleRow(index)}
                              >
                                {expandedRows[index] ? "-" : "+"}
                              </button>
                              {item.title}
                            </p>
                          </td>
                          <td className="px-5 py-5 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              ${item.pricePerCredit * item.numberOfCredits}
                            </p>
                          </td>
                          <td className="hidden sm:table-cell px-5 py-5 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {new Date(item.createdAt).toLocaleDateString()}
                            </p>
                          </td>
                          <td className="hidden sm:table-cell px-5 py-5 bg-white text-sm">
                            <span
                              className={`relative inline-block px-3 py-1 font-semibold text-${
                                item.active ? "green" : "red"
                              }-900 leading-tight`}
                            >
                              <span
                                aria-hidden
                                className={`absolute inset-0 bg-${
                                  item.active ? "green" : "red"
                                }-200 opacity-50 rounded-full`}
                              ></span>
                              <span className="relative">
                                {item.active ? "Active" : "Inactive"}
                              </span>
                            </span>
                          </td>
                        </tr>
                        {expandedRows[index] && (
                          <tr className="sm:hidden border-b">
                            <td
                              colSpan="4"
                              className="px-5 py-5 bg-white text-sm"
                            >
                              <div className="flex flex-col">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  <strong>Date:</strong>{" "}
                                  {new Date(
                                    item.createdAt
                                  ).toLocaleDateString()}
                                </p>
                                <p className="text-gray-900 whitespace-no-wrap">
                                  <strong>Status:</strong>{" "}
                                  <span
                                    className={`relative inline-block px-3 py-1 font-semibold text-${
                                      item.active ? "green" : "red"
                                    }-900 leading-tight`}
                                  >
                                    <span
                                      aria-hidden
                                      className={`absolute inset-0 bg-${
                                        item.active ? "green" : "red"
                                      }-200 opacity-50 rounded-full`}
                                    ></span>
                                    <span className="relative">
                                      {item.active ? "Active" : "Inactive"}
                                    </span>
                                  </span>
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-center sm:justify-between items-center mt-4 text-sm">
              <div className="hidden sm:flex items-center space-x-2">
                <select
                  className="p-2 border rounded bg-gray-100"
                  onChange={handlePerPageChange}
                  value={perPage}
                >
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>
                <span>
                  Showing {indexOfFirstRecord + 1} to {indexOfLastRecord} of{" "}
                  {billingHistory.length} records
                </span>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  className={`px-2 py-1 rounded ${
                    currentPage === 1
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-gray-200 text-gray-600"
                  }`}
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft />
                </button>
                <button
                  key={currentPage}
                  onClick={() => paginate(currentPage)}
                  className="px-3 py-1 rounded bg-purpleButton text-white"
                >
                  {currentPage}
                </button>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  className={`px-2 py-1 rounded ${
                    currentPage === Math.ceil(billingHistory.length / perPage)
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-gray-200 text-gray-600"
                  }`}
                  disabled={
                    currentPage === Math.ceil(billingHistory.length / perPage)
                  }
                >
                  <FaChevronRight />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BillingHistoryTable;
