import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signupCandidate, signupEmployer } from "../../services/apiService";
import rightArrowIs from "../../images/auth/rightArrowIs.png";
import BackgroundAuth from "../../component/auth/BackgroundAuth";
import employer from "../../images/auth/employer.png";
import candidate from "../../images/auth/candidate.png";
import ButtonLoader from "../../component/Loaders/ButtonLoader";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const location = useLocation();
  const initialRole = location.state?.role || "employer";
  const [selectedRole, setSelectedRole] = useState(initialRole);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignup = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    if (!selectedRole) {
      toast.error("Please select a role");
      return;
    }

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      company_name:
        selectedRole === "employer"
          ? companyName === ""
            ? undefined
            : companyName
          : undefined,
    };

    setLoading(true); // Set loading to true

    try {
      if (selectedRole === "employer") {
        await dispatch(signupEmployer(payload));
        setLoading(false); // Set loading to false
        navigate("/employerDashboard/main");
      } else if (selectedRole === "candidate") {
        await dispatch(signupCandidate(payload));
        setLoading(false); // Set loading to false
        navigate("/candidateDashboard/main");
      }
    } catch (err) {
      setLoading(false); // Set loading to false
      toast.error(err.message || "Signup failed");
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      <div className={`h-screen flex flex-col sm:flex-row `}>
        <BackgroundAuth />
        <div className="w-full sm:w-[55%] p-8 sm:p-16 h-full sm:overflow-scroll">
          <div className="md:max-w-[80%] flex flex-col justify-between h-full">
            <div className="font-bold text-2xl">Register</div>
            <div>
              Already have an account?{" "}
              <Link
                to="/login"
                state={{ role: selectedRole }}
                className="text-[#5533ff] font-bold"
              >
                login here
              </Link>
            </div>
            <div className="mt-2">Please select your role</div>
            <div className="flex justify-between mt-2 gap-2">
              <div
                className={`border rounded-lg p-4 w-52 h-32 flex flex-col justify-center items-center relative ${
                  selectedRole === "employer" ? "border-blue-500" : ""
                }`}
                onClick={() => setSelectedRole("employer")}
              >
                <img src={employer} alt="Employer" className="w-16 mb-2" />
                <span className="text-gray-700">Employer</span>
                <input
                  type="radio"
                  name="selection"
                  className="absolute top-2 right-2 border border-gray-300 rounded-full w-4 h-4"
                  checked={selectedRole === "employer"}
                  onChange={() => setSelectedRole("employer")}
                />
              </div>
              <div
                className={`border rounded-lg p-4 w-52 h-32 flex flex-col justify-center items-center relative ${
                  selectedRole === "candidate" ? "border-blue-500" : ""
                }`}
                onClick={() => setSelectedRole("candidate")}
              >
                <img src={candidate} alt="Candidate" className="w-16 mb-2" />
                <span className="text-gray-700">Candidate</span>
                <input
                  type="radio"
                  name="selection"
                  className="absolute top-2 right-2 border border-gray-300 rounded-full w-4 h-4"
                  checked={selectedRole === "candidate"}
                  onChange={() => setSelectedRole("candidate")}
                />
              </div>
            </div>
            <div className="flex gap-4 w-full mt-4">
              <div className="w-[50%]">
                <div>First Name*</div>
                <input
                  type="text"
                  placeholder="enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={`p-2 w-full rounded-2xl mt-1 ${
                    firstName ? "bg-slate-100" : "bg-white"
                  } border-2`}
                />
              </div>
              <div className="w-[50%]">
                <div>Last Name*</div>
                <input
                  type="text"
                  placeholder="enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={`p-2 w-full rounded-2xl mt-1 ${
                    lastName ? "bg-slate-100" : "bg-white"
                  } border-2`}
                />
              </div>
            </div>
            {selectedRole === "employer" && (
              <div>
                <div>Company Name</div>
                <input
                  type="text"
                  placeholder="enter company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className={`p-2 w-full rounded-2xl mt-1 ${
                    companyName ? "bg-slate-100" : "bg-white"
                  } border-2`}
                />
              </div>
            )}
            <div>
              <div>Email*</div>
              <input
                type="email"
                placeholder="enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`p-2 w-full rounded-2xl mt-1 ${
                  email ? "bg-slate-100" : "bg-white"
                } border-2`}
              />
            </div>
            <div>
              <div>Password*</div>
              <input
                type="password"
                placeholder="enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`p-2 w-full rounded-2xl mt-1 ${
                  password ? "bg-slate-100" : "bg-white"
                } border-2`}
              />
            </div>
            <div>
              <div>Confirm password*</div>
              <input
                type="password"
                placeholder="confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={`p-2 w-full rounded-2xl mt-1 ${
                  confirmPassword ? "bg-slate-100" : "bg-white"
                } border-2`}
              />
            </div>
            <button
              onClick={handleSignup}
              className={`bg-[#5533ff] w-28 text-white text-center p-2 rounded-full mt-4 ${
                loading && "opacity-50 cursor-not-allowed "
              }`}
            >
              {loading && <ButtonLoader />} {/* Display loader if loading */}
              {!loading && (
                <>
                  Register
                  <img
                    src={rightArrowIs}
                    alt="right arrow"
                    className="inline w-5"
                  />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
