import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getSubmittedTest,
  markQuestionCorrectUnCorrect,
} from "../../../services/apiService";
import { toast } from "react-toastify"; // Import Toastify for notifications

const SeeApplicationDetailTestResult = () => {
  const jobApplication = useSelector(
    (state) => state.jobApplicationSingleReducer_Employer?.jobApplication[0]
  );

  const [testResults, setTestResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Memoize fetchTestResults using useCallback to avoid recreation on every render
  const fetchTestResults = useCallback(async () => {
    if (jobApplication) {
      setLoading(true);
      try {
        const payload = {
          jobApplyId: jobApplication._id,
          candidateId: jobApplication.candidateId,
        };
        const response = await dispatch(getSubmittedTest(payload));
        setTestResults(response);
      } catch (error) {
        console.error("Failed to fetch submitted test results", error);
      } finally {
        setLoading(false);
      }
    }
  }, [dispatch, jobApplication]);

  useEffect(() => {
    fetchTestResults();
  }, [fetchTestResults]);

  const handleMarkQuestion = async (testId, questionId, isCorrect) => {
    try {
      const payload = {
        testId,
        questionId,
        isCorrect,
      };
      await dispatch(markQuestionCorrectUnCorrect(payload));
      toast.success("Question marked successfully!"); // Success notification

      // Update testResults state locally without fetching again
      setTestResults((prevResults) => {
        if (!prevResults) return prevResults;

        const updatedQuestions = prevResults.questions.map((question) => {
          if (question._id === questionId) {
            return { ...question, isCorrect };
          }
          return question;
        });

        return { ...prevResults, questions: updatedQuestions };
      });
    } catch (error) {
      console.error("Failed to mark question", error);
      toast.error("Failed to mark question. Please try again."); // Error notification
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  if (!testResults) {
    return <div>No test results available</div>;
  }

  return (
    <div className="flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full">
        <div className="sm:hidden">
          <div className="mb-4">
            <h2 className="text-lg font-bold">Total Questions</h2>
            <p>{testResults.totalQuestion}</p>
          </div>
          <div className="mb-4">
            <h2 className="text-lg font-bold">Answered Questions</h2>
            <p>{testResults.answeredQuestion}</p>
          </div>
          <div className="mb-4">
            <h2 className="text-lg font-bold">Status</h2>
            <p
              className={`${
                testResults.status === "Completed"
                  ? "text-greenButton bg-green-100"
                  : "text-red-500 bg-red-100"
              } w-24 text-center rounded-full`}
            >
              {testResults.status}
            </p>
          </div>
          <div className="mb-4">
            <h2 className="text-lg font-bold">Comments</h2>
            <p>{testResults.comment}</p>
          </div>
        </div>
        <div className="hidden sm:flex justify-between">
          <div className="flex flex-col">
            <div className="mb-4">
              <h2 className="text-lg font-bold">Answered Questions</h2>
              <p>{testResults.answeredQuestion}</p>
            </div>
            <div className="mb-4">
              <h2 className="text-lg font-bold">Status</h2>
              <p
                className={`${
                  testResults.status === "Completed"
                    ? "text-greenButton bg-green-100"
                    : "text-red-500 bg-red-100"
                } w-24 text-center rounded-full`}
              >
                {testResults.status}
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="mb-4">
              <h2 className="text-lg font-bold">Total Questions</h2>
              <p>{testResults.totalQuestion}</p>
            </div>
            <div className="mb-4">
              <h2 className="text-lg font-bold">Comments</h2>
              <p>{testResults.comment}</p>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-bold">Video & Audio Recordings:</h2>
          {testResults.recordedVideo ? (
            <a
              href={testResults.recordedVideo}
              className="text-blue-500"
              target="_blank"
              rel="noreferrer noopener"
            >
              Click here
            </a>
          ) : (
            <p>No recordings available</p>
          )}
        </div>

        <div>
          <h2 className="text-lg font-bold mb-4">Given Answers:</h2>
          <p className="text-sm text-gray-600 mb-2">
            Note: Please check if all given answers are correct or not
          </p>
          {testResults.questions.map((question, index) => (
            <div className="mb-4" key={question._id}>
              <h3 className="font-semibold">{`Question ${index + 1}: ${
                question.questionText
              }`}</h3>

              {/* Display multiple-choice options if available */}
              {question.type === 1 && question.options && (
                <div className="flex flex-col mt-2">
                  <h4 className="font-semibold">Options:</h4>
                  {question.options.map((option, optionIndex) => (
                    <label key={optionIndex} className="flex items-center">
                      <input
                        type="radio"
                        name={`q${index}`}
                        value={option}
                        className="mr-2"
                        defaultChecked={question.answer === option}
                        disabled // Make the radio button read-only
                      />
                      {option}
                    </label>
                  ))}
                </div>
              )}

              {/* Display file answer if it exists */}
              {question.fileAnswer && (
                <p>
                  File Answer:{" "}
                  <a
                    href={question.fileAnswer}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-blue-500 underline"
                  >
                    Click here
                  </a>
                </p>
              )}

              {/* Display answer text if available */}
              {question.answer && <p>Answer: {question.answer}</p>}

              {/* Radio buttons to mark Correct or Incorrect */}
              <div className="flex items-center mt-2">
                <label className="mr-4">
                  <input
                    type="radio"
                    name={`correctness-${question._id}`}
                    className="mr-1"
                    onChange={() =>
                      handleMarkQuestion(testResults._id, question._id, true)
                    }
                    checked={question.isCorrect === true}
                  />
                  Correct
                </label>
                <label>
                  <input
                    type="radio"
                    name={`correctness-${question._id}`}
                    className="mr-1"
                    onChange={() =>
                      handleMarkQuestion(testResults._id, question._id, false)
                    }
                    checked={question.isCorrect === false}
                  />
                  Incorrect
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeeApplicationDetailTestResult;
