import React, { useEffect, useState, useMemo } from "react";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { FaCheck, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RiPencilLine } from "react-icons/ri";
import profile from "../../images/employer candidate/profile Button.png";
import deleteUI from "../../images/employer candidate/delete button.png";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  EmployerGetjobApplications,
  UpdateJobNote,
  DeleteJobNote,
} from "../../services/apiService";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import { toast, ToastContainer } from "react-toastify";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CandidateList = () => {
  const query = useQuery();
  const statusFilter = query.get("status");
  const dispatch = useDispatch();
  const { jobApplications, pagination } = useSelector(
    (state) => state.jobApplicationReducer_Employer
  );

  const [candidatesPerPage, setCandidatesPerPage] = useState(
    pagination.pageSize
  );
  const [currentPage, setCurrentPage] = useState(pagination.currentPage);
  const [expandedRow, setExpandedRow] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [comments, setComments] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingComments, setLoadingComments] = useState({}); // Track loading state for each comment
  const [loadingDeletes, setLoadingDeletes] = useState({}); // Track loading state for each delete action

  useEffect(() => {
    const fetchJobApplications = async () => {
      setLoadingComments({});
      try {
        setLoading(true);
        await dispatch(
          EmployerGetjobApplications({
            page: currentPage,
            limit: candidatesPerPage,
          })
        );
        setLoading(false);
        toast.success("Job applications fetched successfully.");
      } catch (error) {
        setLoading(false);
        toast.error("Failed to fetch job applications.");
      }
    };

    fetchJobApplications();
  }, [dispatch, currentPage, candidatesPerPage]);

  const filteredCandidates = useMemo(() => {
    return jobApplications.filter((candidate) => {
      if (statusFilter === "pending") return candidate.status === 0;
      if (statusFilter === "accepted") return candidate.status === 1;
      if (statusFilter === "rejected") return candidate.status === 2;
      if (statusFilter === "test taken") return candidate.status === 3;
      if (statusFilter === "passed") return candidate.status === 4;
      if (statusFilter === "contract signed") return candidate.status === 5;
      if (statusFilter === "Hired") return candidate.success === 1;
      return true;
    });
  }, [statusFilter, jobApplications]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCandidatesPerPageChange = (event) => {
    setCandidatesPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page whenever candidates per page changes
  };

  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleEditClick = (index) => {
    setEditingIndex(editingIndex === index ? null : index);
    setComments({
      ...comments,
      [index]: jobApplications[index].note,
    });
  };

  const handleSaveClick = async (index) => {
    // Set loading state for the specific comment
    setLoadingComments((prev) => ({ ...prev, [index]: true }));
    try {
      const updatedNote = comments[index];
      const jobApplication = jobApplications[index];
      // Dispatch the action to update the note
      await dispatch(
        UpdateJobNote({
          jobId: jobApplication.jobId,
          candidateId: jobApplication.candidateId,
          note: updatedNote,
        })
      );

      setEditingIndex(null);
      toast.success("Comment edited successfully!");
    } catch (error) {
      toast.error("Failed to edit comment.");
    } finally {
      // Reset loading state for the specific comment
      setLoadingComments((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleDeleteClick = async (index) => {
    // Set loading state for the specific delete button
    setLoadingDeletes((prev) => ({ ...prev, [index]: true }));
    try {
      const jobApplication = jobApplications[index];
      // Dispatch the action to delete the job application
      await dispatch(
        DeleteJobNote({
          _id: jobApplication._id,
          jobId: jobApplication.jobId,
          candidateId: jobApplication.candidateId,
        })
      );

      toast.success("Job application deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete job application.");
    } finally {
      // Reset loading state for the specific delete button
      setLoadingDeletes((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleCommentChange = (index, newComment) => {
    setComments({
      ...comments,
      [index]: newComment,
    });
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "bg-[#fcd9e2] text-[#f1416c]";
      case 1:
        return "bg-green-200 text-green-600";
      case 2:
        return "bg-[#ccf4f1] text-[#00c9b7]";
      case 3:
        return "bg-[#fff4d0] text-[#ffc715]";
      case 4:
        return "bg-[#ccf4f1] text-[#00c9b7]";
      case 5:
        return "bg-blue-200 text-blue-600";
      case 6:
        return "bg-green-200 text-green-600";
      default:
        return "bg-[#ccf4f1] text-[#00c9b7]";
    }
  };

  if (loading) {
    return (
      <div className="mt-[15%]">
        <ButtonLoader />
      </div>
    );
  }

  function generateConsistentAlphanumeric(inputString) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let hash = 0;

    // Generate a hash code from the input string
    for (let i = 0; i < inputString.length; i++) {
      hash = (hash << 5) - hash + inputString.charCodeAt(i);
      hash |= 0; // Convert to 32-bit integer
    }

    // Convert hash to a positive number
    let positiveHash = Math.abs(hash);

    // Generate a 6-character alphanumeric string
    let alphanumericString = "";
    for (let i = 0; i < 6; i++) {
      alphanumericString += characters[positiveHash % characters.length];
      positiveHash = Math.floor(positiveHash / characters.length);
    }

    return alphanumericString;
  }

  return (
    <div className="rounded-lg p-4 bg-white shadow">
      <ToastContainer />
      <>
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            placeholder="Search Candidate"
            className="p-4  border rounded bg-gray-100 hidden"
          />
        </div>

        {/* Desktop and Tablet */}
        <div className="hidden sm:flex flex-col">
          <table className="min-w-full text-xs lg:text-sm">
            <thead>
              <tr className="text-left text-gray-400">
                <th className="p-2">APPLICATION. NO</th>
                <th className="p-2">CANDIDATE</th>
                <th className="p-2">JOB REF. NO</th>
                <th className="p-2">NOTES</th>
                <th className="p-2">STATUS</th>
                <th className="p-2">ACTION</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {filteredCandidates.map((jobApplication, index) => (
                <tr key={index} className="border-t">
                  <td className="p-2 font-bold">{jobApplication._id}</td>
                  <td className="p-2">
                    <div className="font-bold">
                      {jobApplication.candidate.first_name}{" "}
                      {jobApplication.candidate.last_name}
                    </div>
                    <div className="text-gray-500">{jobApplication.email}</div>
                  </td>
                  <td>
                    {generateConsistentAlphanumeric(jobApplication.jobId)}
                  </td>
                  <td className="p-2 flex items-center space-x-2">
                    <textarea
                      value={comments[index] || jobApplication.note}
                      onChange={(e) =>
                        handleCommentChange(index, e.target.value)
                      }
                      className={`w-[200px] h-20 p-2 border text-[10px] ${
                        editingIndex === index
                          ? "border-gray-300"
                          : "border-transparent"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                      disabled={editingIndex !== index}
                    />
                    <div className="top-2 right-2 flex flex-col gap-2">
                      {loadingComments[index] ? (
                        <ButtonLoader /> // Show loader for the specific comment
                      ) : (
                        <>
                          <FaCheck
                            className={`h-5 w-5 cursor-pointer rounded ${
                              editingIndex === index
                                ? "bg-purpleButton text-white"
                                : "bg-gray-100 text-gray-300"
                            }`}
                            onClick={() => handleSaveClick(index)}
                          />
                          <RiPencilLine
                            className={`h-5 w-5 cursor-pointer rounded ${
                              editingIndex !== index
                                ? "bg-purpleButton text-white"
                                : "bg-gray-100 text-gray-300"
                            }`}
                            onClick={() => handleEditClick(index)}
                          />
                        </>
                      )}
                    </div>
                  </td>
                  <td className="p-2">
                    <span
                      className={`px-4 py-1 rounded-2xl ${getStatusClass(
                        jobApplication.success === 1 ? 6 : jobApplication.status
                      )}
                      `}
                    >
                      {jobApplication.success === 2 ? (
                        "rejected"
                      ) : jobApplication.success === 1 ? (
                        "Hired"
                      ) : (
                        <>
                          {jobApplication.status === 0 && "pending"}
                          {jobApplication.status === 1 && "accepted"}
                          {jobApplication.status === 3 && "test taken"}
                          {jobApplication.status === 4 && "passed"}
                          {jobApplication.status === 5 && "contract signed"}
                        </>
                      )}
                    </span>
                  </td>
                  <td className="p-2 flex space-x-2">
                    <Link
                      to={`/employerDashboard/application/${jobApplication._id}`}
                      className="bg-gray-200 rounded"
                    >
                      <img src={profile} alt="profile" />
                    </Link>
                    <button
                      className="bg-gray-200 rounded"
                      onClick={() => handleDeleteClick(index)}
                    >
                      {loadingDeletes[index] ? (
                        <ButtonLoader /> // Show loader for the delete button
                      ) : (
                        <img src={deleteUI} alt="delete" />
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile */}
        <div className="flex sm:hidden flex-col">
          <table className="min-w-full text-xs lg:text-sm">
            <thead>
              <tr className="text-left text-gray-400">
                <th className="p-2">APPLICATION. NO</th>
                <th className="p-2">ACTION</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {filteredCandidates.map((jobApplication, index) => (
                <React.Fragment key={index}>
                  <tr className="border-t">
                    <td className="p-2 font-bold">
                      <div className="flex items-center">
                        {expandedRow === index ? (
                          <div className="flex items-center">
                            <div
                              className="cursor-pointer p-1 rounded-full bg-red-500 text-white"
                              onClick={() => handleRowExpand(index)}
                            >
                              <FiMinus />
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <div
                              className="cursor-pointer p-1 rounded-full bg-purpleButton text-white"
                              onClick={() => handleRowExpand(index)}
                            >
                              <GoPlus />
                            </div>
                          </div>
                        )}
                        <div className="ml-2">{jobApplication._id}</div>
                      </div>
                    </td>
                    <td className="p-2 flex space-x-2">
                      <Link
                        to={`/employerDashboard/application/${jobApplication._id}`}
                        className="bg-gray-200 rounded"
                      >
                        <img src={profile} alt="profile" />
                      </Link>
                      <button
                        className="bg-gray-200 rounded"
                        onClick={() => handleDeleteClick(index)}
                      >
                        {loadingDeletes[index] ? (
                          <ButtonLoader /> // Show loader for the delete button
                        ) : (
                          <img src={deleteUI} alt="delete" />
                        )}
                      </button>
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan={1} className="p-2">
                        <div className="flex flex-col space-y-2">
                          <div>
                            <textarea
                              value={comments[index] || jobApplication.note}
                              onChange={(e) =>
                                handleCommentChange(index, e.target.value)
                              }
                              className={`w-full h-20 p-2 border text-10px ${
                                editingIndex === index
                                  ? "border-gray-300"
                                  : "border-transparent"
                              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                              disabled={editingIndex !== index}
                            />
                            <div className="top-2 right-2 flex flex-col gap-2">
                              {loadingComments[index] ? (
                                <ButtonLoader /> // Show loader for the specific comment
                              ) : (
                                <>
                                  <FaCheck
                                    className={`h-5 w-5 cursor-pointer rounded ${
                                      editingIndex === index
                                        ? "bg-purpleButton text-white"
                                        : "bg-gray-100 text-gray-300"
                                    }`}
                                    onClick={() => handleSaveClick(index)}
                                  />
                                  <RiPencilLine
                                    className={`h-5 w-5 cursor-pointer rounded ${
                                      editingIndex !== index
                                        ? "bg-purpleButton text-white"
                                        : "bg-gray-100 text-gray-300"
                                    }`}
                                    onClick={() => handleEditClick(index)}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div>
                            <div>JOB REF. NO</div>
                            <div>
                              {generateConsistentAlphanumeric(
                                jobApplication.jobId
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="font-bold">CANDIDATE:</div>
                            <div>
                              {jobApplication.candidate.first_name}{" "}
                              {jobApplication.candidate.last_name}
                            </div>
                            <div className="text-gray-500">
                              {jobApplication.candidate.email}
                            </div>
                          </div>
                          <div className="flex flex-col text-left gap-1">
                            <div className="font-bold">STATUS:</div>
                            <div>
                              <span
                                className={`px-4 py-1 rounded-2xl items-center ${getStatusClass(
                                  jobApplication.success === 1
                                    ? 6
                                    : jobApplication.status
                                )}
                                `}
                              >
                                {jobApplication.success === 2 ? (
                                  "rejected"
                                ) : jobApplication.success === 1 ? (
                                  "Hired"
                                ) : (
                                  <>
                                    {jobApplication.status === 0 && "pending"}
                                    {jobApplication.status === 1 && "accepted"}
                                    {jobApplication.status === 3 &&
                                      "test taken"}
                                    {jobApplication.status === 4 && "passed"}
                                    {jobApplication.status === 5 &&
                                      "contract signed"}
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center sm:justify-between items-center mt-4 text-sm">
          <div className="hidden sm:flex items-center space-x-2">
            <select
              className="p-2 border rounded bg-gray-100"
              onChange={handleCandidatesPerPageChange}
              value={candidatesPerPage}
            >
              <option value="2">2</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <span>
              Showing{" "}
              {Math.min(
                pagination.currentPage * pagination.pageSize -
                  pagination.pageSize +
                  1,
                pagination.totalDocuments
              )}{" "}
              to{" "}
              {Math.min(
                pagination.currentPage * pagination.pageSize,
                pagination.totalDocuments
              )}{" "}
              of {pagination.totalDocuments} records
            </span>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => paginate(currentPage - 1)}
              className={`px-2 py-1 rounded ${
                currentPage === 1
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-gray-200 text-gray-600"
              }`}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <button
              key={pagination.currentPage}
              onClick={() => paginate(pagination.currentPage)}
              className={`px-3 py-1 rounded ${
                currentPage === pagination.currentPage
                  ? "bg-purpleButton text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
            >
              {pagination.currentPage}
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              className={`px-2 py-1 rounded ${
                currentPage === pagination.totalPages
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-gray-200 text-gray-600"
              }`}
              disabled={currentPage === pagination.totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default CandidateList;
