import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import { getAllJobsCandidates } from "../../services/apiService"; // Adjust the import path as necessary
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { generateConsistentAlphanumericfunction } from "../../functions/generateText";

const CandidateJobs = () => {
  const [jobsPerPage, setJobsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRow, setExpandedRow] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [totalPages, setTotalPages] = useState(1); // State to manage total pages
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const response = await dispatch(
          getAllJobsCandidates({ page: currentPage, limit: jobsPerPage })
        );

        setJobs(response.data.jobs); // Set jobs data
        setTotalPages(response.data.pagination.totalPages); // Set total pages from response
        setLoading(false);
      } catch (error) {
        toast.error("Failed to fetch jobs.");
        setLoading(false);
      }
    };

    fetchJobs();
  }, [currentPage, jobsPerPage, dispatch]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <div className="rounded-lg">
      <ToastContainer />
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <ButtonLoader />
        </div>
      ) : (
        <>
          <div className="hidden lg:flex flex-col">
            <table className="table-auto text-[10px]">
              <thead>
                <tr className="text-left text-[#a1a5b7]">
                  <th className="p-1 md:p-2">Date</th>
                  <th className="p-1 md:p-2">Ref.No</th>
                  <th className="p-1 md:p-2">Title</th>
                  <th className="p-1 md:p-2">Action</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {jobs.map((job, index) => (
                  <tr key={index} className="border-t">
                    <td className="p-1 md:p-2 text-[#2b354f]">
                      {new Date(job.createdAt).toLocaleDateString()}
                    </td>
                    <td className="p-1 md:p-2 text-[#2b354f]">
                      {generateConsistentAlphanumericfunction(job._id)}
                    </td>
                    <td className="p-1 md:p-2 flex flex-col w-32">
                      <div className="text-[#2b354f]">{job.title}</div>
                      <Link
                        to={`/jobApply/${job._id}`}
                        className="text-purpleButton ml-2 text-[10px]"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link to Apply
                      </Link>
                    </td>
                    <td className="p-1 md:p-2">
                      <Link
                        to={`/jobApply/${job._id}`}
                        className="text-purpleButton"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="sm:hidden flex flex-col">
            <table className="table-auto text-sm">
              <thead>
                <tr className="text-left text-[#a1a5b7]">
                  <th className="p-1 md:p-2">Date</th>
                  <th className="p-1 md:p-2">Action</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {jobs.map((job, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-t">
                      <td className="p-1 md:p-2 text-[#2b354f]">
                        {expandedRow === index ? (
                          <div className="flex items-center">
                            <div
                              className="cursor-pointer p-1 rounded-full bg-red-500"
                              onClick={() => handleRowExpand(index)}
                            >
                              <FiMinus className="text-white" />
                            </div>
                            <div className="ml-2">
                              {new Date(job.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <div
                              className="cursor-pointer p-1 rounded-full bg-purpleButton"
                              onClick={() => handleRowExpand(index)}
                            >
                              <GoPlus className="text-white" />
                            </div>
                            <div className="ml-2">
                              {new Date(job.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        )}
                      </td>
                      <td className="p-1 md:p-2">
                        <Link
                          to={`/jobApply/${job._id}`}
                          className="text-purpleButton"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan={2}>
                          <div className="flex flex-col space-y-2 p-2">
                            <div className="flex flex-col items-left">
                              <div className="font-bold">Ref.No:</div>
                              <div>
                                {generateConsistentAlphanumericfunction(
                                  job._id
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col items-left">
                              <div className="font-bold">Title:</div>
                              <div className="flex flex-col">
                                <div>{job.title}</div>
                                <Link
                                  to={`/jobApply/${job._id}`}
                                  className="text-purpleButton text-[10px]"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Link to Apply
                                </Link>
                              </div>
                            </div>
                            <div className="flex flex-col items-left">
                              <div className="font-bold">URL:</div>
                              <div>{job.URL}</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex flex-col sm:flex-row justify-between items-center mt-4 text-sm">
            <div className="hidden items-center space-x-2 sm:flex">
              <select
                className="p-2 border rounded bg-gray-100"
                onChange={(e) => setJobsPerPage(Number(e.target.value))}
                value={jobsPerPage}
              >
                <option value="2">2</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>
                Showing {(currentPage - 1) * jobsPerPage + 1} to{" "}
                {Math.min(currentPage * jobsPerPage, jobs.length)} of{" "}
                {jobs.length} records
              </span>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                className={`px-2 py-1 rounded ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
              </button>
              <button
                key={currentPage}
                onClick={() => paginate(currentPage)}
                className={`px-2 md:px-3 py-1 rounded ${
                  currentPage
                    ? "bg-purpleButton text-white"
                    : "bg-gray-200 text-gray-600"
                }`}
              >
                {currentPage}
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                className={`px-2 py-1 rounded ${
                  currentPage === totalPages
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={currentPage === totalPages}
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CandidateJobs;
