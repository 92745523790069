import { combineReducers } from "redux";
// employer
import authReducer_employer from "./employer/authReducer_employer";
import testBuilder_employer from "./employer/testBuilder_employer";
import jobsReducer_employer from "./employer/jobsReducer_employer";
import helpAndGuide_employer from "./employer/helpAndGuide_employer";
import packages_employer from "./employer/packages_employer";
import jobApplicationReducer_employer from "./employer/jobApplications_employer";
import jobApplicationSingleReducer_employer from "./employer/jobApplication_employer";
import testPermissionsReducer from "./all/testPermissionsReducer";
// candidate
import authReducer_candidate from "./candidate/authPeducer_candidate";
import jobApplicationReducer_candidate from "./candidate/jobApplications_candidate";
import helpAndGuide_candidate from "./candidate/helpAndGuide_candidate";
import jobApplicationSingleReducer_candidate from "./candidate/jobApplication_candidate";
// import other reducers here if any

const appReducer = combineReducers({
  // employer
  authReducer_Employer: authReducer_employer,
  testBuilder_Employer: testBuilder_employer,
  jobsReducer_Employer: jobsReducer_employer,
  helpAndGuide_Employer: helpAndGuide_employer,
  packages_Employer: packages_employer,
  jobApplicationReducer_Employer: jobApplicationReducer_employer,
  jobApplicationSingleReducer_Employer: jobApplicationSingleReducer_employer,
  testPermissionsReducer,
  // canduidate
  authReducer_candidate,
  jobApplicationReducer_candidate,
  helpAndGuide_candidate: helpAndGuide_candidate,
  jobApplicationSingleReducer_candidate,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
