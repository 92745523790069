import React, { useEffect, useState, useCallback } from "react";
import { getAllJobs, deleteJob, editJob } from "../../services/apiService";
import { toast, ToastContainer } from "react-toastify";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import copyIcon from "../../images/jobsEmployer/copy.png";
import editIcon from "../../images/jobsEmployer/edit.png";
import deleteIcon from "../../images/jobsEmployer/delete.png";
import { Link } from "react-router-dom";
import ToggleButton from "../../component/buttons/ToggleButton";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import ConfirmationModal from "../../component/confirm/ConfirmationModel";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const paginateArray = (array, pageSize, currentPage) => {
  const startIndex = (currentPage - 1) * pageSize;
  return array.slice(startIndex, startIndex + pageSize);
};

const JobList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const jobs = useSelector((state) => state.jobsReducer_Employer.jobs);
  const pagination = useSelector(
    (state) => state.jobsReducer_Employer.pagination
  );

  const [expandedRow, setExpandedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deletingJobId, setDeletingJobId] = useState(null);
  const [displayedJobs, setDisplayedJobs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null); // Store job to delete

  const fetchJobs = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        getAllJobs({ page: pagination.currentPage, limit: pagination.limit })
      );
      toast.success("Jobs fetched successfully!");
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, pagination.currentPage, pagination.limit]);

  useEffect(() => {
    if (!jobs.length) {
      fetchJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.showToast) {
      toast.success(location.state.showToast);
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location.state, location.pathname, navigate]);

  useEffect(() => {
    setDisplayedJobs(
      paginateArray(jobs, pagination.limit, pagination.currentPage)
    );
  }, [jobs, pagination.currentPage, pagination.limit]);

  const paginate = (pageNumber) => {
    dispatch({
      type: "EDIT_PAGINATION_JOBS",
      payload: {
        pagination: {
          currentPage: pageNumber,
          limit: Number(pagination.limit),
          totalPages: Math.ceil(
            pagination.totalJobs / Number(pagination.limit)
          ),
          totalJobs: pagination.totalJobs,
        },
      },
    });
  };

  const handleJobsPerPageChange = (event) => {
    const newLimit = Number(event.target.value);
    const newTotalPages = Math.ceil(pagination.totalJobs / newLimit);
    const newCurrentPage = Math.min(pagination.currentPage, newTotalPages);

    dispatch({
      type: "EDIT_PAGINATION_JOBS",
      payload: {
        pagination: {
          currentPage: newCurrentPage,
          limit: newLimit,
          totalPages: newTotalPages,
          totalJobs: pagination.totalJobs,
        },
      },
    });
  };

  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleToggleStatus = async (index) => {
    dispatch({
      type: "EDIT_JOB",
      payload: {
        _id: jobs[index]._id,
        status: !jobs[index].status,
      },
    });
    async function editJobProcess() {
      const job = jobs[index];
      try {
        await dispatch(
          editJob({
            ...job,
            status: !job.status,
          })
        );
        toast.success(`Update status success`);
      } catch (error) {
        toast.error(`Error updating status`);
        dispatch({
          type: "EDIT_JOB",
          payload: {
            _id: jobs[index]._id,
            status: !jobs[index].status,
          },
        });
      }
    }
    editJobProcess();
  };

  const handleTogglePrivatePublic = (index) => {
    dispatch({
      type: "EDIT_JOB",
      payload: {
        _id: jobs[index]._id,
        privateOrPublic: !jobs[index].privateOrPublic,
      },
    });
    async function editJobProcess() {
      const job = jobs[index];
      try {
        await dispatch(
          editJob({
            ...job,
            privateOrPublic: !job.privateOrPublic,
          })
        );
        toast.success(`Update private/public status success`);
      } catch (error) {
        toast.error(`Error updating private/public status`);
        dispatch({
          type: "EDIT_JOB",
          payload: {
            _id: jobs[index]._id,
            privateOrPublic: !jobs[index].privateOrPublic,
          },
        });
      }
    }
    editJobProcess();
  };

  const handleCopyUrl = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Job post URL copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy URL:", error);
        toast.error("Failed to copy URL. Please try again.");
      });
  };

  const handleDeleteJob = async () => {
    setDeletingJobId(jobToDelete);
    try {
      setIsModalOpen(false); // Close modal after deletion
      await dispatch(deleteJob({ id: jobToDelete }));
      toast.success("Job deleted successfully!");
    } catch (error) {
      console.error("Error deleting job:", error);
      toast.error(
        `Error deleting job: ${
          error.response?.data?.message || "An error occurred"
        }`
      );
    } finally {
      setDeletingJobId(null);
    }
  };

  const openModal = (jobId) => {
    setJobToDelete(jobId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setJobToDelete(null);
  };

  return (
    <div className="rounded-lg">
      <ToastContainer />
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDeleteJob}
        question="Are you sure you want to delete this job?"
      />
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
        <input
          type="text"
          placeholder="Search Jobs"
          className="p-2 border rounded bg-gray-100 invisible"
        />
        <Link
          to="/employerDashboard/job"
          className="px-4 py-2 bg-purpleButton text-white rounded"
        >
          + New Job
        </Link>
      </div>
      {loading ? (
        <ButtonLoader />
      ) : (
        <>
          {/* Desktop View */}
          <div className="hidden lg:flex flex-col">
            <table className="table-auto text-[10px]">
              <thead>
                <tr className="text-left text-[#a1a5b7]">
                  <th className="p-1 md:p-2">DATE</th>
                  <th className="p-1 md:p-2">REF. NO</th>
                  <th className="p-1 md:p-2">TITLE</th>
                  <th className="p-1 md:p-2">APPLICATIONS</th>
                  <th className="p-1 md:p-2">TEST COMPLETED</th>
                  <th className="p-1 md:p-2">CONTRACT SIGN.</th>
                  <th className="p-1 md:p-2">URL</th>
                  <th className="p-1 md:p-2">COPY JOB POST URL</th>
                  <th className="p-1 md:p-2">STATUS</th>
                  <th className="p-1 md:p-2">PRIVATE/PUBLIC</th>
                  <th className="p-1 md:p-2">ACTION</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {displayedJobs.map((job, index) => (
                  <tr key={index} className="border-t">
                    <td className="p-1 md:p-2 text-[#2b354f]">
                      {new Date(job.createdAt).toLocaleDateString()}
                    </td>
                    <td className="p-1 md:p-2 text-[#2b354f]">{job._id}</td>
                    <td className="p-1 md:p-2 flex flex-col w-32">
                      <div className="text-[#2b354f]">{job.title}</div>
                      <Link
                        to={`/jobApply/${job._id}`}
                        className="text-purpleButton ml-2 text-[10px]"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link to Apply
                      </Link>
                    </td>
                    <td className="p-1 md:p-2">{job.applications || 0}</td>
                    <td className="p-1 md:p-2">{job.testCompleted || 0}</td>
                    <td className="p-1 md:p-2">{job.contractSigned || 0}</td>
                    <td className="p-1 md:p-2 text-[#2b354f] cursor-pointer max-w-24 break-words">
                      {`${process.env.REACT_APP_BASE_URL}/jobApply/${job._id}`}
                    </td>
                    <td className="flex text-center justify-center">
                      <button
                        className="p-1 bg-slate-200 flex"
                        onClick={() =>
                          handleCopyUrl(
                            `${process.env.REACT_APP_BASE_URL}/jobApply/${job._id}`
                          )
                        }
                      >
                        <img src={copyIcon} alt="copy" />
                      </button>
                    </td>
                    <td className="p-1 md:p-2">
                      <ToggleButton
                        toggled={job.status}
                        onToggle={() => handleToggleStatus(index)}
                      />
                    </td>
                    <td className="p-1 md:p-2">
                      <ToggleButton
                        toggled={job.privateOrPublic}
                        onToggle={() => handleTogglePrivatePublic(index)}
                      />
                    </td>
                    <td className="p-1 md:p-2 flex space-x-1 md:space-x-2">
                      <Link
                        to={`/employerDashboard/job/`}
                        className="p-1 bg-slate-200"
                        state={{ job }}
                      >
                        <img src={editIcon} alt="edit" />
                      </Link>
                      <button
                        className="p-1 bg-slate-200"
                        onClick={() => openModal(job._id)}
                        disabled={deletingJobId === job._id}
                      >
                        {deletingJobId === job._id ? (
                          <ButtonLoader size="small" />
                        ) : (
                          <img src={deleteIcon} alt="delete" />
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Medium Screen View */}
          <div className="hidden sm:flex lg:hidden flex-col">
            <table className="table-auto text-sm">
              <thead>
                <tr className="text-left text-[#a1a5b7]">
                  <th className="p-1 md:p-2">DATE</th>
                  <th className="p-1 md:p-2">URL</th>
                  <th className="p-1 md:p-2">STATUS</th>
                  <th className="p-1 md:p-2">PRIVATE/PUBLIC</th>
                  <th className="p-1 md:p-2">ACTION</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {displayedJobs.map((job, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-t">
                      <td className="p-1 md:p-2 text-[#2b354f]">
                        {expandedRow === index ? (
                          <div className="flex items-center">
                            <div
                              className="cursor-pointer p-1 rounded-full bg-red-500"
                              onClick={() => handleRowExpand(index)}
                            >
                              <FiMinus className="text-white" />
                            </div>
                            <div className="ml-2">{job.createdAt}</div>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <div
                              className="cursor-pointer p-1 rounded-full bg-purpleButton"
                              onClick={() => handleRowExpand(index)}
                            >
                              <GoPlus className="text-white" />
                            </div>
                            <div className="ml-2">
                              {new Date(job.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        )}
                      </td>
                      <td className="p-1 md:p-2 cursor-pointer max-w-24 break-words">{`${process.env.REACT_APP_BASE_URL}/jobApply/${job._id}`}</td>
                      <td className="p-1 md:p-2">
                        <ToggleButton
                          toggled={job.status}
                          onToggle={() => handleToggleStatus(index)}
                        />
                      </td>
                      <td className="p-1 md:p-2">
                        <ToggleButton
                          toggled={job.privateOrPublic}
                          onToggle={() => handleTogglePrivatePublic(index)}
                        />
                      </td>
                      <td className="p-1 md:p-2 flex space-x-1 md:space-x-2">
                        <Link
                          to={`/employerDashboard/job/`}
                          className="p-1 bg-slate-200"
                          state={{ job }}
                        >
                          <img src={editIcon} alt="edit" />
                        </Link>
                        <button
                          className="p-1 bg-slate-200"
                          onClick={() => openModal(job._id)}
                          disabled={deletingJobId === job._id}
                        >
                          {deletingJobId === job._id ? (
                            <ButtonLoader size="small" />
                          ) : (
                            <img src={deleteIcon} alt="delete" />
                          )}
                        </button>
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan={5}>
                          <div className="flex flex-col space-y-2 p-2">
                            <div className="flex flex-col items-left">
                              <div className="font-bold w-32">REF. NO:</div>
                              <div>{job._id}</div>
                            </div>
                            <td className="flex flex-col">
                              <div className="font-bold w-32">
                                COPY JOB POST URL:
                              </div>
                              <button
                                className="p-1 flex"
                                onClick={() =>
                                  handleCopyUrl(
                                    `${process.env.REACT_APP_BASE_URL}/jobApply/${job._id}`
                                  )
                                }
                              >
                                <img src={copyIcon} alt="copy" />
                              </button>
                            </td>
                            <div className="flex flex-col items-left">
                              <div className="font-bold w-32">TITLE:</div>
                              <div className="flex flex-col">
                                <div>{job.title}</div>
                                <Link
                                  to={`/jobApply/${job._id}`}
                                  className="text-purpleButton ml-2 text-[10px]"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Link to Apply
                                </Link>
                              </div>
                            </div>
                            <div className="flex flex-col items-left">
                              <div className="font-bold w-32">
                                APPLICATIONS:
                              </div>
                              <div>{job.applications || 0}</div>
                            </div>
                            <div className="flex flex-col items-left">
                              <div className="font-bold w-32">
                                TEST COMPLETED:
                              </div>
                              <div>{job.testCompleted || 0}</div>
                            </div>
                            <div className="flex flex-col items-left">
                              <div className="font-bold w-32">
                                CONTRACT SIGN:
                              </div>
                              <div>{job.contractSigned || 0}</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          {/* Small Mobile Screen View */}
          <div className="flex sm:hidden flex-col">
            <table className="table-auto text-sm">
              <thead>
                <tr className="text-left text-[#a1a5b7]">
                  <th className="p-1 md:p-2">DATE</th>
                  <th className="p-1 md:p-2">ACTION</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {displayedJobs.map((job, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-t">
                      <td className="p-1 md:p-2 text-[#2b354f]">
                        {expandedRow === index ? (
                          <div className="flex items-center">
                            <div
                              className="cursor-pointer p-1 rounded-full bg-red-500"
                              onClick={() => handleRowExpand(index)}
                            >
                              <FiMinus className="text-white" />
                            </div>
                            <div className="ml-2">
                              {new Date(job.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <div
                              className="cursor-pointer p-1 rounded-full bg-purpleButton"
                              onClick={() => handleRowExpand(index)}
                            >
                              <GoPlus className="text-white" />
                            </div>
                            <div className="ml-2">{job.createdAt}</div>
                          </div>
                        )}
                      </td>
                      <td className="p-1 md:p-2 flex space-x-1 md:space-x-2">
                        <Link
                          to={`/employerDashboard/job/`}
                          className="p-1 bg-slate-200"
                          state={{ job }}
                        >
                          <img src={editIcon} alt="edit" />
                        </Link>
                        <button
                          className="p-1 bg-slate-200"
                          onClick={() => openModal(job._id)}
                          disabled={deletingJobId === job._id}
                        >
                          {deletingJobId === job._id ? (
                            <ButtonLoader size="small" />
                          ) : (
                            <img src={deleteIcon} alt="delete" />
                          )}
                        </button>
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan={2}>
                          <div className="flex flex-col space-y-2 p-2">
                            <div className="flex flex-col items-left">
                              <div className="font-bold w-32">REF. NO:</div>
                              <div>{job._id}</div>
                            </div>
                            <div className="flex flex-col items-left">
                              <div className="font-bold w-32">TITLE:</div>
                              <div className="flex flex-col">
                                <div>{job.title}</div>
                                <Link
                                  to={`/jobApply/${job._id}`}
                                  className="text-purpleButton ml-2 text-[10px]"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Link to Apply
                                </Link>
                              </div>
                            </div>
                            <div className="flex flex-col items-left">
                              <div className="font-bold w-32">
                                APPLICATIONS:
                              </div>
                              <div>{job.applications || 0}</div>
                            </div>
                            <div className="flex flex-col items-left">
                              <div className="font-bold w-32">
                                TEST COMPLETED:
                              </div>
                              <div>{job.testCompleted || 0}</div>
                            </div>
                            <div className="flex flex-col items-start">
                              <div>URL</div>
                              <div className="p-1 md:p-2 max-w-24 break-words">{`${process.env.REACT_APP_BASE_URL}/jobApply/${job._id}`}</div>
                            </div>
                            <td className="flex flex-col">
                              <div className="font-bold w-32">
                                COPY JOB POST URL:
                              </div>
                              <button
                                className="p-1 flex"
                                onClick={() =>
                                  handleCopyUrl(
                                    `${process.env.REACT_APP_BASE_URL}/jobApply/${job._id}`
                                  )
                                }
                              >
                                <img src={copyIcon} alt="copy" />
                              </button>
                            </td>
                            <div className="flex flex-col">
                              <div>STATUS</div>
                              <div className="p-1 md:p-2">
                                <ToggleButton
                                  toggled={job.status}
                                  onToggle={() => handleToggleStatus(index)}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div>PRIVATE/PUBLIC</div>
                              <div className="p-1 md:p-2">
                                <ToggleButton
                                  toggled={job.privateOrPublic}
                                  onToggle={() =>
                                    handleTogglePrivatePublic(index)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex flex-col sm:flex-row justify-between items-center mt-4 text-sm">
            <div className="hidden items-center space-x-2 sm:flex">
              <select
                className="p-2 border rounded bg-gray-100"
                onChange={handleJobsPerPageChange}
                value={pagination.limit}
              >
                <option value="2">2</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>
                Showing {(pagination.currentPage - 1) * pagination.limit + 1} to{" "}
                {Math.min(
                  pagination.currentPage * pagination.limit,
                  pagination.totalJobs
                )}{" "}
                of {pagination.totalJobs} records
              </span>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => paginate(pagination.currentPage - 1)}
                className={`px-2 py-1 rounded ${
                  pagination.currentPage === 1
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={pagination.currentPage === 1}
              >
                <FaChevronLeft />
              </button>
              <button
                key={pagination.currentPage}
                onClick={() => paginate(pagination.currentPage)}
                className={`px-2 md:px-3 py-1 rounded ${
                  pagination.currentPage
                    ? "bg-purpleButton text-white"
                    : "bg-gray-200 text-gray-600"
                }`}
              >
                {pagination.currentPage}
              </button>
              <button
                onClick={() => paginate(pagination.currentPage + 1)}
                className={`px-2 py-1 rounded ${
                  pagination.currentPage === pagination.totalPages
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={pagination.currentPage === pagination.totalPages}
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default JobList;
