import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyPayment } from "../../services/apiService"; // API service to verify payment
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai"; // Import icons

const SubscriptionSuccess = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const verifySubscription = async () => {
      if (sessionId) {
        try {
          // Adding delay to wait for the webhook response (simulated polling)
          await new Promise((resolve) => setTimeout(resolve, 3000));

          const response = await dispatch(verifyPayment(sessionId));
          setSuccessMessage(
            response.message || "Subscription completed successfully!"
          );
        } catch (error) {
          setErrorMessage("Failed to verify payment.");
        } finally {
          setLoading(false);
        }
      } else {
        setErrorMessage("No session ID found.");
        setLoading(false);
      }
    };

    verifySubscription();
  }, [sessionId, dispatch]);

  const handleButtonClick = () => {
    navigate("/PurchaseCredits");
  };

  if (loading) {
    return <ButtonLoader />;
  }

  return (
    <div className="p-8 flex flex-col items-center justify-center min-h-screen bg-gray-50">
      {successMessage ? (
        <div className="text-center">
          <AiOutlineCheckCircle className="text-green-500 text-6xl mb-4" />
          <h2 className="text-green-500 text-2xl font-bold mb-4">
            {successMessage}
          </h2>
          <button
            onClick={handleButtonClick}
            className="bg-purpleButton text-white px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300"
          >
            Go to Purchase Credits
          </button>
        </div>
      ) : (
        <div className="text-center">
          <AiOutlineCloseCircle className="text-red-500 text-6xl mb-4" />
          <h2 className="text-red-500 text-2xl font-bold mb-4">
            {errorMessage}
          </h2>
        </div>
      )}
    </div>
  );
};

export default SubscriptionSuccess;
