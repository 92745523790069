import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import { getEmployerDashboardInfo } from "../../services/apiService"; // Adjust the import path as necessary
import { toast, ToastContainer } from "react-toastify";

// images
import Active from "../../images/dashboard/Active.png";
import Private from "../../images/dashboard/Private.png";
import Public from "../../images/dashboard/Public.png";
import Total from "../../images/dashboard/Total.png";
import RejectedPurple from "../../images/dashboard/RejectedPurple.png";
import TestPurple from "../../images/dashboard/TestPurple.png";
import TotalPurple from "../../images/dashboard/TotalPurple.png";
import ContractPurple from "../../images/dashboard/ContractsPurple.png";
import { useDispatch } from "react-redux";

const EmployerMain = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true); // Start loading
        const response = await dispatch(getEmployerDashboardInfo());
        setDashboardData(response.data);
      } catch (error) {
        toast.error("Failed to fetch dashboard data.");
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchDashboardData();
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <div className="items-center justify-center">
      <ToastContainer />
      <div className="flex gap-3">
        <Link
          to="/employerDashboard/job"
          className="bg-greenButton text-white py-2 px-3 w-full sm:w-auto rounded"
        >
          Post New Job
        </Link>
        <Link
          to="/PurchaseCredits"
          className="bg-purpleButton text-white py-2 px-3 w-full sm:w-auto rounded"
        >
          Purchase Credits
        </Link>
      </div>
      <div className="flex flex-col md:flex-row py-4 gap-4 ">
        <div className="flex space-x-4 w-full flex-col md:flex-row max-w-[350px]">
          {/* Jobs Summary */}
          <Link
            to="/employerDashboard/jobs?status=all"
            className="bg-[#AEEBE7] rounded-lg shadow-md p-6 flex-1"
          >
            <h2 className="text-xl font-semibold mb-4 text-[#00C9B7]">
              Jobs Summary
            </h2>
            <div className="flex flex-col gap-7">
              <div className="flex">
                <div className="flex flex-row items-center mb-4 gap-4 w-[50%]">
                  <div className="bg-white w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-lg">
                    <img src={Total} alt="totalJobs" />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xl font-bold">
                      {dashboardData?.jobs?.total || 0}
                    </span>
                    <span className="text-[12px] text-[#00C9B7]">
                      Total Jobs
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center mb-4 gap-4 w-[50%]">
                  <div className="bg-white w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-lg">
                    <img src={Active} alt="activeJobs" />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xl font-bold">
                      {dashboardData?.jobs?.active || 0}
                    </span>
                    <span className="text-[12px] text-[#00C9B7]">
                      Active Jobs
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-row items-center mb-4 gap-4 w-[50%]">
                  <div className="bg-white w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-lg">
                    <img src={Public} alt="publicJobs" />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xl font-bold">
                      {dashboardData?.jobs?.public || 0}
                    </span>
                    <span className="text-[12px] text-[#00C9B7]">
                      Public Jobs
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center mb-4 gap-4 w-[50%]">
                  <div className="bg-white w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-lg">
                    <img src={Private} alt="privateJobs" />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xl font-bold">
                      {dashboardData?.jobs?.private || 0}
                    </span>
                    <span className="text-[12px] text-[#00C9B7]">
                      Private Jobs
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="flex space-x-4 w-full flex-col md:flex-row max-w-[350px]">
          <Link
            to="/employerDashboard/candidates?status=all"
            className="bg-[#c8befc] rounded-lg shadow-md p-6 flex-1"
          >
            <h2 className="text-xl font-semibold mb-3 text-[#433d8b]">
              Candidates Summary
            </h2>
            <div className="flex">
              <div className="flex flex-row items-center mb-4 gap-4 w-[50%]">
                <div className="w-[50px]">
                  <div className="bg-white w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-lg">
                    <img src={TotalPurple} alt="totalApplications" />
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-xl font-bold">
                    {dashboardData?.jobApplications?.total || 0}
                  </span>
                  <span className="text-[12px] text-[#433d8b]">
                    Total Applications
                  </span>
                </div>
              </div>
              <div className="flex flex-row items-center mb-4 gap-4 w-[50%]">
                <div className="w-[50px]">
                  <div className="bg-white w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-lg">
                    <img src={TestPurple} alt="testCompleted" />
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-xl font-bold">
                    {dashboardData?.jobApplications?.testCompleted || 0}
                  </span>
                  <span className="text-[12px] text-[#433d8b]">
                    Test Completed
                  </span>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-row items-center mb-4 gap-4 w-[50%]">
                <div className="w-[50px]">
                  <div className="bg-white w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-lg">
                    <img src={ContractPurple} alt="contractsSigned" />
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-xl font-bold">
                    {dashboardData?.jobApplications?.contractSigned || 0}
                  </span>
                  <span className="text-[12px] text-[#433d8b]">
                    Contract Signed
                  </span>
                </div>
              </div>
              <div className="flex flex-row items-center mb-4 gap-4 w-[50%]">
                <div className="w-[50px]">
                  <div className="bg-white w-8 h-8 sm:w-12 sm:h-12 flex items-center justify-center rounded-lg">
                    <img src={RejectedPurple} alt="applicationsRejected" />
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-xl font-bold">
                    {dashboardData?.jobApplications?.rejected || 0}
                  </span>
                  <span className="text-[12px] text-[#433d8b]">
                    Applications Rejected
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmployerMain;
