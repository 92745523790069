import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import employer from "../../images/auth/employer.png";
import candidate from "../../images/auth/candidate.png";
import rightArrowIs from "../../images/auth/rightArrowIs.png";
import BackgroundAuth from "../../component/auth/BackgroundAuth";
import OTPInput from "../../component/auth/OTPbox";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import {
  getProfileEmployer,
  loginEmployer,
  sendForgetOTP,
  verifyOTP,
  setPasswordReset,
  loginCandidate,
  getCandidateProfile,
  sendForgetOTPCandidate,
  verifyOTPCandidate,
  setPasswordResetCandidate,
} from "../../services/apiService"; // Ensure to import these functions

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [confirmedPasswordVisible, setConfirmedPasswordVisible] =
    useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [otp, setOtp] = useState(""); // State for OTP
  const [otpLoading, setOtpLoading] = useState(false);
  const location = useLocation();
  const initialRole = location.state?.role || "employer";
  const [selectedRole, setSelectedRole] = useState(initialRole);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRole === "employer") {
      const storedEmail = localStorage.getItem("employerEmail");
      if (storedEmail !== "" && storedEmail) {
        setEmail(storedEmail);
      }
    } else if (selectedRole === "candidate") {
      const storedEmail = localStorage.getItem("candidateEmail");
      if (storedEmail !== "" && storedEmail) {
        setEmail(storedEmail);
      }
    } else {
      localStorage.removeItem("employerEmail");
      localStorage.removeItem("candidateEmail");
      setEmail("");
      setRememberMe(false);
      setPassword("");
    }
  }, [selectedRole]); // This useEffect will only run when selectedRole changes

  useEffect(() => {
    if (rememberMe && email) {
      if (selectedRole === "employer") {
        localStorage.setItem("employerEmail", email);
      } else if (selectedRole === "candidate") {
        localStorage.setItem("candidateEmail", email);
      }
    }
  }, [rememberMe, email, selectedRole]); // This useEffect will only run when rememberMe, email, or selectedRole changes

  useEffect(() => {
    const checkProfile = async () => {
      const profile = await dispatch(getProfileEmployer());
      if (profile) {
        navigate("/employerDashboard/main");
      }
      const candidateProfile = await dispatch(getCandidateProfile());
      if (candidateProfile) {
        navigate("/candidateDashboard/main");
      }
      setLoading(false);
    };

    checkProfile();
  }, [selectedRole, navigate, dispatch]); //

  const enterRegisteredEmail = () => {
    setStep(1);
  };

  const handleSendOtp = async () => {
    setOtpLoading(true);
    try {
      if (selectedRole === "employer") {
        await dispatch(sendForgetOTP({ email }));
        setStep(2);
        toast.success("OTP sent to your email.");
      }
      if (selectedRole === "candidate") {
        await dispatch(sendForgetOTPCandidate({ email }));
        setStep(2);
        toast.success("OTP sent to your email.");
      }
    } catch (error) {
      toast.error("Error sending OTP. Try again.");
    } finally {
      setOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setOtpLoading(true);
    try {
      if (selectedRole === "employer") {
        await dispatch(verifyOTP({ email, otp }));
        setStep(3);
        toast.success("OTP verified.");
      }

      if (selectedRole === "candidate") {
        await dispatch(verifyOTPCandidate({ email, otp }));
        setStep(3);
        toast.success("OTP verified.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setOtpLoading(false);
    }
  };

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const maxLength = 20;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength || password.length > maxLength) {
      return `Password must be between ${minLength}-${maxLength} characters.`;
    }
    if (!hasUpperCase) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowerCase) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasNumbers) {
      return "Password must contain at least one number.";
    }
    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }
    return null; // Return null if the password is valid
  };

  const handlePasswordChange = async () => {
    const validationError = validatePassword(confirmPassword);
    if (validationError) {
      toast.error(validationError);
      return;
    }

    if (confirmPassword !== confirmedPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    try {
      if (selectedRole === "employer") {
        await dispatch(
          setPasswordReset({ email, otp, password: confirmPassword })
        );
        setStep(0);
        toast.success("Password reset successfully.");
      }
      if (selectedRole === "candidate") {
        await dispatch(
          setPasswordResetCandidate({ email, otp, password: confirmPassword })
        );
        setStep(0);
        toast.success("Password reset successfully.");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleLogin = async () => {
    setButtonLoading(true);
    try {
      const payload = { email, password };
      let response;
      if (selectedRole === "employer") {
        response = await dispatch(loginEmployer(payload));
        if (response) {
          if (rememberMe) {
            localStorage.setItem("email", email);
            localStorage.setItem("role", selectedRole);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("role");
          }
          navigate("/employerDashboard/main");
        }
      } else if (selectedRole === "candidate") {
        response = await dispatch(loginCandidate(payload));
        if (response) {
          if (rememberMe) {
            localStorage.setItem("email", email);
            localStorage.setItem("role", selectedRole);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("role");
          }
          navigate("/candidateDashboard/main");
        }
      } else {
        toast.error("Please select a role.");
        return;
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setButtonLoading(false);
    }
  };
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col sm:flex-row">
      <ToastContainer position="top-right" autoClose={5000} />
      <BackgroundAuth />
      <div className="w-full sm:w-[55%] p-8 sm:p-16 h-full sm:overflow-scroll">
        {step === 0 && (
          <div className="md:max-w-[80%] flex flex-col justify-between h-full">
            <div className="font-bold text-[30px]">Login</div>
            <div>
              Don't have an account?{" "}
              <Link
                to="/signup"
                state={{ role: selectedRole }}
                className="text-[#5533ff] font-bold"
              >
                create it here
              </Link>
            </div>
            <div className="mt-2">Please select your role</div>
            <div className="flex mt-2 justify-between gap-2 sm:justify-normal sm:gap-4">
              <div
                className={`border rounded-lg p-4 w-52 h-32 flex flex-col justify-center items-center relative ${
                  selectedRole === "employer" ? "border-blue-500" : ""
                }`}
                onClick={() => setSelectedRole("employer")}
              >
                <img src={employer} alt="Employer" className="w-16 mb-2" />
                <span className="text-gray-700">Employer</span>
                <input
                  type="radio"
                  name="selection"
                  className="absolute top-2 right-2 border border-gray-300 rounded-full w-4 h-4"
                  checked={selectedRole === "employer"}
                  onChange={() => setSelectedRole("employer")}
                />
              </div>
              <div
                className={`border rounded-lg p-4 w-52 h-32 flex flex-col justify-center items-center relative ${
                  selectedRole === "candidate" ? "border-blue-500" : ""
                }`}
                onClick={() => setSelectedRole("candidate")}
              >
                <img src={candidate} alt="Candidate" className="w-16 mb-2" />
                <span className="text-gray-700">Candidate</span>
                <input
                  type="radio"
                  name="selection"
                  className="absolute top-2 right-2 border border-gray-300 rounded-full w-4 h-4"
                  checked={selectedRole === "candidate"}
                  onChange={() => setSelectedRole("candidate")}
                />
              </div>
            </div>
            <div className="mt-2">
              <div>
                <div>Email*</div>
                <input
                  type="email"
                  placeholder="enter email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (rememberMe) {
                      if (selectedRole === "employer") {
                        localStorage.setItem("employerEmail", e.target.value);
                      } else if (selectedRole === "candidate") {
                        localStorage.setItem("candidateEmail", e.target.value);
                      }
                    }
                  }}
                  className={`p-2 w-full rounded-2xl mt-1 ${
                    email ? "bg-slate-100" : "bg-white"
                  } border-2`}
                />
              </div>
              <div className="relative mt-4">
                <div className="flex justify-between">Password*</div>
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={`p-2 w-full rounded-2xl mt-1 ${
                    password ? "bg-slate-100" : "bg-white"
                  } border-2`}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0  top-[45%] mr-4   flex items-center text-sm leading-5"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? (
                    <FaRegEyeSlash className="w-5 h-5 text-gray-700" />
                  ) : (
                    <FaEye className="w-5 h-5 text-gray-700" />
                  )}
                </button>
              </div>
            </div>
            <div className="flex gap-2 mt-2">
              <input
                type="checkbox"
                className="inline"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <div>Remember me</div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div
                onClick={enterRegisteredEmail}
                className="cursor-pointer text-blue-500"
              >
                Forgot your Password?
              </div>
              <button
                onClick={handleLogin}
                className={`bg-[#5533ff] w-24 text-white text-center p-2 rounded-full ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {buttonLoading ? (
                  <ButtonLoader /> // Use the ButtonLoader component
                ) : (
                  <>
                    Login{" "}
                    <img
                      src={rightArrowIs}
                      alt="right arrow"
                      className="inline w-5"
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        )}
        {step === 1 && (
          <div>
            <div>
              <div className="font-semibold text-[50px]">Forgot your</div>
              <div className="font-semibold text-[50px]">Password?</div>
              <div className="my-4">
                No problem. Just let us know your email address and we will
                email you a password reset link that will allow you to choose a
                new one.
              </div>
              <div className="my-7">
                <div>Enter your email below to retrieve your account or </div>
                <div className="text-[#5533ff] font-bold">Login</div>
              </div>
              <input
                type="email"
                placeholder="Your registered email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`p-2 w-full rounded-2xl mt-1 ${
                  email ? "bg-slate-100" : "bg-white"
                } border-2`}
              />
            </div>
            <div className="flex justify-end my-7">
              <button
                onClick={handleSendOtp}
                className={`bg-[#5533ff] text-white p-2 w-28 items-center rounded-2xl flex justify-around ${
                  otpLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={otpLoading}
              >
                {otpLoading ? (
                  <ButtonLoader /> // Use the ButtonLoader component
                ) : (
                  <>
                    Send OTP
                    <img
                      src={rightArrowIs}
                      alt="rightarrow"
                      className="inline w-5"
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            <div className="flex justify-center font-semibold text-[45px]">
              Verification
            </div>
            <div className="flex justify-center my-5">
              Please enter the OTP code sent to your email
            </div>
            <div className="flex justify-center">
              <OTPInput length={5} onChangeOTP={handleOtpChange} />
            </div>
            <div className="flex justify-center my-7">
              <button
                onClick={handleVerifyOtp}
                className={`bg-[#5533ff] text-white p-2 w-32 items-center rounded-full flex justify-around ${
                  otpLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={otpLoading}
              >
                {otpLoading ? (
                  <ButtonLoader /> // Use the ButtonLoader component
                ) : (
                  <>
                    Verify OTP
                    <img
                      src={rightArrowIs}
                      alt="rightarrow"
                      className="inline w-5"
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div>
            <div className="font-semibold text-[50px]">Reset your</div>
            <div className="font-semibold text-[50px]">Password</div>
            <div className="relative mt-4">
              <div className="flex justify-between">New Password</div>
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                placeholder="enter password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={`p-2 w-full rounded-2xl mt-1 ${
                  confirmPassword ? "bg-slate-100" : "bg-white"
                } border-2`}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 top-[45%] mr-4 flex items-center text-sm leading-5"
                onClick={() =>
                  setConfirmPasswordVisible(!confirmPasswordVisible)
                }
              >
                {confirmPasswordVisible ? (
                  <FaRegEyeSlash className="w-5 h-5 text-gray-700" />
                ) : (
                  <FaEye className="w-5 h-5 text-gray-700" />
                )}
              </button>
            </div>
            <div className="relative mt-4">
              <div className="flex justify-between">Confirm New Password</div>
              <input
                type={confirmedPasswordVisible ? "text" : "password"}
                placeholder="confirm password"
                value={confirmedPassword}
                onChange={(e) => setConfirmedPassword(e.target.value)}
                className={`p-2 w-full rounded-2xl mt-1 ${
                  confirmedPassword ? "bg-slate-100" : "bg-white"
                } border-2`}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 top-[45%] mr-4 flex items-center text-sm leading-5"
                onClick={() =>
                  setConfirmedPasswordVisible(!confirmedPasswordVisible)
                }
              >
                {confirmedPasswordVisible ? (
                  <FaRegEyeSlash className="w-5 h-5 text-gray-700" />
                ) : (
                  <FaEye className="w-5 h-5 text-gray-700" />
                )}
              </button>
            </div>
            <div className="flex my-6 ml-[79%]">
              <button
                onClick={handlePasswordChange}
                className={`bg-[#5533ff] text-white p-2 w-28 items-center rounded-2xl ${
                  otpLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={otpLoading}
              >
                {otpLoading ? (
                  <ButtonLoader /> // Use the ButtonLoader component
                ) : (
                  <>Change</>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
