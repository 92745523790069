import React from "react";

const ToggleButton = ({ toggled, onToggle }) => {
  return (
    <button
      className={`${
        toggled ? "bg-purpleButton" : "bg-gray-300"
      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
      onClick={onToggle}
    >
      <span
        className={`${
          toggled ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform bg-white rounded-full transition-transform`}
      />
    </button>
  );
};

export default ToggleButton;
