import { Link } from "react-router-dom";
// images
import logo from "../../images/Logo/whiteLogo.png";
import purpleProfile from "../../images/profile/ProfilePicture.jpg" 
const Header = () => {
  return (
    <header className="bg-purpleButton py-2 px-4 flex justify-between items-center">
      <div className="text-white text-xl font-bold">
        <img src={logo} alt="Logo" className="w-8 h-7 text-white" />
      </div>
      <Link
        to="/employerDashboard/main"
        className="bg-white text-purpleButton text-sm px-2 py-1 rounded-3xl flex flex-row items-center gap-2"
      >
        <img src={purpleProfile} className="w-3" alt="profile" />
        <p>My Account</p>
      </Link>
    </header>
  );
};

export default Header;
