// MediaStreamContext.js
import React, { createContext, useContext, useState } from "react";

const MediaStreamContext = createContext();

export const useMediaStream = () => useContext(MediaStreamContext);

export const MediaStreamProvider = ({ children }) => {
  const [cameraStream, setCameraStream] = useState(null);
  const [screenStream, setScreenStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);

  return (
    <MediaStreamContext.Provider
      value={{
        cameraStream,
        setCameraStream,
        screenStream,
        setScreenStream,
        mediaRecorder,
        setMediaRecorder,
        recordedChunks,
        setRecordedChunks,
      }}
    >
      {children}
    </MediaStreamContext.Provider>
  );
};
