const initialState = {
  jobs: [],
  pagination: {
    currentPage: 1,
    totalPages: 0,
    totalJobs: 0,
    limit: 10, // Ensure this is set here
  },
};

const jobsReducer_employer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_JOB": {
      if (state.jobs.length > 0) {
        return {
          ...state,
          jobs: [action.payload, ...state.jobs],
          pagination: {
            ...state.pagination,
            totalJobs: state.pagination.totalJobs + 1,
            totalPages: Math.ceil(
              (state.pagination.totalJobs + 1) / state.pagination.limit
            ),
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "ADD_LIST_JOBs":
      return {
        ...state,
        jobs: action.payload.jobs,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    case "EDIT_JOB":
      return {
        ...state,
        jobs: state.jobs.map((job) =>
          job._id === action.payload._id ? { ...job, ...action.payload } : job
        ),
      };
    case "DELETE_JOB":
      return {
        ...state,
        jobs: state.jobs.filter((job) => job._id !== action.payload),
        pagination: {
          ...state.pagination,
          totalJobs: state.pagination.totalJobs - 1,
          totalPages: Math.ceil(
            (state.pagination.totalJobs - 1) / state.pagination.limit
          ),
          currentPage:
            Math.min(
              state.pagination.currentPage,
              Math.ceil(
                (state.pagination.totalJobs - 1) / state.pagination.limit
              )
            ) === 0
              ? 1
              : Math.min(
                  state.pagination.currentPage,
                  Math.ceil(
                    (state.pagination.totalJobs - 1) / state.pagination.limit
                  )
                ),
        },
      };
    case "GET_JOBS":
      return {
        ...state,
        jobs: action.payload.jobs,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    case "EDIT_PAGINATION_JOBS":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    default:
      return state;
  }
};

export default jobsReducer_employer;
