import React from "react";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, question }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-10">
      <div className="bg-white rounded-lg p-6 w-80 text-center shadow-lg">
        <div className="text-yellow-500 text-3xl mb-4">⚠️</div>
        <h2 className="text-lg font-semibold mb-2">{question}</h2>
        <p className="text-sm text-gray-600 mb-6">
          You won't be able to revert this!
        </p>
        <div className="flex justify-between">
          <button
            onClick={onConfirm}
            className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
          >
            Yes, do it!
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
