const initialState = {
  testBuilders: [],
  pagination: {
    currentPage: 0,
    totalPages: 0,
    totalJobs: 0,
    limit: 10, // Ensure this is set here
  },
};

const testBuilder_employer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TEST_BUILDERS":
      return {
        ...state,
        testBuilders: [...state.testBuilders, ...action.payload.testBuilders],
        pagination: {
          ...action.payload.pagination,
        },
      };
    case "ADD_TEST_BUILDER": {
      if (state.testBuilders.length > 0) {
        return {
          ...state,
          testBuilders: [action.payload, ...state.testBuilders],
          pagination: {
            ...state.pagination,
            totalJobs: state.pagination.totalJobs + 1,
            totalPages: Math.ceil(
              (state.pagination.totalJobs + 1) / state.pagination.limit
            ),
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "EDIT_TEST_BUILDER":
      return {
        ...state,
        testBuilders: state.testBuilders.map((builder) => {
          return builder._id === action.payload.itemId
            ? action.payload.updatedTestBuilder
            : builder;
        }),
      };
    case "DELETE_TEST_BUILDER":
      return {
        ...state,
        testBuilders: state.testBuilders.filter(
          (builder) => builder._id !== action.payload
        ),
        pagination: {
          ...state.pagination,
          totalJobs: state.pagination.totalJobs - 1,
          totalPages: Math.ceil(
            (state.pagination.totalJobs - 1) / state.pagination.limit
          ),
        },
      };
    case "EDIT_PAGINATION_TEST_BUILDER":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    case "ADD_TEST_QUESTION_IN_BUILDER":
      return {
        ...state,
        testBuilders: state.testBuilders.map((builder) => {
          if (builder._id === action.payload.itemId) {
            return {
              ...builder,
              questions: [...action.payload.questions, ...builder.questions],
            };
          } else {
            return builder;
          }
        }),
      };
    default:
      return state;
  }
};

export default testBuilder_employer;
