import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileDropper from "../../../component/fileDroper/FileDropper";
import Modal from "./congratulationsModel/CongratulationsApplicationSubmittedModel"; // Adjust the import path as necessary
import { useMediaStream } from "./MediaStreamContext"; // Adjust the import path
import ButtonLoader from "../../../component/Loaders/ButtonLoader"; // Adjust the import path as necessary
import {
  getCandidateJobApplicationTest,
  postCandidateJobApplicationTest,
  uploadFiles,
} from "../../../services/apiService"; // Adjust the import path
import { useDispatch } from "react-redux";

const CandidateAttemptTest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobApplyId } = location.state || {}; // Use jobApplyId from location state or provide a default
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [testDetails, setTestDetails] = useState(null); // State to hold fetched test details
  const [timeLeft, setTimeLeft] = useState(null); // Initialize with zero until data is fetched
  const [answers, setAnswers] = useState([]); // State to hold answers
  const [uploadedVideo, setUploadedVideo] = useState(""); // State to hold uploaded video URL
  const [videoUploading, setVideoUploading] = useState(false); // State to handle video upload status
  const [isUploading, setIsUploading] = useState(false); // New state to indicate uploading status
  const [currentStep, setCurrentStep] = useState("attemptTest"); // State to manage current step
  const [wordCountErrors, setWordCountErrors] = useState([]); // Track word count errors per question
  const timerRef = useRef(null); // Ref to hold the timer interval
  const [isRecordingStopped, setIsRecordingStopped] = useState(false); // State to track if recording has stopped

  const {
    cameraStream,
    screenStream,
    recordedChunks,
    setCameraStream,
    setScreenStream,
    setMediaRecorder,
    setRecordedChunks,
  } = useMediaStream();
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef(recordedChunks);

  // Function to fetch test details from API and initialize answers
  const fetchTestDetails = useCallback(async () => {
    try {
      setLoading(true);
      const data = await dispatch(
        getCandidateJobApplicationTest({ jobApplyId })
      );
      setTestDetails(data);
      setTimeLeft(data.testBuilder.testTime * 60); // Convert minutes to seconds
      // Initialize answers with the fetched questions
      const initializedAnswers = data.testQuestions.map((question) => ({
        ...question,
        answer: "", // Default to empty string for text answers
        fileAnswer: null, // Default to null for file answers
      }));
      setAnswers(initializedAnswers);
    } catch (error) {
      toast.error("Failed to fetch test details.");
      console.error("Error fetching test details: ", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, jobApplyId]);

  // Effect to fetch test details on component mount
  useEffect(() => {
    fetchTestDetails();
  }, [fetchTestDetails]);

  // Effect to handle page refresh and redirect
  useEffect(() => {
    if (!location.state) {
      navigate(`/candidateDashboard/applications`);
    }
  }, [location.state, navigate]);

  // Effect to prevent navigation using browser arrows and replace state
  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.href);
    };

    window.history.replaceState(null, null, window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // Function to handle stopping of recording
  const handleStopRecording = useCallback(async () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
    }

    if (screenStream) {
      screenStream.getTracks().forEach((track) => track.stop());
    }

    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
    }

    // Set recording stopped state to true
    setIsRecordingStopped(true);
    setCameraStream(null);
    setScreenStream(null);
  }, [cameraStream, screenStream, setCameraStream, setScreenStream]);

  // Function to handle submission of test
  const handleSubmit = useCallback(async () => {
    const payload = {
      jobApplyId,
      recordedVideo: uploadedVideo,
      questions: answers.map((answer) => ({
        type: answer.type,
        questionText: answer.questionText,
        wordLimit: answer.wordLimit,
        options: answer.options,
        correctAnswer: answer.correctAnswer,
        allowFile: answer.allowFile,
        isCorrect: answer.isCorrect,
        answer: answer.answer || null, // Use the answer if available, otherwise default to empty string
        fileAnswer: answer.fileAnswer || null, // Use the fileAnswer if available, otherwise default to null
      })),
    };

    try {
      await dispatch(postCandidateJobApplicationTest(payload));
      setShowModal(true);
      toast.success("Test submitted successfully!");
    } catch (error) {
      toast.error("Failed to submit test.");
      console.error("Submission error:", error);
    }
  }, [answers, dispatch, jobApplyId, uploadedVideo]);

  // Combined function to stop recording, upload video, and submit the test
  const handleRecordingAndUpload = useCallback(async () => {
    if (isUploading) return; // Prevent multiple triggers

    setIsUploading(true); // Set uploading state to prevent multiple uploads
    setCurrentStep("uploading"); // Set the current step to uploading

    await handleStopRecording(); // Stop the recording

    // Wait until the recording has stopped before uploading
    const checkIfRecordingStopped = new Promise((resolve) => {
      const interval = setInterval(() => {
        if (isRecordingStopped) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });

    await checkIfRecordingStopped; // Ensure the recording has completely stopped before uploading

    setIsUploading(false); // Reset uploading state
  }, [handleStopRecording, isRecordingStopped, isUploading]);

  // Effect to manage timer countdown for the test duration
  useEffect(() => {
    if (timeLeft > 0) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current);
            handleRecordingAndUpload(); // Automatically stop recording, upload video, and submit when time is up
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timerRef.current); // Cleanup timer on component unmount
  }, [handleRecordingAndUpload, timeLeft]);

  // Function to handle downloading of recorded video
  const handleDownload = useCallback(async () => {
    if (recordedChunksRef.current.length > 0) {
      const blob = new Blob(recordedChunksRef.current, {
        type: "video/webm",
      });
      setVideoUploading(true); // Start uploading state

      try {
        // Upload the video to the server
        const response = await uploadFiles([blob]);
        if (response) {
          setUploadedVideo(response.photos[0]); // Save the uploaded video URL
        }

        toast.success("Video uploaded successfully!");
      } catch (error) {
        toast.error("Failed to upload video.");
        console.error("Video upload error:", error);
      } finally {
        setVideoUploading(false); // End uploading state
      }
    }
  }, []);

  useEffect(() => {
    if (uploadedVideo) {
      handleSubmit();
    }
  }, [handleSubmit, uploadedVideo]);

  // Function to handle start of recording
  const handleStartRecording = useCallback(async () => {
    try {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const screenVideo = document.createElement("video");
      screenVideo.srcObject = screenStream;
      await screenVideo.play();

      const cameraVideo = document.createElement("video");
      cameraVideo.srcObject = cameraStream;
      cameraVideo.muted = true;
      await cameraVideo.play();

      const cameraWidth = 300;
      const cameraHeight = 205;

      canvas.width = screenVideo.videoWidth;
      canvas.height = screenVideo.videoHeight;

      const drawFrame = () => {
        ctx.drawImage(screenVideo, 0, 0, canvas.width, canvas.height);
        ctx.drawImage(
          cameraVideo,
          canvas.width - cameraWidth - 10,
          canvas.height - cameraHeight - 10,
          cameraWidth,
          cameraHeight
        );
        requestAnimationFrame(drawFrame);
      };

      drawFrame();

      const mixedStream = canvas.captureStream(30);
      const mixedAudioStream = new MediaStream([
        ...mixedStream.getVideoTracks(),
        ...cameraStream.getAudioTracks(),
        ...screenStream.getAudioTracks(),
      ]);

      mediaRecorderRef.current = new MediaRecorder(mixedAudioStream, {
        mimeType: "video/webm;codecs=vp9,opus",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
          setRecordedChunks([...recordedChunksRef.current]); // Update the state
        }
      };

      mediaRecorderRef.current.onstop = handleDownload;

      mediaRecorderRef.current.start();
      setMediaRecorder(mediaRecorderRef.current);
    } catch (err) {
      console.error("Error starting recording: ", err);
    }
  }, [
    cameraStream,
    handleDownload,
    screenStream,
    setMediaRecorder,
    setRecordedChunks,
  ]);

  // Effect to start recording when both streams are ready
  useEffect(() => {
    if (cameraStream && screenStream) {
      handleStartRecording();
    }
  }, [cameraStream, handleStartRecording, screenStream]);

  // Function to handle closing of modal and redirect
  const handleClose = () => {
    setShowModal(false);
    navigate(`/candidateDashboard/setTestPermissions/${jobApplyId}`);
  };

  // Handle answer input for questions
  const handleAnswerChange = (questionIndex, updatedAnswer) => {
    setAnswers((prevAnswers) => {
      // Create a new array based on the previous state
      const updatedAnswers = [...prevAnswers];

      // Make sure to keep all the current properties and only update what's in updatedAnswer
      updatedAnswers[questionIndex] = {
        ...updatedAnswers[questionIndex], // Preserve current properties
        ...(updatedAnswer.fileAnswer !== undefined && {
          fileAnswer: updatedAnswer.fileAnswer,
        }), // Conditionally update fileAnswer
        ...(updatedAnswer.answer !== undefined && {
          answer: updatedAnswer.answer,
        }), // Conditionally update answer
      };

      return updatedAnswers;
    });

    // Clear or set error for this specific question index
    setWordCountErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (updatedAnswer.answer !== undefined) {
        const wordLimit = testDetails.testQuestions[questionIndex].wordLimit;
        const wordCount = updatedAnswer.answer.trim().split(/\s+/).length;
        if (wordCount > wordLimit) {
          newErrors[questionIndex] = `Word limit exceeded. Maximum allowed words: ${wordLimit}`;
        } else {
          newErrors[questionIndex] = null; // Clear error if within limit
        }
      }
      return newErrors;
    });
  };

  // Function to enforce word limit on text area input
  const enforceWordLimit = (text, limit) => {
    const words = text.trim().split(/\s+/);
    if (words.length <= limit) {
      return text;
    } else {
      return words.slice(0, limit).join(" ");
    }
  };

  return (
    <div className="bg-[#f9fafb] flex flex-col items-center">
      <ToastContainer position="top-right" autoClose={5000} />
      {currentStep === "attemptTest" && // Show test attempt UI
        (loading ? (
          <ButtonLoader />
        ) : (
          <>
            <div className="text-center fixed">
              <h1 className="text-2xl font-semibold mt-4">Attempt Test</h1>
              <p className="text-[10px] text-slate-300">Test 20 July 24</p>
              <div className="text-center mt-4">
                <div className="text-2xl font-bold">
                  {String(Math.floor(timeLeft / 60)).padStart(2, "0")}:
                  {String(timeLeft % 60).padStart(2, "0")}
                </div>
                <div className="text-[10px]">
                  <span className="px-2">Min</span>
                  <span className="px-2">Sec</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full max-w-[900px] rounded-lg p-6 mt-32">
              {testDetails?.testQuestions.map((question, index) => (
                <div
                  key={question._id}
                  className="p-6 mx-auto bg-white rounded-xl shadow-md space-y-4 w-full"
                >
                  <h2 className="text-xl font-bold">Question {index + 1}</h2>
                  <p className="text-gray-700">{question.questionText}</p>
                  {question.type === 2 && (
                    <div className="flex items-center justify-center w-full">
                      <FileDropper
                        title={"Click Or Drop"}
                        instructions={
                          "Please upload 1 video at a time max 50mb"
                        }
                        onFileDrop={(file) =>
                          handleAnswerChange(index, {
                            fileAnswer: file,
                          })
                        }
                        type="video"
                        fileTypes={["mp4", "mov", "avi"]}
                        maxFiles={1}
                      />
                    </div>
                  )}
                  {question.type === 1 && question.options && (
                    <div className="space-y-1">
                      {question.options.map((option, optionIndex) => (
                        <div key={optionIndex} className="flex items-center">
                          <input
                            type="radio"
                            id={`question-${index}-option-${optionIndex}`}
                            name={`question-${index}`}
                            value={option}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                            onChange={() =>
                              handleAnswerChange(index, {
                                answer: option,
                                isCorrect:
                                  optionIndex === question.correctAnswer,
                              })
                            }
                          />
                          <label
                            htmlFor={`question-${index}-option-${optionIndex}`}
                            className="ml-2 block text-sm text-gray-700"
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                  {question.type === 0 && (
                    <>
                      <textarea
                        className="w-full h-24 p-2 border border-gray-300 rounded-md"
                        placeholder="Your answer"
                        maxLength={question.wordLimit * 100}
                        value={answers[index]?.answer || ""}
                        onChange={(e) =>
                          handleAnswerChange(index, {
                            answer: enforceWordLimit(
                              e.target.value,
                              question.wordLimit
                            ),
                          })
                        }
                      />
                      <p className="text-sm text-gray-500">
                        Word limit: {question.wordLimit} | Current count:{" "}
                        {answers[index]?.answer
                          ? answers[index].answer.split(/\s+/).filter(Boolean)
                              .length
                          : 0}
                      </p>
                      {wordCountErrors[index] && (
                        <p className="text-red-500 text-sm">
                          {wordCountErrors[index]}
                        </p>
                      )}
                      {question.allowFile && (
                        <div className="flex items-center justify-center w-full">
                          <FileDropper
                            title={"Click Or Drop"}
                            instructions={"Please upload 1 file at a time"}
                            onFileDrop={(file) =>
                              handleAnswerChange(index, {
                                fileAnswer: file, // Update only fileAnswer
                              })
                            }
                            type="file"
                            fileTypes={[
                              "mp4",
                              "mov",
                              "avi",
                              "pdf",
                              "doc",
                              "docx",
                            ]}
                            maxFiles={1}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
              <div className="flex justify-center">
                <button
                  onClick={handleRecordingAndUpload} // Updated to call the combined function
                  disabled={videoUploading || isUploading} // Disable button while video is uploading
                  className="px-4 py-2 font-bold text-white bg-purpleButton rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                >
                  {isUploading ? "Uploading..." : "Submit Application"}
                </button>
              </div>
            </div>
          </>
        ))}
      {currentStep === "uploading" && ( // Show uploading message UI
        <div className="flex flex-col items-center justify-center min-h-screen bg-[#f9fafb]">
          <div className="p-6 mx-auto bg-white rounded-xl shadow-md space-y-4 w-full max-w-md text-center">
            <h2 className="text-2xl font-semibold">Uploading Your Test</h2>
            <p className="text-gray-700">
              Please wait while your test is being uploaded. This may take a few
              moments. Do not close or refresh your browser window.
            </p>
            <div className="mt-4">
              <ButtonLoader />
            </div>
          </div>
        </div>
      )}
      <Modal show={showModal} onClose={handleClose} />
    </div>
  );
};

export default CandidateAttemptTest;
