import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import {
  getEmployerPackaged,
  newSubscription,
  getStripeKeys,
} from "../../services/apiService"; // Add getStripeKeys import
import Header from "../../component/headers/Header";
import { loadStripe } from "@stripe/stripe-js"; // Import Stripe SDK

// images
import blackLogo from "../../images/Logo/applicationLogo.png";

const Package = ({
  title,
  price,
  features,
  maxCredits,
  freeCredits,
  isFreeTrial,
  isCurrent,
  onPurchase,
  loading,
}) => {
  return (
    <div className="bg-white shadow-md rounded-lg m-4 min-h-56 min-w-60">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold mb-4 bg-purpleButton text-white w-[65%] px-4 py-6 rounded-br-3xl text-wrap text-left">
          {title}
        </h3>
      </div>
      <div className="flex flex-row justify-center items-center mb-4">
        <div className="text-purpleButton font-bold text-xl">
          {isFreeTrial ? "Free" : `$${price}`}
        </div>
        {!isFreeTrial && (
          <>
            <div>/</div>
            <div className="text-purpleButton font-normal">per credit</div>
          </>
        )}
      </div>
      <ul className="mb-4 flex flex-col gap-2 text-[12px] text-center">
        <li className="bg-[#f2f3f5]">
          {isFreeTrial ? "Trial Credits: " : "Number of Credits: "}
          {maxCredits}
        </li>
        {freeCredits && (
          <li className="bg-[#f2f3f5]">Free Credits: {freeCredits}</li>
        )}
        {features.map((feature, index) => (
          <li key={index} className="bg-[#f2f3f5]">
            {feature}
          </li>
        ))}
        {isCurrent ? (
          <div className="border-solid border-2 w-24 border-purpleButton bg-purpleButton text-white px-2 py-1 rounded-3xl my-4 flex justify-center items-center gap-2 text-center m-auto">
            <div>Current</div>
          </div>
        ) : (
          <button
            onClick={onPurchase}
            className="border-solid border-2 w-32 border-purpleButton text-purpleButton px-2 py-1 rounded-3xl my-4 flex justify-center items-center gap-2 text-center m-auto"
            disabled={loading}
          >
            {loading ? <ButtonLoader /> : "Buy Now"}
          </button>
        )}
      </ul>
    </div>
  );
};

const EmployerPurchaseCredits = () => {
  const dispatch = useDispatch();
  const { employerPackages = [], currentPackage = null } = useSelector(
    (state) => ({
      employerPackages:
        state?.packages_Employer?.employerPackages?.packages || [],
      currentPackage:
        state?.packages_Employer?.employerPackages?.currentSubscription
          ?.currentPackage || null,
    })
  );

  const [loading, setLoading] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(null); // For button loader
  const [stripe, setStripe] = useState(null); // Store the Stripe object

  useEffect(() => {
    // Fetch Stripe keys and initialize Stripe
    const fetchStripeKeys = async () => {
      try {
        const keys = await getStripeKeys();
        console.log("keys.publishableKey", keys.publishableKey);
        const stripeObj = await loadStripe(keys.publishableKey); // Initialize Stripe with publishable key
        setStripe(stripeObj);
      } catch (error) {
        toast.error("Failed to load Stripe keys.");
        console.error("Error fetching Stripe keys:", error);
      }
    };

    const fetchPackages = async () => {
      setLoading(true);
      try {
        await dispatch(getEmployerPackaged());
        toast.success("Packages loaded successfully!");
      } catch (error) {
        toast.error("Failed to load packages.");
        console.error("Error fetching packages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStripeKeys();
    fetchPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handlePurchase = async (pkg) => {
    if (!stripe) {
      toast.error("Stripe is not initialized.");
      return;
    }

    setPurchaseLoading(pkg._id);
    try {
      const response = await dispatch(newSubscription(pkg));

      if (response?.sessionId) {
        // Redirect the user to Stripe Checkout
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.sessionId,
        });

        if (error) {
          toast.error(`Stripe checkout failed: ${error.message}`);
        }
      } else {
        toast.error("Failed to initiate subscription.");
      }
    } catch (error) {
      toast.error(`Failed to subscribe to ${pkg.title}.`);
      console.error("Error purchasing subscription:", error);
    } finally {
      setPurchaseLoading(null);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <ToastContainer />
      <Header />
      <main className="flex-1 flex flex-col justify-center items-center text-center bg-gray-100 p-8">
        <div>
          <h2 className="text-3xl font-bold mb-4">Purchase Packages</h2>

          <p className="max-w-[700px] text-[#a1a5b7] text-[8px] sm:text-lg text-left sm:text-center">
            Unlock unlimited potential with our all-inclusive subscription,
            offering high-quality video and audio recording capabilities
            alongside unlimited job postings. Streamline your content creation
            and recruitment processes with seamless integration and premium
            tools designed to elevate your business.
          </p>
        </div>
        <div className="w-full flex items-baseline justify-center gap-8 flex-wrap">
          {loading ? (
            <ButtonLoader />
          ) : (
            <>
              {currentPackage && (
                <Package
                  key={currentPackage._id}
                  title={currentPackage.title}
                  price={currentPackage.pricePerCredit}
                  features={currentPackage.features}
                  maxCredits={currentPackage.numberOfCredits}
                  isFreeTrial={currentPackage.type === 0}
                  isCurrent={true}
                />
              )}
              {employerPackages?.map((pkg) => (
                <Package
                  key={pkg._id}
                  title={pkg.title}
                  price={pkg.pricePerCredit}
                  features={pkg.features}
                  maxCredits={pkg.numberOfCredits}
                  isFreeTrial={pkg.type === 0}
                  isCurrent={false}
                  onPurchase={() => handlePurchase(pkg)}
                  loading={purchaseLoading === pkg._id}
                />
              ))}
            </>
          )}
        </div>
      </main>
      <footer className="bg-white p-4 text-center flex flex-col justify-center gap-2">
        <div>
          <img src={blackLogo} className="w-20 inline" alt="black logo" />
        </div>
        <div className="font-extrabold text-2xl">GrapeSoft</div>
      </footer>
    </div>
  );
};

export default EmployerPurchaseCredits;
