import React, { useState, useEffect } from "react";
import FileDropper from "../../component/fileDroper/FileDropper";
import ToggleButton from "../../component/buttons/ToggleButton";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import {
  getTestBuilders,
  createJob,
  editJob,
  getJobDraft,
  createJobDraft,
} from "../../services/apiService";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

const InputField = ({ id, label, type, value, onChange }) => (
  <div className="mb-6">
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={id}>
      {label}
    </label>
    <input
      id={id}
      type={type}
      value={value}
      onChange={onChange}
      autoComplete="off"
      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
        value ? "bg-slate-100" : ""
      }`}
    />
  </div>
);

const EmployerJob = () => {
  const location = useLocation();
  const job = location.state?.job || null;

  const [toggleStates, setToggleStates] = useState({
    coverLetter: job?.coverLetter || false,
    cv: job?.cv || false,
    aboutVideo: job?.aboutVideo || false,
  });

  const [formValues, setFormValues] = useState({
    title: job?.title || "",
    specifications: job?.specification?.title || "",
    training: job?.training?.title || "",
    contract: job?.contract?.title || "",
    testBuilderId: job?.testBuilderId || "",
  });

  const [testBuilders, setTestBuilders] = useState([]);
  const [loadingPost, setLoadingPost] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState({
    specificationVideo: job?.specification?.video || null,
    specificationPdf: job?.specification?.docs || null,
    trainingVideo: job?.training?.video || null,
    trainingPdf: job?.training?.docs || null,
    contractPdf: job?.contract?.docs || null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTestBuilders = async () => {
      try {
        const response = await dispatch(
          getTestBuilders({
            page: 1,
            limit: 100,
            infinity: true,
          })
        );
        setTestBuilders(response.data.testBuilders);
      } catch (error) {
        toast.error("Failed to load test builders");
        console.error("Error fetching test builders:", error);
      }
    };

    fetchTestBuilders();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await dispatch(getJobDraft());

        setToggleStates({
          coverLetter: response.data?.coverLetter || false,
          cv: response.data?.cv || false,
          aboutVideo: response.data?.aboutVideo || false,
        });

        setFormValues({
          title: response.data?.title || "",
          specifications: response.data?.specification?.title || "",
          training: response.data?.training?.title || "",
          contract: response.data?.contract?.title || "",
          testBuilderId: response.data?.testBuilderId || "",
        });

        setUploadedFiles({
          specificationVideo: response.data?.specification?.video || null,
          specificationPdf: response.data?.specification?.docs || null,
          trainingVideo: response.data?.training?.video || null,
          trainingPdf: response.data?.training?.docs || null,
          contractPdf: response.data?.contract?.docs || null,
        });
      } catch (error) {
        // toast.error("Failed to get draft");
        // console.error(error.data);
      }
    };
    if (!job?._id) {
      fetchData(); // Call the async function
    }
  }, [dispatch, job]); // Don't forget to add dependencies

  const handleDraftSave = async () => {
    try {
      const payload = {
        title: formValues.title,
        specification: {
          title: formValues.specifications,
          video: uploadedFiles.specificationVideo,
          docs: uploadedFiles.specificationPdf,
        },
        training: {
          title: formValues.training,
          video: uploadedFiles.trainingVideo,
          docs: uploadedFiles.trainingPdf,
        },
        contract: {
          title: formValues.contract,
          docs: uploadedFiles.contractPdf,
        },
        testBuilderId:
          formValues.testBuilderId === "" ? null : formValues.testBuilderId,
        coverLetter: toggleStates.coverLetter,
        cv: toggleStates.cv,
        aboutVideo: toggleStates.aboutVideo,
      };
      await dispatch(createJobDraft(payload));
      toast.success("draft saved");
    } catch (error) {
      toast.error("Failed to save draft");
    }
  };

  const handleToggleStatus = (index) => {
    setToggleStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingPost(true);

    const payload = {
      title: formValues.title,
      specification: {
        title: formValues.specifications,
        video: uploadedFiles.specificationVideo,
        docs: uploadedFiles.specificationPdf,
      },
      training: {
        title: formValues.training,
        video: uploadedFiles.trainingVideo,
        docs: uploadedFiles.trainingPdf,
      },
      contract: {
        title: formValues.contract,
        docs: uploadedFiles.contractPdf,
      },
      testBuilderId: formValues.testBuilderId,
      coverLetter: toggleStates.coverLetter,
      cv: toggleStates.cv,
      aboutVideo: toggleStates.aboutVideo,
    };

    const requiredFields = {
      title: "Title",
      "specification.title": "Specification Title",
      "specification.video": "Specification Video",
      "specification.docs": "Specification PDF",
      "training.title": "Training Title",
      "training.video": "Training Video",
      "training.docs": "Training PDF",
      "contract.title": "Contract Title",
      "contract.docs": "Contract PDF",
      testBuilderId: "Test Builder ID",
    };

    const checkForMissingFields = (payload, requiredFields) => {
      for (const key in requiredFields) {
        const keys = key.split(".");
        let value = payload;
        for (const k of keys) {
          value = value?.[k];
        }
        if (!value) {
          toast.error(`Missing field: ${requiredFields[key]}`);
          return false; // Stop checking after the first error
        }
      }
      return true;
    };

    if (checkForMissingFields(payload, requiredFields)) {
      try {
        if (job) {
          payload._id = job._id;
          await dispatch(
            editJob({
              ...payload,
              privateOrPublic: job.privateOrPublic,
              status: job.status,
            })
          );
          navigate("/employerDashboard/jobs", {
            state: { showToast: "Job updated successfully!" },
          });
        } else {
          await dispatch(createJob(payload));
          navigate("/employerDashboard/jobs", {
            state: { showToast: "Job created successfully!" },
          });
        }
      } catch (error) {
        toast.error(`Failed to ${job ? "update" : "create"} job`);
        console.error(`${job ? "Edit" : "Create"} job error:`, error);
      } finally {
        setLoadingPost(false);
      }
    } else {
      setLoadingPost(false);
      toast.error("Please fill all fields");
    }
  };

  return (
    <div className="bg-white flex flex-col items-center justify-center px-2 sm:px-8 py-2 w-full shadow-md">
      <ToastContainer />
      <div className="w-full rounded-lg">
        <div className="flex flex-row justify-between items-center w-full py-2">
          <div className="font-bold">{job ? "Edit Job" : "New Job"}</div>
          <button
            type="submit"
            className="p-2 rounded-3xl bg-purpleButton w-28 text-white"
            disabled={loadingPost}
            onClick={handleSubmit}
          >
            {loadingPost ? <ButtonLoader /> : job ? "Update" : "Post"}
          </button>
        </div>
        <InputField
          id="title"
          label="Title"
          type="text"
          value={formValues.title}
          onChange={handleInputChange}
        />
        <InputField
          id="specifications"
          label="Specifications"
          type="text"
          value={formValues.specifications}
          onChange={handleInputChange}
        />

        <div className="flex flex-col sm:flex-row justify-between gap-7 sm:h-52">
          <div className="mb-6 w-full">
            <h3 className="text-gray-700 text-sm font-bold mb-2">Videos</h3>
            <FileDropper
              title="Drop video here or click to upload"
              instructions="Upload only 1 video at a time"
              type="specificationVideo"
              onFileDrop={(uploadedFilePath, type) => {
                setUploadedFiles((prev) => ({
                  ...prev,
                  [type]: uploadedFilePath,
                }));
              }}
              fileTypes={["mp4", "mov", "avi"]}
              defaultFile={uploadedFiles.specificationVideo}
            />
          </div>

          <div className="mb-6 w-full">
            <h3 className="text-gray-700 text-sm font-bold mb-2">pdf/Docs</h3>
            <FileDropper
              title="Drop pdf here or click to upload"
              instructions="Upload only 1 pdf at a time"
              type="specificationPdf"
              onFileDrop={(uploadedFilePath, type) => {
                setUploadedFiles((prev) => ({
                  ...prev,
                  [type]: uploadedFilePath,
                }));
              }}
              fileTypes={["pdf", "doc", "docx"]}
              defaultFile={uploadedFiles.specificationPdf}
            />
          </div>
        </div>

        <InputField
          id="training"
          label="Training"
          type="text"
          value={formValues.training}
          onChange={handleInputChange}
        />

        <div className="flex flex-col sm:flex-row justify-between gap-7 sm:h-52">
          <div className="mb-6 w-full">
            <h3 className="text-gray-700 text-sm font-bold mb-2">Videos</h3>
            <FileDropper
              title="Drop video here or click to upload"
              instructions="Upload only 1 video at a time"
              type="trainingVideo"
              onFileDrop={(uploadedFilePath, type) => {
                setUploadedFiles((prev) => ({
                  ...prev,
                  [type]: uploadedFilePath,
                }));
              }}
              fileTypes={["mp4", "mov", "avi"]}
              defaultFile={uploadedFiles.trainingVideo}
            />
          </div>

          <div className="mb-6 w-full">
            <h3 className="text-gray-700 text-sm font-bold mb-2">pdf/Docs</h3>
            <FileDropper
              title="Drop pdf here or click to upload"
              instructions="Upload only 1 pdf at a time"
              type="trainingPdf"
              onFileDrop={(uploadedFilePath, type) => {
                setUploadedFiles((prev) => ({
                  ...prev,
                  [type]: uploadedFilePath,
                }));
              }}
              fileTypes={["pdf", "doc", "docx"]}
              defaultFile={uploadedFiles.trainingPdf}
            />
          </div>
        </div>

        <InputField
          id="contract"
          label="Contract"
          type="text"
          value={formValues.contract}
          onChange={handleInputChange}
        />

        <FileDropper
          title="Drop pdf here or click to upload"
          instructions="Upload only 1 pdf at a time"
          type="contractPdf"
          onFileDrop={(uploadedFilePath, type) => {
            setUploadedFiles((prev) => ({
              ...prev,
              [type]: uploadedFilePath,
            }));
          }}
          fileTypes={["pdf", "doc", "docx"]}
          defaultFile={uploadedFiles.contractPdf}
        />

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Select Test
          </label>
          <select
            id="testBuilderId"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={formValues.testBuilderId}
            onChange={handleInputChange}
          >
            <option value="">Select Test</option>
            {testBuilders.map((builder) => (
              <option key={builder._id} value={builder._id}>
                {builder.testName}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-6">
          <h3 className="text-gray-700 text-sm font-bold mb-2">
            Required fields*
          </h3>
          <div className="flex flex-col w-36 gap-2">
            <div className="flex flex-row justify-between">
              <div>Cover letter</div>
              <ToggleButton
                toggled={toggleStates.coverLetter}
                onToggle={() => handleToggleStatus("coverLetter")}
              />
            </div>
            <div className="flex flex-row w-full justify-between">
              <div>CV</div>
              <ToggleButton
                toggled={toggleStates.cv}
                onToggle={() => handleToggleStatus("cv")}
              />
            </div>
            <div className="flex flex-row justify-between">
              <div>About Video</div>
              <ToggleButton
                toggled={toggleStates.aboutVideo}
                onToggle={() => handleToggleStatus("aboutVideo")}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <button
            className="bg-purpleButton hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-3xl focus:outline-none focus:shadow-outline"
            disabled={loadingPost}
            onClick={handleSubmit}
          >
            {loadingPost ? <ButtonLoader /> : job ? "Save" : "Post"}
          </button>
          {job?._id ? (
            <></>
          ) : (
            <>
              <Link
                to="/employerDashboard/jobs"
                onClick={handleDraftSave}
                className="bg-purpleButton hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-3xl focus:outline-none focus:shadow-outline"
              >
                Draft
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployerJob;
