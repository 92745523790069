import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import profileImage from "../../../images/profile/ProfilePicture.jpg";
import greyProfile from "../../../images/employer candidate/greyProfile.png";
import SeeApplicationDetailTimeLine from "./SeeApplicationDetailTimeLine";
import { RiPencilLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { UpdateJobNote } from "../../../services/apiService"; // Import the update note action
import ButtonLoader from "../../../component/Loaders/ButtonLoader"; // Import ButtonLoader component for showing loading state
import { toast, ToastContainer } from "react-toastify"; // Import toast for notifications

const SeeApplicationDetailHeader = () => {
  // Access the fetched job application data from Redux store
  const jobApplication = useSelector(
    (state) => state.jobApplicationSingleReducer_Employer?.jobApplication[0]
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [comment, setComment] = useState(jobApplication?.note || "");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (jobApplication) {
      setComment(jobApplication.note || ""); // Set initial comment if available
    }
  }, [jobApplication]);

  const getPageStyle = (path) => {
    return location.pathname === path
      ? "text-purpleButton font-bold underline underline-offset-[20px] decoration-4"
      : "text-black font-bold";
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      await dispatch(
        UpdateJobNote({
          jobId: jobApplication.jobId,
          candidateId: jobApplication.candidateId,
          note: comment,
        })
      );
      setIsEditing(false);
      toast.success("Comment Saved!");
    } catch (error) {
      toast.error("Failed to save comment.");
    } finally {
      setLoading(false);
    }
  };
  if (!jobApplication) {
    return (
      <>
        <ButtonLoader />
      </>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-4 flex flex-col text-[10px] sm:text-lg">
      <ToastContainer />
      <div className="flex flex-col sm:flex-row justify-between w-full">
        <div className="flex flex-col items-start gap-4 w-[100%] sm:w-[60%]">
          <div className="flex">
            <img
              src={jobApplication?.candidate.avatar || profileImage}
              alt="profileImage"
              className="w-[90px] h-[90px] sm:w-[140px] sm:h-[140px] mr-4"
            />
            <div>
              <h1 className="text-lg font-bold">{`${
                jobApplication?.candidate.first_name || ""
              } ${jobApplication?.candidate.last_name || ""}`}</h1>
              <div className="flex items-center gap-1">
                <img src={greyProfile} alt="greyProfile" />
                <p className="text-[#a1a5b7]">Candidate</p>
              </div>
              <p>
                <span className="text-[#a1a5b7]">Applied for:</span>{" "}
                <span className="text-lg font-bold">
                  {new Date(jobApplication?.createdAt).toLocaleString()}
                </span>
              </p>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-lg font-bold mb-2">Notes</h2>
            <div className="flex items-center gap-2 w-full">
              <textarea
                className={`w-full p-2 border ${
                  isEditing ? "border-gray-300" : "border-transparent"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-[10px]`}
                rows="2"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                disabled={!isEditing}
              />
              <div className="top-2 right-2 flex flex-col gap-2">
                {loading ? (
                  <ButtonLoader />
                ) : (
                  <>
                    <FaCheck
                      className={`h-5 w-5 cursor-pointer ${
                        isEditing
                          ? "bg-purpleButton text-white"
                          : "bg-gray-100 text-gray-300"
                      }`}
                      onClick={handleSaveClick}
                    />
                    <RiPencilLine
                      className={`h-5 w-5 cursor-pointer rounded ${
                        !isEditing
                          ? "bg-purpleButton text-white"
                          : "bg-gray-100 text-gray-300"
                      }`}
                      onClick={handleEditClick}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <SeeApplicationDetailTimeLine />
      </div>
      <div className="flex text-wrap items-start gap-2 sm:gap-10">
        <div
          className={`cursor-pointer ${getPageStyle(
            `/employerDashboard/application/${jobApplication?._id}`
          )}`}
          onClick={() =>
            navigate(`/employerDashboard/application/${jobApplication?._id}`)
          }
        >
          Personal Information
        </div>
        <div
          className={`cursor-pointer ${getPageStyle(
            `/employerDashboard/application/${jobApplication?._id}/testResult`
          )}`}
          onClick={() =>
            navigate(
              `/employerDashboard/application/${jobApplication?._id}/testResult`
            )
          }
        >
          Test Result
        </div>
        <div
          className={`cursor-pointer ${getPageStyle(
            `/employerDashboard/application/${jobApplication?._id}/contract`
          )}`}
          onClick={() =>
            navigate(
              `/employerDashboard/application/${jobApplication?._id}/contract`
            )
          }
        >
          Contracts
        </div>
      </div>
    </div>
  );
};

export default SeeApplicationDetailHeader;
