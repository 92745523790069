// src/Pages/SubscriptionCancel.js
import React from "react";

const SubscriptionCancel = () => {
  return (
    <div className="p-4">
      <h2 className="text-red-500">Your subscription was canceled.</h2>
    </div>
  );
};

export default SubscriptionCancel;
