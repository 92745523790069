import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import { IoMdSettings } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6"; // Import FaArrowRight icon
import Drawer from "react-modern-drawer";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateProfile, logout } from "../../services/apiService";
import AppliationLogo from "../../images/Logo/applicationLogo.png"; // Corrected import
import ProfilePicture from "../../images/profile/ProfilePicture.jpg";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import Notifications from "../notifications/Notifications";
import SimpleTextGreeting from "../../component/greetings/SimpletextGreeting";
import "react-modern-drawer/dist/index.css";

function CandidateIndex() {
  const { userInfo } = useSelector((state) => state?.authReducer_candidate);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleMouseEnter = () => {
    setShowMenu(true);
  };

  const handleMouseLeave = () => {
    setShowMenu(false);
  };

  const signout = async () => {
    dispatch(logout());
    await localStorage.removeItem("accessToken");
    navigate("/login");
  };

  useEffect(() => {
    const checkProfile = async () => {
      try {
        setLoading(true);
        const response = await dispatch(getCandidateProfile());
        if (!response) {
          navigate("/login");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching profile:", error);
        navigate("/login");
        setLoading(false);
      }
    };

    checkProfile();
  }, [navigate, dispatch]);

  if (loading) {
    return (
      <div className="mt-[25%]">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col md:flex-row min-h-screen bg-[#f9fafb]">
        {/* Desktop view */}
        <div className="hidden md:flex w-[25%] py-2 px-7 flex-col justify-between ">
          <div className="h-[calc(100vh-100px)] bg-[#f2f3f5] rounded-2xl m-5 py-2 px-4 hidden md:flex flex-col justify-between w-[17%] fixed">
            <img src={AppliationLogo} alt="logo" className="w-16" />
            <div className="flex flex-col gap-2 text-[#8e96a7]">
              <Link
                to="/candidateDashboard/main"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/main"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Dashboard</div>
              </Link>
              <Link
                to="/candidateDashboard/applications"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/applications"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>My Applications</div>
              </Link>
              <Link
                to="/candidateDashboard/profile"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/profile"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>My Profile</div>
              </Link>
              <Link
                to="/candidateDashboard/jobs"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/jobs"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Jobs</div>
              </Link>
              <Link
                to="/candidateDashboard/helpAndGuide"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/helpAndGuide"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Help & Guide</div>
              </Link>
            </div>
            {/* Profile section */}
            <div className="flex flex-row justify-between items-center">
              <div className="w-[50px]">
                <img
                  src={userInfo?.avatar ? userInfo.avatar : ProfilePicture}
                  alt="profile"
                  className="md:w-7 md:h-7 lg-1:w-12 lg-1:h-12 rounded-full"
                />
              </div>
              <div className="flex flex-col text-[6px] md:text-[10px] leading-5 ml-2">
                <div className="font-bold">
                  {userInfo?.first_name} {userInfo?.last_name}
                </div>
                <div className="text-[#b0b4c2] max-w-[120px] break-words">
                  {userInfo?.email}
                </div>
              </div>
              <div>
                <div className="relative inline-block text-left">
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <IoMdSettings className="w-5 h-5 text-slate-400 cursor-pointer hover:text-blue-500" />
                  </div>
                  {showMenu && (
                    <div
                      className="origin-bottom-right absolute right-0 w-48 lg-1:w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{ bottom: "100%" }}
                    >
                      <div className="py-1">
                        <div className="flex items-center p-4">
                          <img
                            src={
                              userInfo?.avatar
                                ? userInfo.avatar
                                : ProfilePicture
                            }
                            alt="Profile"
                            className="w-10 h-10 rounded-full"
                          />
                          <div className="ml-3 overflow-hidden">
                            <div className="flex gap-2">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {userInfo?.first_name} {userInfo?.last_name}
                              </p>
                              <div className="bg-green-400 text-white w-8 flex justify-center text-[10px] items-center rounded-lg m-auto">
                                user
                              </div>
                            </div>
                            <p className="text-xs text-gray-500 break-words">
                              {userInfo?.email}
                            </p>
                          </div>
                        </div>
                        <div className="border-t border-gray-100"></div>
                        <Link
                          to="/candidateDashboard/profile"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Settings
                        </Link>
                        <div
                          onClick={signout}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        >
                          Sign Out
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile view */}
        <div className="md:hidden flex flex-col justify-between p-4">
          <div className="flex flex-row justify-between gap-2 items-center">
            <div className="flex items-center gap-2">
              <button onClick={toggleDrawer}>
                <HiMenu />
              </button>
              <img src={AppliationLogo} alt="logo" className="w-4 h-4" />
            </div>
            <div>
              <Notifications userInfo={userInfo} />
            </div>
          </div>
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            size={200}
            direction="left"
            className="flex flex-col justify-between py-3 px-3"
            style={{
              height: "calc(100vh - 100px)",
            }}
          >
            <div className="flex items-center gap-2">
              <button onClick={toggleDrawer}>
                <HiMenu />
              </button>
              <img src={AppliationLogo} alt="logo" className="w-4 h-4" />
            </div>
            <div className="flex flex-col gap-2 text-[#8e96a7]">
              <Link
                to="/candidateDashboard/main"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/main"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Dashboard</div>
              </Link>
              <Link
                to="/candidateDashboard/applications"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/applications"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>My Applications</div>
              </Link>
              <Link
                to="/candidateDashboard/profile"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/profile"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>My Profile</div>
              </Link>
              <Link
                to="/candidateDashboard/jobs"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/jobs"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Jobs</div>
              </Link>
              <Link
                to="/candidateDashboard/helpAndGuide"
                className={`flex gap-2 items-center ${
                  location.pathname === "/candidateDashboard/helpAndGuide"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Help & Guide</div>
              </Link>
            </div>
            {/* Profile section */}
            <div className="flex flex-row items-center justify-between">
              <div className="w-[30px]">
                <img
                  src={userInfo?.avatar ? userInfo.avatar : ProfilePicture}
                  alt="profile"
                  className="w-9 h-9 rounded-full"
                />
              </div>
              <div className="flex flex-col w-[100px] text-[9px] leading-3 ml-2">
                <div className="font-bold">
                  {userInfo?.first_name} {userInfo?.last_name}
                </div>
                <div className="text-[#b0b4c2] break-words">
                  {userInfo?.email}
                </div>
              </div>
              <div>
                <div className="relative inline-block text-left">
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <IoMdSettings className="w-5 h-5 text-slate-400 cursor-pointer hover:text-blue-500" />
                  </div>
                  {showMenu && (
                    <div
                      className="origin-bottom-right absolute right-0 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{ bottom: "100%" }}
                    >
                      <div className="py-1">
                        <div className="flex items-center p-4">
                          <img
                            src={
                              userInfo?.avatar
                                ? userInfo.avatar
                                : ProfilePicture
                            }
                            alt="Profile"
                            className="w-10 h-10 rounded-full"
                          />
                          <div className="ml-3 overflow-hidden">
                            <div className="flex gap-2">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {userInfo?.first_name} {userInfo?.last_name}
                              </p>
                              <div className="bg-green-400 text-white w-8 flex justify-center text-[10px] items-center rounded-lg m-auto">
                                user
                              </div>
                            </div>
                            <p className="text-xs text-gray-500 break-words">
                              {userInfo?.email}
                            </p>
                          </div>
                        </div>
                        <div className="border-t border-gray-100"></div>
                        <Link
                          to="/candidateDashboard/profile"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Settings
                        </Link>
                        <div
                          onClick={signout}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        >
                          Sign Out
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Drawer>
        </div>

        <div className="flex flex-col w-full md:mt-10">
          <div className="p-4 flex justify-between items-center">
            <div>
              <h1 className="text-xl font-semibold">
                Hello, {userInfo?.first_name} {userInfo?.last_name}
              </h1>
              <p className="md:ml-2 text-slate-500">
                <SimpleTextGreeting />
              </p>
            </div>
            <div className="hidden md:flex items-center">
              <Notifications userInfo={userInfo} />
            </div>
          </div>
          <div className="p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateIndex;
