import React, { useEffect, useState, useMemo } from "react";
import { toast, ToastContainer } from "react-toastify";
import ButtonLoader from "../../component/Loaders/ButtonLoader";
import { useNavigate, useParams } from "react-router-dom";
import { getCandidateProfile, loginCandidate } from "../../services/apiService";
import { useDispatch } from "react-redux";

const LoginWithPasswordOnly = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useParams();

  // Memoize the payload to prevent changes on every render
  const payload = useMemo(() => {
    let decodedPayload = decodeURIComponent(email);
    return JSON.parse(decodedPayload);
  }, [email]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError(""); // Reset error message on input change
  };

  // Effect to check if the user is already logged in
  useEffect(() => {
    const checkLoginStatus = async () => {
      setLoading(true); // Start loading when checking login status
      try {
        const response = await dispatch(getCandidateProfile());
        if (response && response.data.email === payload.candidateEmail) {
          setIsLoggedIn(true);
          navigate(
            `/candidateDashboard/setTestPermissions/${payload.jobApplyId}`
          );
          toast.success("Already logged in!");
        } else {
          setIsLoggedIn(false);
        }
      } catch (err) {
        console.error("Error checking login status:", err);
        setError("Failed to check login status.");
      } finally {
        setLoading(false); // End loading after the check is complete
      }
    };

    checkLoginStatus();
  }, [dispatch, navigate, payload]); // Now using memoized payload as dependency

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isLoggedIn) return; // Exit if already logged in

    setLoading(true);
    setError(""); // Clear error on new submit attempt

    try {
      await dispatch(
        loginCandidate({
          email: payload.candidateEmail,
          password: password,
        })
      );
      navigate(`/candidateDashboard/setTestPermissions/${payload.jobApplyId}`);
      toast.success("Logged in successfully!");
    } catch (err) {
      setError(err.message || "An error occurred while logging in.");
      toast.error(err.message || "Login failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <div className="mt-[25%]">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <ToastContainer position="top-right" autoClose={5000} />
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
        <h2 className="text-2xl font-semibold text-center mb-6">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-purpleButton sm:text-sm"
              placeholder="Enter your password"
              required
            />
          </div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purpleButton hover:bg-purpleButton focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purpleButton"
            disabled={loading}
          >
            {loading ? <ButtonLoader /> : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginWithPasswordOnly;
